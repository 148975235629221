import React, { useEffect, useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import WalletIcon from "@mui/icons-material/Wallet";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
// import ViewLogDialog from "./ViewLogDialog";

const PendingFundRequest = () => {
  const loadingCtx = useContext(LoadingContext);

  const history = useHistory();

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WalletService.pendingFundRequest(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div>
          <span style={{ fontSize: "18px", fontWeight: "bold" }}>
            ₹{row.amount}
          </span>
        </div>
      ),
    },
    {
      name: "Payment REF ID",
      cell: (row) => (
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.payment_ref_id}
          </span>
          <br />
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Bank Name",
      cell: (row) => (
        <div>
          <span>{row.bank_id}</span>
        </div>
      ),
    },
    {
      name: "Remark",
      cell: (row) => (
        <div>
          <span>{row.remark ? row.remark : "null"}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "Approved") {
              return <span class="badge badge-success">{row.status}</span>;
            } else if (row.status === "Rejected") {
              return <span class="badge badge-danger">{row.status}</span>;
            } else if (row.status === "Pending") {
              return <span class="badge badge-warning">{row.status}</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Want to Approve?",
                html: `
                  <input id="swal-input1" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Approve`,
                confirmButtonColor: "green",
                preConfirm: () => {
                  return [document.getElementById("swal-input1").value];
                },
              });
              if (formValues) {
                updateFundReqHandler(row.id, "Approved", formValues[0]);
              }
            }}
          >
            <DoneOutlineIcon />
          </IconButton>

          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Want to Reject?",
                html: `
                <input id="swal-input2" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Reject`,
                confirmButtonColor: "red",
                preConfirm: () => {
                  return [document.getElementById("swal-input2").value];
                },
              });
              if (formValues) {
                updateFundReqHandler(row.id, "Rejected", formValues[0]);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const updateFundReqHandler = async (id, status, remark) => {
    if (status == "Rejected") {
      if (!remark) {
        Swal.fire("Opps!", "Please Enter Remark", "error");
        return;
      }
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.fundRequestStatusUpdate(
        token,
        id,
        status,
        remark
      );

      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Pending Fund Request">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Pending Fund Request</h3>
                  </div>

                  <div class="card-body">
                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      {/* <ViewLogDialog setOpen={setOpen} open={open} fData={selectedData} /> */}
    </React.Fragment>
  );
};

export default PendingFundRequest;
