import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddNewInvestment = () => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState();
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [chequeStatus, setChequeStatus] = useState();

  const [id, setId] = useState();

  const formRef = useRef();
  const userIdRef = useRef();
  const amountRef = useRef();
  const fromBankRef = useRef();
  const toBankRef = useRef();
  const paymentDateRef = useRef();
  const paymentRefIdRef = useRef();
  // const paymentReceiptRef = useRef();
  const remarkRef = useRef();
  const chequeDetailsRef = useRef();

  function handlePaymentReceipt(event) {
    let _file = event.target.files[0];

    const fileSizeKiloBytes = _file.size / 1024;

    if (fileSizeKiloBytes > 2048) {
      Swal.fire("Opps!", "The file size should be less than 2 MB", "error");
      return;
    }

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    if (!allowedExtensions.exec(_file.name)) {
      Swal.fire(
        "Opps!",
        "Invalid File Type (JPG, JPEG, PNG and PDF are allowed)",
        "error"
      );
      return;
    }

    setFile(_file);
  }

  const submitHandler = async () => {
    const amount = amountRef.current.value;
    const fromBank = fromBankRef.current.value;
    const toBank = toBankRef.current.value;
    const paymentDate = paymentDateRef.current.value;
    const paymentRefId = paymentRefIdRef.current.value;
    //const paymentReceipt = paymentReceiptRef.current.files[0];
    const remark = remarkRef.current.value;
    const chequeDetails = chequeDetailsRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!fromBank) {
      Swal.fire("Opps!", "Invalid From Bank", "error");
      return;
    }

    if (!toBank) {
      Swal.fire("Opps!", "Invalid To Bank", "error");
      return;
    }

    if (!paymentMode) {
      Swal.fire("Opps!", "Invalid Payment Mode", "error");
      return;
    }

    if (!paymentDate) {
      Swal.fire("Opps!", "Invalid Payment Date", "error");
      return;
    }

    if (!paymentRefId) {
      Swal.fire("Opps!", "Invalid Payment Ref ID", "error");
      return;
    }

    if (!file) {
      Swal.fire("Opps!", "Please Upload Payment Receipt", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.addNewInvestment(
        token,
        id,
        amount,
        fromBank,
        toBank,
        paymentMode,
        paymentDate,
        paymentRefId,
        file,
        remark,
        chequeStatus,
        chequeDetails
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchHandler = async () => {
    const userId = userIdRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data.result);
        setId(responseData.data.result.id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [amountInWord, setAmountInWord] = useState();

  const getWord = () => {
    const amount = amountRef.current.value;
    if (amount) {
      let words = toWords.convert(amount);
      setAmountInWord(words);
    } else {
      setAmountInWord();
    }
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add New Investment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add New Investment</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          {!open && (
                            <div class="form-group row">
                              <div class="input-group input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter User ID/Mobile Number"
                                  ref={userIdRef}
                                />
                                <span class="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-info btn-flat"
                                    onClick={fetchHandler}
                                  >
                                    Go
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}

                          {open && data && (
                            <div>
                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      data.name + " (" + data.user_id + ")"
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Amount
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Amount"
                                    maxLength={10}
                                    ref={amountRef}
                                    style={{ fontWeight: "bold" }}
                                    onChange={getWord}
                                  />
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {amountInWord}
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  From the Bank
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    placeholder="From the Bank"
                                    ref={fromBankRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  To the Bank
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    placeholder="To the Bank"
                                    ref={toBankRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Payment Mode
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setPaymentMode(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Payment Mode---
                                    </option>
                                    <option value="CASH">CASH</option>
                                    <option value="IMPS">IMPS</option>
                                    <option value="NEFT">NEFT</option>
                                    <option value="RTGS">RTGS</option>
                                    <option value="UPI">UPI</option>
                                    <option value="CHEQUE">CHEQUE</option>
                                    <option value="INVESTOR TO INVESTOR">
                                      INVESTOR TO INVESTOR
                                    </option>
                                    <option value="TPT">TPT</option>
                                    <option value="CREDIT CARD">
                                      CREDIT CARD
                                    </option>
                                    <option value="COMMISSION ADJUSTMENT">
                                      COMMISSION ADJUSTMENT
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Payment Date
                                </label>
                                <div class="col-sm-9">
                                  {/* <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      label="Payment Date"
                                      inputRef={paymentDateRef}
                                      inputFormat="dd/MM/yyyy"
                                      format="DD-MM-YYYY"
                                      slotProps={{
                                        textField: {
                                          helperText: "DD/MM/YYYY",
                                          fullWidth: true,
                                        },
                                      }}
                                    />
                                  </LocalizationProvider> */}
                                  <input
                                    type="date"
                                    class="form-control"
                                    ref={paymentDateRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Payment Ref ID
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    placeholder="Payment Ref ID"
                                    ref={paymentRefIdRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Payment Receipt
                                </label>
                                <div class="col-sm-9">
                                  <div class="input-group">
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        class="custom-file-input"
                                        //  ref={paymentReceiptRef}
                                        onChange={handlePaymentReceipt}
                                        accept=".jpg,.jpeg,.png,application/pdf"
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="exampleInputFile"
                                      >
                                        {file ? file.name : "Choose file"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Remark
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={remarkRef}
                                    className="form-control"
                                    placeholder="Remark"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Cheque Status
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setChequeStatus(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Cheque Status---
                                    </option>
                                    <option value="Not Applicable">
                                      Not Applicable
                                    </option>
                                    <option value="Delivered">Delivered</option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Cheque Details
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={chequeDetailsRef}
                                    className="form-control"
                                    placeholder="Cheque Details"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddNewInvestment;
