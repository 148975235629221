import React, { useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";

import BasicDetailsTab from "./BasicDetailsTab";
import PersonalDetailsTab from "./PersonalDetailsTab";
import ShopDetailsTab from "./ShopDetailsTab";
import BankDetailsTab from "./BankDetailsTab";
import KycDocumentsTab from "./KycDocumentsTab";
import ActionTab from "./ActionTab";
import VideoKycTab from "./VideoKycTab";
import PanDetailsTab from "./PanDetailsTab";
import AadhaarDetailsTab from "./AadhaarDetailsTab";

import Swal from "sweetalert2";

import HttpError from "../../../../utill/HttpError";
import UserService from "../../../../services/Admin/UserService";
import AuthService from "../../../../services/Admin/AuthService";

function MyTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

MyTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const [value, seValue] = React.useState(0);

  const handleChange = (e, v) => {
    seValue(v);
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const initial_data = [
    {
      name: "profile_img",
      value: "",
    },
    {
      name: "selfie_with_shop_img",
      value: "",
    },
    {
      name: "pan_img",
      value: "",
    },
    {
      name: "poa_frontside_img",
      value: "",
    },
    {
      name: "poa_backside_img",
      value: "",
    },
    {
      name: "img_on_aadhaar",
      value: "",
    },
    {
      name: "video_declaration",
      value: "",
    },
  ];

  const [base64Data, setBase64Data] = useState(initial_data);

  const getImageHandler = async (id) => {
    // START LOADING
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const data = await UserService.getBase64Image(
        token,
        props.userData ? props.userData.id : "",
        initial_data[id]["name"]
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        let newArr = [...base64Data];

        if (id === 6) {
          newArr[id]["value"] = "data:video/mp4;base64," + data.data.base64;
        } else {
          newArr[id]["value"] = "data:image/png;base64," + data.data.base64;
        }

        setBase64Data(newArr);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    // STOP LOADING
    setIsLoading(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container justify="center">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Basic Details" {...a11yProps(0)} />
            <Tab label="Personal Details" {...a11yProps(1)} />
            <Tab label="Shop Details" {...a11yProps(2)} />
            <Tab label="Bank Details" {...a11yProps(3)} />
            <Tab label="PAN Details" {...a11yProps(4)} />
            <Tab label="Aadhaar Details" {...a11yProps(5)} />
            <Tab label="Video KYC" {...a11yProps(6)} />
            <Tab label="Action" {...a11yProps(7)} />
          </Tabs>
        </Grid>
      </Box>
      <MyTabPanel value={value} index={0}>
        <BasicDetailsTab userData={props.userData} />
      </MyTabPanel>

      <MyTabPanel value={value} index={1}>
        <PersonalDetailsTab
          userData={props.userData}
          isLoading={isLoading}
          base64Data={base64Data}
          getImageHandler={getImageHandler}
        />
      </MyTabPanel>

      <MyTabPanel value={value} index={2}>
        <ShopDetailsTab
          userData={props.userData}
          isLoading={isLoading}
          base64Data={base64Data}
          getImageHandler={getImageHandler}
        />
      </MyTabPanel>

      <MyTabPanel value={value} index={3}>
        <BankDetailsTab userData={props.userData} />
      </MyTabPanel>

      <MyTabPanel value={value} index={4}>
        <PanDetailsTab
          userData={props.userData}
          isLoading={isLoading}
          base64Data={base64Data}
          getImageHandler={getImageHandler}
        />
      </MyTabPanel>

      <MyTabPanel value={value} index={5}>
        <AadhaarDetailsTab
          userData={props.userData}
          isLoading={isLoading}
          base64Data={base64Data}
          getImageHandler={getImageHandler}
        />
      </MyTabPanel>

      <MyTabPanel value={value} index={6}>
        <VideoKycTab
          userData={props.userData}
          isLoading={isLoading}
          base64Data={base64Data}
          getImageHandler={getImageHandler}
        />
      </MyTabPanel>

      <MyTabPanel value={value} index={7}>
        <ActionTab userData={props.userData} />
      </MyTabPanel>
    </Box>
  );
};

export default TabPanel;
