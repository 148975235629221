import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AuthService from "../../../services/User/AuthService";
import InvestmentService from "../../../services/User/InvestmentService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const InvtReqEditDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.selectedData;

  const [isLoading, setIsLoading] = useState(false);

  const viewPaymentReceiptHandler = async (orderId) => {
    // loadingCtx.startLoading();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.viewPaymentReceipt(
        token,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.setImage(responseData.data.image);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    setIsLoading(false);
  };

  const [paymentMode, setPaymentMode] = useState(data ? data.payment_mode : "");
  const [file, setFile] = useState();

  const formRef = useRef();
  const amountRef = useRef();
  const fromBankRef = useRef();
  const toBankRef = useRef();
  const paymentDateRef = useRef();
  const paymentRefIdRef = useRef();
  // const paymentReceiptRef = useRef();
  const remarkRef = useRef();

  function handlePaymentReceipt(event) {
    let _file = event.target.files[0];

    const fileSizeKiloBytes = _file.size / 1024;

    if (fileSizeKiloBytes > 2048) {
      Swal.fire("Opps!", "The file size should be less than 2 MB", "error");
      return;
    }

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    if (!allowedExtensions.exec(_file.name)) {
      Swal.fire(
        "Opps!",
        "Invalid File Type (JPG, JPEG, PNG and PDF are allowed)",
        "error"
      );
      return;
    }

    setFile(_file);
  }

  const updateHandler = async (orderId) => {
    const amount = amountRef.current.value;
    const fromBank = fromBankRef.current.value;
    const toBank = toBankRef.current.value;
    const paymentDate = paymentDateRef.current.value;
    const paymentRefId = paymentRefIdRef.current.value;
    //const paymentReceipt = paymentReceiptRef.current.files[0];
    const remark = remarkRef.current.value;

    if (!orderId) {
      Swal.fire("Opps!", "Invalid Order ID", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!fromBank) {
      Swal.fire("Opps!", "Invalid From Bank", "error");
      return;
    }

    if (!toBank) {
      Swal.fire("Opps!", "Invalid To Bank", "error");
      return;
    }

    if (!paymentMode) {
      Swal.fire("Opps!", "Invalid Payment Mode", "error");
      return;
    }

    if (!paymentDate) {
      Swal.fire("Opps!", "Invalid Payment Date", "error");
      return;
    }

    if (!paymentRefId) {
      Swal.fire("Opps!", "Invalid Payment Ref ID", "error");
      return;
    }

    if (!file) {
      Swal.fire("Opps!", "Please Upload Payment Receipt", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    props.handleClose();
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.updateInvestmentRequest(
        token,
        orderId,
        amount,
        fromBank,
        toBank,
        paymentMode,
        paymentDate,
        paymentRefId,
        file,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && data && (
              <ul class="list-group list-group-unbordered mb-3">
                {data && data.status === "REJECTED" && (
                  <div>
                    <li class="list-group-item">
                      <b>User ID</b>{" "}
                      <span class="float-right">
                        {data
                          ? data.tbl_user.user_id +
                            " (" +
                            data.tbl_user.name +
                            ")"
                          : ""}
                      </span>
                    </li>
                    <li class="list-group-item">
                      <b>Order ID</b>{" "}
                      <span class="float-right">
                        {data ? data.order_id : ""}
                      </span>
                    </li>
                    <li class="list-group-item">
                      <b>Status</b>{" "}
                      <span class="float-right">{data ? data.status : ""}</span>
                    </li>
                    <li class="list-group-item">
                      <b>Rejected Reason</b>{" "}
                      <span class="float-right" style={{ color: "red" }}>
                        {data.admin_remark}
                      </span>
                    </li>
                  </div>
                )}
                {data && data.status !== "REJECTED" && (
                  <div>
                    {data.tbl_user && (
                      <li class="list-group-item">
                        <b>User ID</b>{" "}
                        <span class="float-right">
                          {data
                            ? data.tbl_user.user_id +
                              " (" +
                              data.tbl_user.name +
                              ")"
                            : ""}
                        </span>
                      </li>
                    )}

                    <li class="list-group-item">
                      <b>Amount</b>{" "}
                      <span
                        class="float-right"
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        ₹{data ? data.amount : ""}
                      </span>
                    </li>

                    {data && data.status === "APPROVED" && (
                      <li class="list-group-item">
                        <b>Amount Withdrawn</b>{" "}
                        <span
                          class="float-right"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          ₹{data ? data.amount_withdrawn : ""}
                        </span>
                      </li>
                    )}

                    <li class="list-group-item">
                      <b>From the Bank</b>{" "}
                      <span class="float-right">
                        {data ? data.from_bank : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>To the Bank</b>{" "}
                      <span class="float-right">
                        {data ? data.to_bank : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Payment Mode/Date</b>{" "}
                      <span class="float-right">
                        {data
                          ? data.payment_mode + " (" + data.payment_date + ")"
                          : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Payment Ref ID</b>{" "}
                      <span class="float-right">
                        {data ? data.payment_ref_id : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Payment Receipt</b>{" "}
                      {isLoading && (
                        <span class="float-right badge badge-pill badge-danger">
                          Fetching...
                        </span>
                      )}
                      {!isLoading && (
                        <span class="float-right">
                          {props.image && (
                            <span>
                              <span
                                class="badge badge-pill badge-primary"
                                onClick={() => {
                                  const newTab = window.open(props.image);

                                  newTab?.document.write(
                                    `<!DOCTYPE html><head><title>Image</title></head><body><img src="${props.image}"  ></body></html>`
                                  );

                                  newTab?.document.close();
                                }}
                              >
                                View
                              </span>

                              <a
                                href={props.image}
                                download={data.payment_receipt}
                                class="badge badge-pill badge-primary ml-2"
                              >
                                Download
                              </a>
                            </span>
                          )}

                          {!props.image && (
                            <span
                              class="badge badge-pill badge-primary ml-2"
                              onClick={() => {
                                viewPaymentReceiptHandler(
                                  data ? data.order_id : ""
                                );
                              }}
                            >
                              Fetch
                            </span>
                          )}
                        </span>
                      )}
                    </li>

                    <li class="list-group-item">
                      <b>Remark</b>{" "}
                      <span class="float-right">{data ? data.remark : ""}</span>
                    </li>

                    <li class="list-group-item">
                      <b>Order ID</b>{" "}
                      <span class="float-right">
                        {data ? data.order_id : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Status</b>{" "}
                      <span class="float-right">{data ? data.status : ""}</span>
                    </li>

                    <li class="list-group-item">
                      <b>Created On</b>{" "}
                      <span class="float-right">
                        {data ? data.created_on : ""}
                      </span>
                    </li>
                  </div>
                )}
              </ul>
            )}

            {data && data.status === "REJECTED" && (
              <div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Amount</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Amount"
                      maxLength={10}
                      ref={amountRef}
                      defaultValue={data.amount}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">From the Bank</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="From the Bank"
                      ref={fromBankRef}
                      defaultValue={data.from_bank}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">To the Bank</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="To the Bank"
                      ref={toBankRef}
                      defaultValue={data.to_bank}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Mode</label>
                  <div class="col-sm-9">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      <option value={data.payment_mode}>
                        {data.payment_mode}
                      </option>
                      <option value="CASH">CASH</option>
                      <option value="IMPS">IMPS</option>
                      <option value="NEFT">NEFT</option>
                      <option value="RTGS">RTGS</option>
                      <option value="UPI">UPI</option>
                      <option value="CHEQUE">CHEQUE</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Date</label>
                  <div class="col-sm-9">
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Payment Date"
                        inputRef={paymentDateRef}
                        inputFormat="dd/MM/yyyy"
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            helperText: "DD/MM/YYYY",
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider> */}
                    <input
                      type="date"
                      class="form-control"
                      ref={paymentDateRef}
                      defaultValue={data.dob}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Ref ID</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Payment Ref ID"
                      ref={paymentRefIdRef}
                      defaultValue={data.payment_ref_id}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Receipt</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          //  ref={paymentReceiptRef}
                          onChange={handlePaymentReceipt}
                          accept=".jpg,.jpeg,.png,application/pdf"
                        />
                        <label class="custom-file-label" for="exampleInputFile">
                          {file ? file.name : "Choose file"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Remark</label>
                  <div class="col-sm-9">
                    <textarea
                      ref={remarkRef}
                      className="form-control"
                      placeholder="Remark"
                      rows="3"
                      defaultValue={data.remark}
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            {data && data.status === "REJECTED" && (
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  updateHandler(data ? data.order_id : "");
                }}
              >
                Update and Re-Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InvtReqEditDialog;
