import common from "../../utill/Common";
import axios from "axios";

class CommonService {
  getDomainInfo = async (domain_name) => {
    const url = common.baseUrl + "user/website/info";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain_name }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CommonService();
