import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const WalletStatement = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  //   const [open, setOpen] = useState(false);
  //   const [selectedData, setSelectedData] = useState();

  const size = 10;

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate]);

  const fetchUsers = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WalletService.walletStatement(
      token,
      fromDate,
      toDate,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Date Time",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },

    {
      name: "Description",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>
            {row.product.name} - {row.product.sub_master_product.name}
          </b>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Order Amount",
      cell: (row) => (
        <h6>
          <span class="badge badge-primary">
            {"₹" + row.order_amount.toFixed(2)}
          </span>
        </h6>
      ),
    },
    {
      name: "Credit/Debit",
      cell: (row) => (
        <div>
          {(() => {
            if (row.txn_type === "CR") {
              return (
                <h6>
                  <span class="badge badge-success">
                    {"+" + row.amount.toFixed(2)}
                  </span>
                </h6>
              );
            } else {
              return (
                <h6>
                  <span class="badge badge-danger">
                    {"-" + row.amount.toFixed(2)}
                  </span>
                </h6>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Balance",
      cell: (row) => (
        <h6>
          <span class="badge badge-primary">
            {"₹" + row.closing_bal.toFixed(2)}
          </span>
        </h6>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h6>
                  <span class="badge badge-success">SUCCESS</span>
                </h6>
              );
            } else if (row.status === "FAILED") {
              return (
                <h6>
                  <span class="badge badge-danger">FAILED</span>
                </h6>
              );
            } else if (row.status === "PENDING") {
              return (
                <h6>
                  <span class="badge badge-warning">PENDING</span>
                </h6>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h6>
                  <span class="badge badge-secondary">REVERSAL</span>
                </h6>
              );
            } else if (row.status === "REFUND") {
              return (
                <h6>
                  <span class="badge badge-secondary">REFUND</span>
                </h6>
              );
            }
          })()}
        </div>
      ),
    },
  ];

  // const handleIncomplete = (authorization) => {
  //   const url = `https://sso-nsdl-ekyc-app.pages.dev/sso_nsdl_ekyc_redirect?is_incomplete=true&authorization=${authorization}`;

  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");

  //   if (newWindow) newWindow.opener = null;
  // };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.xlsWalletStatement(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Wallet Statement">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Wallet Statement</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2 mb-3">
                      <div class="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-icon waves-effect waves-light"
                            onClick={xlsReport}
                          >
                            <i class="fa fa-download"></i> EXPORT
                          </button>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default WalletStatement;
