import common from "../../utill/Common";

class InvestmentService {
  addCreditTransaction = async (
    token,
    id,
    txn_type,
    amount,
    txn_date,
    remark
  ) => {
    const url = common.baseUrl + "admin/credit/add_transaction";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, txn_type, amount, txn_date, remark }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  creditTransactionHistory = async (
    token,
    from_date,
    to_date,
    user_id,
    txn_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/credit/transaction_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        txn_date,
        from_date,
        to_date,
        user_id,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvestmentService();
