import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

import AuthService from "../../../services/User/AuthService";
import DeveloperApiService from "../../../services/User/DeveloperApiService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const Webhooks = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const nsdlPANCardUrlRef = useRef();

  const utiPANCardPaymentUrlRef = useRef();
  const utiPANCardRefundUrlRef = useRef();
  const utiPANCardReversalUrlRef = useRef();

  const iDFetchBalUrlRef = useRef();
  const iDWalletDebitUrlRef = useRef();
  const iDPayoutUrlRef = useRef();

  const submitHandler = async () => {
    const nsdl_pancard_url = nsdlPANCardUrlRef.current.value;

    const uti_pancard_payment_url = utiPANCardPaymentUrlRef.current.value;
    const uti_pancard_refund_url = utiPANCardRefundUrlRef.current.value;
    const uti_pancard_reversal_url = utiPANCardReversalUrlRef.current.value;

    const id_fetch_balance_url = iDFetchBalUrlRef.current.value;
    const id_wallet_debit_url = iDWalletDebitUrlRef.current.value;
    const id_payout_url = iDPayoutUrlRef.current.value;

    const urls = {
      nsdl_pancard: nsdl_pancard_url,
      utiitsl_pancard: {
        payment: uti_pancard_payment_url,
        refund: uti_pancard_refund_url,
        reversal: uti_pancard_reversal_url,
      },
      insurance_dekho: {
        fetch_balance: id_fetch_balance_url,
        wallet_debit: id_wallet_debit_url,
        payout: id_payout_url,
      },
    };

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DeveloperApiService.webhooks(token, urls);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const data = props.data ? JSON.parse(props.data.callback_urls) : null;

  return (
    <React.Fragment>
      <div className="mt-3 mb-3">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div class="form-group">
              <label for="exampleInputEmail1">NSDL PAN Card (URL)</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter NSDL PAN Card (URL)"
                ref={nsdlPANCardUrlRef}
                defaultValue={data ? data.nsdl_pancard : ""}
              />
            </div>

            <br />

            <div class="form-group">
              <label for="exampleInputEmail1">
                UTIITSL PAN Card - Payment (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter UTIITSL PAN Card - Payment (URL)"
                ref={utiPANCardPaymentUrlRef}
                defaultValue={data ? data.utiitsl_pancard.payment : ""}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                UTIITSL PAN Card - Refund (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter UTIITSL PAN Card - Refund (URL)"
                ref={utiPANCardRefundUrlRef}
                defaultValue={data ? data.utiitsl_pancard.refund : ""}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                UTIITSL PAN Card - Reversal (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter UTIITSL PAN Card - Reversal (URL)"
                ref={utiPANCardReversalUrlRef}
                defaultValue={data ? data.utiitsl_pancard.reversal : ""}
              />
            </div>

            <br />

            <div class="form-group">
              <label for="exampleInputEmail1">
                Insurance Dekho - Fetch Balance (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Insurance Dekho - Fetch Balance (URL)"
                ref={iDFetchBalUrlRef}
                defaultValue={data ? data.insurance_dekho.fetch_balance : ""}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Insurance Dekho - Wallet Debit (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Insurance Dekho - Wallet Debit (URL)"
                ref={iDWalletDebitUrlRef}
                defaultValue={data ? data.insurance_dekho.wallet_debit : ""}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Insurance Dekho - Payout (URL)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Insurance Dekho - Payout (URL)"
                ref={iDPayoutUrlRef}
                defaultValue={data ? data.insurance_dekho.payout : ""}
              />
            </div>

            <br />

            <div className="text-center plan-btn mt-2 mb-2">
              <Button
                color="primary"
                onClick={() => {
                  submitHandler();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Webhooks;
