import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/Admin/AuthService";
import RechargeApiService from "../../../../services/Admin/RechargeApiService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import TransactionApiTab from "./TransactionApiTab";
import StatusCheckTab from "./StatusCheckTab";
import BalanceCheckTab from "./BalanceCheckTab";
import WebhookApiTab from "./WebhookApiTab";
import DisputeApiTab from "./DisputeApiTab";

const EditRechargeApi = (props) => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);

  const [transactionApi, setTransactionApi] = useState({
    url: "",
    method: "",
    headers: [{ key: "", value: "" }],
    content_type: "JSON",
    body: [],
    json_body: {},
    response_type: "JSON",
    response_keys: {
      status_key: "",
      message_key: "",
      api_order_id_key: "",
      operator_ref_id_key: "",
      commission_key: "",
      closing_bal_key: "",
      debit_amount_key: "",
      status_success_value: "",
      status_failed_value: "",
    },
  });

  const [statusCheckApi, setStatusCheckApi] = useState({
    url: "",
    method: "",
    headers: [{ key: "", value: "" }],
    content_type: "JSON",
    body: [],
    json_body: {},
    response_type: "JSON",
    response_keys: {
      status_key: "",
      message_key: "",
      api_order_id_key: "",
      operator_ref_id_key: "",
      commission_key: "",
      closing_bal_key: "",
      debit_amount_key: "",
      status_success_value: "",
      status_failed_value: "",
    },
  });

  const [disputeApi, setDisputeApi] = useState({
    url: "",
    method: "",
    headers: [{ key: "", value: "" }],
    content_type: "JSON",
    body: [],
    json_body: {},
    response_type: "JSON",
    response_keys: {
      message_key: "",
    },
  });

  const [balanceCheckApi, setBalanceCheckApi] = useState({
    url: "",
    method: "",
    headers: [{ key: "", value: "" }],
    content_type: "JSON",
    body: [],
    json_body: {},
    response_type: "JSON",
    response_keys: {
      balance_key: "",
    },
  });

  const [webhookApi, setWebhookApi] = useState({
    method: "",
    response_type: "",
    response_keys: {
      status_key: "",
      message_key: "",
      order_id_key: "",
      operator_ref_id_key: "",
      debit_amount_key: "",
      status_failed_value: "",
      status_success_value: "",
    },
  });

  const [data, setData] = useState();
  const [status, setStatus] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const whitelistedIpsRef = useRef();

  const editRechargeApiHandler = async () => {
    const name = nameRef.current.value;
    const whitelistedIps = whitelistedIpsRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid API Name", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await RechargeApiService.update(
        token,
        id,
        name,
        whitelistedIps,
        status,
        transactionApi,
        statusCheckApi,
        disputeApi,
        balanceCheckApi,
        webhookApi
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeApiService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;

        setData(data);
        setStatus(data.status);

        const configuration = data.configuration;

        if (configuration && configuration.transaction_api) {
          setTransactionApi(configuration.transaction_api);
        }

        if (configuration && configuration.status_check_api) {
          setStatusCheckApi(configuration.status_check_api);
        }

        if (configuration && configuration.dispute_api) {
          setDisputeApi(configuration.dispute_api);
        }

        if (configuration && configuration.balance_check_api) {
          setBalanceCheckApi(configuration.balance_check_api);
        }

        if (configuration && configuration.webhook_api) {
          setWebhookApi(configuration.webhook_api);
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Recharge API">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Edit Recharge API</h3>
                    <div class="card-tools">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/admin/recharge_api_list");
                        }}
                      >
                        Recharge API List
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      {data && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                API Name
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="API Name"
                                  ref={nameRef}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Whitelisted IPs
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  ref={whitelistedIpsRef}
                                  defaultValue={data.whitelisted_ips}
                                />
                                <small>Use comma (,) to add multiple IPs</small>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Webhook URL
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  defaultValue={
                                    "https://www.yourdomain.com/api/recharge_response/" +
                                    Base64.encode(data.id)
                                  }
                                  readOnly
                                />

                                <small>
                                  Set this as Webhook URL in your {data.name}{" "}
                                  account
                                </small>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Status
                              </label>
                              <div class="col-sm-10">
                                <select
                                  name="title"
                                  class="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={data.status}>
                                    {data.status}
                                  </option>
                                  <option value="Enabled">Enabled</option>
                                  <option value="Disabled">Disabled</option>
                                </select>
                              </div>
                            </div>

                            <div class="row pt-3">
                              <div class="col-5 col-sm-3">
                                <div
                                  class="nav flex-column nav-tabs h-100"
                                  id="vert-tabs-tab"
                                  role="tablist"
                                  aria-orientation="vertical"
                                >
                                  <a
                                    class="nav-link active"
                                    id="vert-tabs-home-tab"
                                    data-toggle="pill"
                                    href="#vert-tabs-home"
                                    role="tab"
                                    aria-controls="vert-tabs-home"
                                    aria-selected="true"
                                  >
                                    Transaction API
                                  </a>
                                  <a
                                    class="nav-link"
                                    id="vert-tabs-profile-tab"
                                    data-toggle="pill"
                                    href="#vert-tabs-profile"
                                    role="tab"
                                    aria-controls="vert-tabs-profile"
                                    aria-selected="false"
                                  >
                                    Status Check API
                                  </a>
                                  <a
                                    class="nav-link"
                                    id="vert-tabs-dispute-tab"
                                    data-toggle="pill"
                                    href="#vert-tabs-dispute"
                                    role="tab"
                                    aria-controls="vert-tabs-dispute"
                                    aria-selected="false"
                                  >
                                    Dispute API
                                  </a>
                                  <a
                                    class="nav-link"
                                    id="vert-tabs-messages-tab"
                                    data-toggle="pill"
                                    href="#vert-tabs-messages"
                                    role="tab"
                                    aria-controls="vert-tabs-messages"
                                    aria-selected="false"
                                  >
                                    Balance Check API
                                  </a>
                                  <a
                                    class="nav-link"
                                    id="vert-tabs-webhook-tab"
                                    data-toggle="pill"
                                    href="#vert-tabs-webhook"
                                    role="tab"
                                    aria-controls="vert-tabs-webhook"
                                    aria-selected="false"
                                  >
                                    Webhook API
                                  </a>
                                </div>
                              </div>
                              <div class="col-7 col-sm-9">
                                <div
                                  class="tab-content"
                                  id="vert-tabs-tabContent"
                                >
                                  <div
                                    class="tab-pane text-left fade active show"
                                    id="vert-tabs-home"
                                    role="tabpanel"
                                    aria-labelledby="vert-tabs-home-tab"
                                  >
                                    <TransactionApiTab
                                      transactionApi={transactionApi}
                                      setTransactionApi={setTransactionApi}
                                    />
                                  </div>

                                  <div
                                    class="tab-pane fade"
                                    id="vert-tabs-profile"
                                    role="tabpanel"
                                    aria-labelledby="vert-tabs-profile-tab"
                                  >
                                    <StatusCheckTab
                                      statusCheckApi={statusCheckApi}
                                      setStatusCheckApi={setStatusCheckApi}
                                    />
                                  </div>

                                  <div
                                    class="tab-pane fade"
                                    id="vert-tabs-dispute"
                                    role="tabpanel"
                                    aria-labelledby="vert-tabs-dispute-tab"
                                  >
                                    <DisputeApiTab
                                      disputeApi={disputeApi}
                                      setDisputeApi={setDisputeApi}
                                    />
                                  </div>

                                  <div
                                    class="tab-pane fade"
                                    id="vert-tabs-messages"
                                    role="tabpanel"
                                    aria-labelledby="vert-tabs-messages-tab"
                                  >
                                    <BalanceCheckTab
                                      balanceCheckApi={balanceCheckApi}
                                      setBalanceCheckApi={setBalanceCheckApi}
                                    />
                                  </div>

                                  <div
                                    class="tab-pane fade"
                                    id="vert-tabs-webhook"
                                    role="tabpanel"
                                    aria-labelledby="vert-tabs-webhook-tab"
                                  >
                                    <WebhookApiTab
                                      webhookApi={webhookApi}
                                      setWebhookApi={setWebhookApi}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <p>{"{number}"}</p>

                            <p>{"{amount}"}</p>

                            <p>{"{operator_code}"}</p>

                            <p>{"{order_id}"}</p>

                            <div class="text-center pt-3">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={editRechargeApiHandler}
                              >
                                Save Changes
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditRechargeApi;
