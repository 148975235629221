import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/Admin/AuthService";
import RechargeApiService from "../../../../services/Admin/RechargeApiService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const RechargeApiList = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const loadingCtx = useContext(LoadingContext);

  const [userType, setUserType] = useState();

  useEffect(() => {
    fetchData();
    getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setUserType(_userType);
  };

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    RechargeApiService.fetchAll(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data;
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.id}</span>
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      name: "URL",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.url}</span>
        </div>
      ),
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.user_id}</span>
        </div>
      ),
    },
    {
      name: "Password",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.password}</span>
        </div>
      ),
    },
    {
      name: "Token",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.token}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "Enabled") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/edit_recharge_api/" + row.id);
              return true;
            }}
          >
            <EditIcon />
          </IconButton>

          {userType === "Admin" && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure to delete?",
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  confirmButtonColor: "red",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteHandler(row.id);
                  }
                });
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeApiService.delete(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        fetchData();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Recharge API List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Recharge API List</h3>

                    <div class="card-tools">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/admin/add_recharge_api");
                        }}
                      >
                        Add Recharge API
                      </button>
                    </div>
                  </div>

                  <div class="card-body">
                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default RechargeApiList;
