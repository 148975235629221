import React from "react";

import common from "../../../../utill/Common";

const StatusCheckTab = (props) => {
  const rechargeApi = props.statusCheckApi;
  const setRechargeApi = props.setStatusCheckApi;

  return (
    <React.Fragment>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">URL</label>
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            defaultValue={rechargeApi.url}
            //  ref={urlRef}
            onInput={(x) => {
              let value = x.target.value;

              rechargeApi.url = value;
            }}
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Method</label>
        <div class="col-sm-9">
          <select
            className="form-control"
            onChange={(x) => {
              let value = x.target.value;

              rechargeApi.method = value;
            }}
          >
            <option value={rechargeApi ? rechargeApi.method : ""}>
              {rechargeApi ? rechargeApi.method : "Select Method"}
            </option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
          </select>
        </div>
      </div>

      {/* {JSON.stringify(rechargeApi)} */}

      <div class="card bg-light d-flex flex-fill">
        <div class="card-header text-muted border-bottom-0">
          <h3 class="card-title">Headers</h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-info btn-sm"
              onClick={() => {
                const headers = [
                  ...rechargeApi.headers,
                  { key: "", value: "" },
                ];

                // REPLACE 1
                let replace1 = { ...rechargeApi };
                replace1.headers = headers;

                // Do this!
                setRechargeApi((prevState) => {
                  return {
                    ...prevState,
                    headers: headers,
                  };
                });
              }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>

        <div class="card-body pt-0 mb-2 ">
          {rechargeApi.headers.map((e, index) => {
            return (
              <div class="row pt-2" key={index}>
                <div class="col-5">
                  <label>Key</label>
                  <input
                    type="text"
                    class="form-control"
                    defaultValue={e.key}
                    onInput={(x) => {
                      let key = x.target.value;

                      rechargeApi.headers[index].key = key;
                    }}
                  />
                </div>
                <div class="col-5">
                  <label>Value</label>
                  <input
                    type="text"
                    class="form-control"
                    defaultValue={e.value}
                    onInput={(x) => {
                      let value = x.target.value;

                      rechargeApi.headers[index].value = value;
                    }}
                  />
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    style={{ marginTop: "34px" }}
                    onClick={() => {
                      let headers = rechargeApi.headers.filter(
                        (item, index2) => index2 !== index
                      );

                      // REPLACE 1
                      let replace1 = {
                        ...rechargeApi,
                      };
                      replace1.headers = headers;

                      // Do this!
                      setRechargeApi((prevState) => {
                        return {
                          ...prevState,
                          headers: headers,
                        };
                      });
                    }}
                  >
                    <i className="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div class="card bg-light d-flex flex-fill">
        <div class="card-header text-muted border-bottom-0">
          <h3 class="card-title">Body</h3>
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-info btn-sm"
              disabled={rechargeApi.content_type === "Form Data" ? false : true}
              onClick={() => {
                const body = [...rechargeApi.body, { key: "", value: "" }];

                // REPLACE 1
                let replace1 = { ...rechargeApi };
                replace1.body = body;

                // Do this!
                setRechargeApi((prevState) => {
                  return {
                    ...prevState,
                    body: body,
                  };
                });
              }}
            >
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </div>

        <div class="card-body pt-0 mb-2 ">
          <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
            <li class="nav-item">
              <a
                className={`nav-link ${
                  rechargeApi.content_type === "Form Data" ? "active" : ""
                }`}
                id="custom-tabs-four-home-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="custom-tabs-four-home"
                aria-selected="true"
                onClick={() => {
                  // Do this!
                  setRechargeApi((prevState) => {
                    return {
                      ...prevState,
                      content_type: "Form Data",
                    };
                  });
                }}
              >
                Form Data
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  rechargeApi.content_type === "JSON" ? "active" : ""
                }`}
                id="custom-tabs-four-profile-tab"
                data-toggle="pill"
                role="tab"
                aria-controls="custom-tabs-four-profile"
                aria-selected="false"
                onClick={() => {
                  // Do this!
                  setRechargeApi((prevState) => {
                    return {
                      ...prevState,
                      content_type: "JSON",
                    };
                  });
                }}
              >
                JSON
              </a>
            </li>
          </ul>

          {rechargeApi.content_type === "Form Data" && (
            <div>
              {rechargeApi.body.map((e, index) => {
                return (
                  <div class="row pt-2" key={index}>
                    <div class="col-5">
                      <label>Key</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.key}
                        onInput={(x) => {
                          let key = x.target.value;

                          rechargeApi.body[index].key = key;
                        }}
                      />
                    </div>
                    <div class="col-5">
                      <label>Value</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.value}
                        onInput={(x) => {
                          let value = x.target.value;

                          rechargeApi.body[index].value = value;
                        }}
                      />
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style={{ marginTop: "34px" }}
                        onClick={() => {
                          let body = rechargeApi.body.filter(
                            (item, index2) => index2 !== index
                          );

                          // REPLACE 1
                          let replace1 = {
                            ...rechargeApi,
                          };
                          replace1.body = body;

                          // Do this!
                          setRechargeApi((prevState) => {
                            return {
                              ...prevState,
                              body: body,
                            };
                          });
                        }}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {rechargeApi.content_type === "JSON" && (
            <div class="row pt-2">
              <div class="col-12">
                <textarea
                  type="text"
                  class="form-control mt-2"
                  rows="6"
                  defaultValue={JSON.stringify(rechargeApi.json_body)}
                  onChange={(x) => {
                    if (common.IsJsonString(x.target.value)) {
                      rechargeApi.json_body = JSON.parse(x.target.value);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div class="card bg-light d-flex flex-fill">
        <div class="card-header text-muted border-bottom-0">
          <h3 class="card-title">Response</h3>
        </div>

        <div class="card-body pt-0 mb-2 ">
          {/* <div class="form-group row">
            <label class="col-sm-3 col-form-label">Type</label>
            <div class="col-sm-9">
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  checked={rechargeApi.response_type === "JSON" ? true : false}
                  id="customRadio1"
                  onClick={() => {
                    alert("JSON");
                    // Do this!
                    setRechargeApi((prevState) => {
                      return {
                        ...prevState,
                        response_type: "JSON",
                      };
                    });
                  }}
                />
                <label for="customRadio1" class="custom-control-label">
                  JSON
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  checked={rechargeApi.response_type === "XML" ? true : false}
                  id="customRadio2"
                  onClick={() => {
                    alert("XML");
                    // Do this!
                    setRechargeApi((prevState) => {
                      return {
                        ...prevState,
                        response_type: "XML",
                      };
                    });
                  }}
                />
                <label for="customRadio2" class="custom-control-label">
                  XML
                </label>
              </div>
            </div>
          </div> */}

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Type</label>
            <div class="col-sm-10">
              <select
                className="form-control"
                onChange={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_type = value;
                }}
              >
                <option value={rechargeApi ? rechargeApi.response_type : ""}>
                  {rechargeApi
                    ? rechargeApi.response_type
                    : "Select Response Type"}
                </option>
                <option value="JSON">JSON</option>
                <option value="XML">XML</option>
              </select>
            </div>
          </div>

          <div class="row pt-2">
            <div class="col-4">
              <label>Status Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_key = value;
                }}
              />
            </div>
            <div class="col-4">
              <label>Message Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.message_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.message_key = value;
                }}
              />
            </div>
            <div class="col-4">
              <label>API Order ID Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.api_order_id_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.api_order_id_key = value;
                }}
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4">
              <label>Operator Ref ID Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.operator_ref_id_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.operator_ref_id_key = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Commission Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.commission_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.commission_key = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Closing Balance Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.closing_balance_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.closing_balance_key = value;
                }}
              />
            </div>
          </div>

          <div class="row pt-2">
            <div class="col-4">
              <label>Debit Amount Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.debit_amount_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.debit_amount_key = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Status Success Value</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_success_value}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_success_value = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Status Failed Value</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_failed_value}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_failed_value = value;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatusCheckTab;
