import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const TeamInvestmentHistory = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [totalAmount, setTotalAmount] = useState();

  const size = 10;

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, userId]);

  const fetchUsers = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    InvestmentService.teamInvestmentHistory(token, userId, fromDate, toDate)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data;
        setData(dataResult);
        setLoading(false);

        let _totalAmount = 0;

        dataResult.forEach((e) => {
          _totalAmount += parseFloat(e.amount - e.amount_withdrawn);
        });
        setTotalAmount(_totalAmount);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.tbl_user.name} - {row.tbl_user.user_id}
          </span>
        </div>
      ),
    },
    {
      name: "Available Amount",
      cell: (row) => (
        <div style={{ fontSize: "14px", fontWeight: "bold", color: "green" }}>
          ₹{row.amount - row.amount_withdrawn}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Team Investment History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Team Investment History</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="From Date"
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                    defaultValue={fromDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="To Date"
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                    defaultValue={toDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="User ID"
                                    onChange={(e) => {
                                      setUserId(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />

                    <br />
                    {data && (
                      <div>
                        <p>
                          Total Amount:{" "}
                          <b style={{ color: "green" }}>₹{totalAmount}</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default TeamInvestmentHistory;
