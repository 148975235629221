import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const FundRequestHistory = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  //   const [open, setOpen] = useState(false);
  //   const [selectedData, setSelectedData] = useState();

  const size = 10;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WalletService.fundRequestHistory(
      token,
      fromDate,
      toDate,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Date Time",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b style={{ fontSize: "16px" }}>₹{row.amount}</b> <br />
        </div>
      ),
    },
    {
      name: "Bank Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.bank}</span>
        </div>
      ),
    },
    {
      name: "Payment Ref ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>{row.payment_ref_id}</b> <br />
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Admin Remark",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.admin_remark ? row.admin_remark : "NILL"}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "Approved") {
              return <span class="badge badge-success">APPROVED</span>;
            } else if (row.status === "Rejected") {
              return <span class="badge badge-danger">REJECTED</span>;
            } else if (row.status === "Pending") {
              return <span class="badge badge-warning">PENDING</span>;
            }
          })()}
        </div>
      ),
    },
  ];

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Fund Request History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Fund Request History</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default FundRequestHistory;
