import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { logoutUser } from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import AuthService from "../../../services/Admin/AuthService";

const Logout = (props) => {
  const dispatch = useDispatch();

  const [isUserLogout, setIsUserLogout] = useState(false);

  useEffect(() => {
    handler();
  });

  const handler = () => {
    AuthService.logout();
    setIsUserLogout(true);
  };

  if (isUserLogout) {
    return <Redirect to="/admin_login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
