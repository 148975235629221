import common from "../../utill/Common";

class InvestmentService {
  addNewInvestment = async (
    token,
    id,
    amount,
    from_bank,
    to_bank,
    payment_mode,
    payment_date,
    payment_ref_id,
    file,
    remark,
    cheque_status,
    cheque_details
  ) => {
    const url = common.baseUrl + "admin/investment/add_new_investment";

    const dataArray = new FormData();
    dataArray.append("id", id);
    dataArray.append("amount", amount);
    dataArray.append("from_bank", from_bank);
    dataArray.append("to_bank", to_bank);
    dataArray.append("payment_mode", payment_mode);
    dataArray.append("payment_date", payment_date);
    dataArray.append("payment_ref_id", payment_ref_id);
    dataArray.append("payment_receipt", file);
    dataArray.append("remark", remark);
    dataArray.append("cheque_status", cheque_status);
    dataArray.append("cheque_details", cheque_details);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchByInvestmentId = async (token, id) => {
    const url = common.baseUrl + "admin/investment/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateInvestment = async (
    token,
    id,
    payment_date,
    admin_remark,
    is_closed,
    cheque_status,
    cheque_details,
    cheque_return_date
  ) => {
    const url = common.baseUrl + "admin/investment/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        payment_date,
        admin_remark,
        is_closed,
        cheque_status,
        cheque_details,
        cheque_return_date,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  history = async (
    token,
    from_date,
    to_date,
    user_id,
    status,
    search_text,
    is_closed,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/investment/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        from_date,
        to_date,
        status,
        search_text,
        is_closed,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  teamInvestmentHistory = async (token, user_id, from_date, to_date) => {
    const url = common.baseUrl + "admin/investment/team_investment_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        from_date,
        to_date,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  pendingInvtRequest = async (token, user_id, search_text) => {
    const url = common.baseUrl + "admin/investment/pending_invt_request";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        search_text,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  viewPaymentReceipt = async (token, order_id) => {
    const url = common.baseUrl + "admin/investment/view_payment_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateInvestmentRequest = async (token, order_id, status, remark) => {
    const url = common.baseUrl + "admin/investment/update_invt_request";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
        status,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteEntry = async (token, id, order_id) => {
    const url = common.baseUrl + "admin/investment/delete_entry";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  downloadReceipt = async (token, order_id) => {
    const url = common.baseUrl + "admin/investment/download_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvestmentService();
