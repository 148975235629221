import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const UpdateNomineeDetailsForm = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();
  const [nomineeRelation, setNomineeRelation] = useState();

  const formRef = useRef();
  const nomineeNameRef = useRef();

  const updateHandler = async () => {
    const nomineeName = nomineeNameRef.current.value;

    if (!nomineeName) {
      Swal.fire("Opps!", "Invalid Nominee Name", "error");
      return;
    }

    if (!nomineeRelation) {
      Swal.fire("Opps!", "Invalid Nominee Relation", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.updateNomineeDetails(
        token,
        nomineeName,
        nomineeRelation
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.fetch(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-12 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Nominee Name</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nominee Name"
                      ref={nomineeNameRef}
                      defaultValue={data.nominee_name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">
                    Nominee Relation
                  </label>
                  <div class="col-sm-10">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setNomineeRelation(e.target.value);
                      }}
                    >
                      {data.nominee_relation && (
                        <option defaultValue={data.nominee_relation}>
                          {data.nominee_relation}
                        </option>
                      )}

                      {!data.nominee_relation && (
                        <option defaultValue="">
                          ---Select Nominee Relation---
                        </option>
                      )}
                      <option defaultValue="Father">Father</option>
                      <option defaultValue="Mother">Mother</option>
                      <option defaultValue="Wife">Wife</option>
                      <option defaultValue="Husband">Husband</option>
                      <option defaultValue="Son">Son</option>
                      <option defaultValue="Daughter">Daughter</option>
                    </select>
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateNomineeDetailsForm;
