import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AuthService from "../../../services/User/AuthService";
import WithdrawalService from "../../../services/User/WithdrawalService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const ActionDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.selectedData;

  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();
  const amountRef = useRef();

  const submitHandler = async (orderId) => {
    const amount = amountRef.current.value;

    if (!orderId) {
      Swal.fire("Opps!", "Invalid Order ID", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    props.handleClose();
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WithdrawalService.newWithdrawalRequest(
        token,
        orderId,
        amount
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && data && (
              <ul class="list-group list-group-unbordered mb-3">
                <div>
                  <li class="list-group-item">
                    <b>Order ID</b>{" "}
                    <span class="float-right">{data ? data.order_id : ""}</span>
                  </li>

                  <li class="list-group-item">
                    <b>Payment Date</b>{" "}
                    <span class="float-right">
                      {data ? data.payment_date : ""}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Invested Amount</b>{" "}
                    <span
                      class="float-right"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      ₹{data ? data.amount : ""}
                    </span>
                  </li>
                  <li class="list-group-item">
                    <b>Amount Withdrawn</b>{" "}
                    <span
                      class="float-right"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      ₹{data ? data.amount_withdrawn : ""}
                    </span>
                  </li>
                  <li class="list-group-item">
                    <b>Lien Amount</b>{" "}
                    <span
                      class="float-right"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      ₹{data.lien_amount ? data.lien_amount : "0"}
                    </span>
                  </li>
                  <li class="list-group-item">
                    <b>Available Balance</b>{" "}
                    <span
                      class="float-right"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      ₹{data ? data.amount - data.amount_withdrawn : ""}
                    </span>
                  </li>
                </div>
              </ul>
            )}

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Amount</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Amount"
                  maxLength={10}
                  ref={amountRef}
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                submitHandler(data ? data.order_id : "");
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ActionDialog;
