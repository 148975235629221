import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/MenuOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

import PendingActionDialog from "./PendingActionDialog";

const PendingInvtRequest = () => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [userType, setUserType] = useState();

  useEffect(() => {
    fetch();
    getUserType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, userId]);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setUserType(_userType);
  };

  const fetch = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    InvestmentService.pendingInvtRequest(token, userId, searchText)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data;
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.tbl_user ? row.tbl_user.user_id : ""} (
            {row.tbl_user ? row.tbl_user.name : ""})
          </span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-primary">₹{row.amount}</span>{" "}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "APPROVED") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else if (row.status === "PENDING") {
              return (
                <span class="badge badge-pill badge-warning">{row.status}</span>
              );
            } else if (row.status === "REJECTED") {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Created On",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
              setImage();
            }}
          >
            <SettingsIcon />
          </IconButton>

          {userType === "Admin" && (
            <IconButton
              color="error"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure to delete?",
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  confirmButtonColor: "red",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteInvEntryHandler(row.id, row.order_id);
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const deleteInvEntryHandler = async (id, orderId) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.deleteEntry(
        token,
        id,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [image, setImage] = useState();

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Investment History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Investment History</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="User ID"
                                    onChange={(e) => {
                                      setUserId(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <PendingActionDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          selectedData={selectedData}
          image={image}
          setImage={setImage}
          fetch={fetch}
          userType={userType}
        />
      </Templete>
    </React.Fragment>
  );
};

export default PendingInvtRequest;
