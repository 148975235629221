import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import ReportsService from "../../../services/Admin/ReportsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const XlsCommissionBalance = () => {
  const loadingCtx = useContext(LoadingContext);

  const download = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ReportsService.xlsCommissionBalance(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
        const downloadLink = responseData["data"]["download_link"];

        window.open(downloadLink, "");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Xls Commission Balance">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Xls Commission Balance</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto pt-3">
                      <form autoComplete="off">
                        <div class="card-body">
                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={download}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default XlsCommissionBalance;
