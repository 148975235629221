import common from "../../utill/Common";

class NoticeService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/notice/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (token, title, description, image) => {
    const url = common.baseUrl + "admin/notice/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        title,
        description,
        image,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (token, id, title, description, image) => {
    const url = common.baseUrl + "admin/notice/edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        title,
        description,
        image,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/notice/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAll = async (token) => {
    const url = common.baseUrl + "admin/notice/fetch_all";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  history = async (
    token,
    expense_date,
    from_date,
    to_date,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/expense/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        expense_date,
        from_date,
        to_date,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NoticeService();
