import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import NoticeService from "../../../services/Admin/NoticeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const EditNotice = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const formRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();
  const imageRef = useRef();

  const editNoticeHandler = async () => {
    const title = titleRef.current.value;
    const description = descriptionRef.current.value;
    const image = imageRef.current.value;

    if (!title) {
      Swal.fire("Opps!", "Invalid Title", "error");
      return;
    }

    if (!description) {
      Swal.fire("Opps!", "Invalid Description", "error");
      return;
    }

    if (!image) {
      Swal.fire("Opps!", "Invalid Image", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await NoticeService.update(
        token,
        id,
        title,
        description,
        image
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NoticeService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Notice">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Edit Notice</h3>

                    <div class="card-tools">
                      <a
                        href="/admin/notice_list"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Notice List
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      {data && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Title
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Title"
                                  ref={titleRef}
                                  defaultValue={data.title}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Description
                              </label>
                              <div class="col-sm-10">
                                {/* <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Description"
                                  ref={descriptionRef}
                                  defaultValue={data.description}
                                /> */}

                                <textarea
                                  //  id="compose-textarea2"
                                  class="form-control"
                                  style={{ height: "300px" }}
                                  ref={descriptionRef}
                                  defaultValue={data.description}
                                ></textarea>
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Image Link
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Image Link"
                                  ref={imageRef}
                                  defaultValue={data.image}
                                />
                              </div>
                            </div>

                            <div class="card-footer text-center">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={editNoticeHandler}
                              >
                                Submit
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditNotice;
