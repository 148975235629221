import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import UserService from "../../../services/Admin/UserService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ResetLoginDetailsForm = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const formRef = useRef();
  const passwordRef = useRef();
  const pinRef = useRef();

  const submitHandler = async () => {
    const password = passwordRef.current.value;
    const pin = pinRef.current.value;

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    if (!pin || pin.length < 4 || pin.length > 4) {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = props.userId ? props.userId : props.match.params.userId;

      const responseData = await UserService.resetLoginDetails(
        token,
        userId,
        password,
        pin
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data.result);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-6 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">User ID</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      readOnly
                      defaultValue={
                        props.userId
                          ? props.userId
                          : props.match.params.userId + " (" + data.name + ")"
                      }
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Password</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Password"
                      ref={passwordRef}
                      // defaultValue={data.name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">PIN</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="PIN"
                      ref={pinRef}
                      // defaultValue={data.name}
                      maxLength={4}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={submitHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetLoginDetailsForm;
