import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import UserService from "../../../services/Admin/UserService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const UpdateBankDetails = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [accountType, setAccountType] = useState();

  const [data, setData] = useState();

  const formRef = useRef();
  const bankNameRef = useRef();
  const accountNumberRef = useRef();
  const ifscCodeRef = useRef();

  const updateHandler = async () => {
    const bankName = bankNameRef.current.value;
    const accountNumber = accountNumberRef.current.value;
    const ifscCode = ifscCodeRef.current.value;

    if (!bankName) {
      Swal.fire("Opps!", "Invalid Bank Name", "error");
      return;
    }

    if (!accountNumber) {
      Swal.fire("Opps!", "Invalid Account Number", "error");
      return;
    }

    if (!ifscCode) {
      Swal.fire("Opps!", "Invalid IFSC Code", "error");
      return;
    }

    if (!accountType) {
      Swal.fire("Opps!", "Invalid Account Type", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = props.userId ? props.userId : props.match.params.userId;

      const responseData = await UserService.updateBankDetails(
        token,
        userId,
        bankName,
        accountNumber,
        ifscCode,
        accountType
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data.result);
        setAccountType(responseData.data.result.account_type);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-8 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Bank Name</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Bank Name"
                      ref={bankNameRef}
                      defaultValue={data.bank_name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Account Number</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="Account Number"
                      ref={accountNumberRef}
                      defaultValue={data.account_number}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">IFSC Code</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="IFSC Code"
                      ref={ifscCodeRef}
                      defaultValue={data.ifsc_code}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Account Type</label>
                  <div class="col-sm-10">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setAccountType(e.target.value);
                      }}
                    >
                      {data.account_type && (
                        <option defaultValue={data.account_type}>
                          {data.account_type}
                        </option>
                      )}

                      {!data.account_type && (
                        <option defaultValue="">
                          ---Select Account Type---
                        </option>
                      )}
                      <option defaultValue="Current">Current</option>
                      <option defaultValue="Savings">Savings</option>
                    </select>
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpdateBankDetails;
