import common from "../../utill/Common";

class InsuranceService {
  report = async (
    token,
    from_date,
    to_date,
    status,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/insurance/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        status,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (token, from_date, to_date, user_id) => {
    const url = common.baseUrl + "admin/insurance/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date, user_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InsuranceService();
