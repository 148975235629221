import common from "../../utill/Common";

class DeveloperApiService {
  regenerateKey = async (token) => {
    const url = common.baseUrl + "user/developer_api/regenerate_key";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  regenerateKeyOtpVerify = async (token, otp_token, otp) => {
    const url = common.baseUrl + "user/developer_api/regenerate_key_otp_verify";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token: otp_token,
        otp,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getKey = async (token) => {
    const url = common.baseUrl + "user/developer_api/get_key";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  webhooks = async (token, urls) => {
    const url = common.baseUrl + "user/developer_api/webhooks";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ urls: urls }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  ipWhitelist = async (token, ips) => {
    const url = common.baseUrl + "user/developer_api/ip_whitelist";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ ips }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DeveloperApiService();
