import common from "../../utill/Common";

class NsdlPANCardService {
  getAuthorization = async (
    token,
    userId,
    pin,
    application_mode,
    application_type,
    category,
    name,
    dob,
    gender,
    mobile,
    email,
    is_physical_pan_required
  ) => {
    const url = common.baseUrl + "user/nsdl_pancard/get_authorization";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({
        application_mode,
        application_type,
        category,
        name,
        dob,
        gender,
        mobile,
        email,
        is_physical_pan_required,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getSubscriptionFees = async (token) => {
    const url =
      common.baseUrl + "user/nsdl_pancard/fetch_activation_commercial";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  txnStatus = async (token, order_id) => {
    const url = common.baseUrl + "user/nsdl_pancard/txn_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  panStatus = async (token, ack_no) => {
    const url = common.baseUrl + "user/nsdl_pancard/pan_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ ack_no }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  report = async (token, from_date, to_date, search_text, page, size) => {
    const url = common.baseUrl + "user/nsdl_pancard/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date, search_text, page, size }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (token, from_date, to_date) => {
    const url = common.baseUrl + "user/nsdl_pancard/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  xlsReport = async (token, from_date, to_date) => {
    const url = common.baseUrl + "user/nsdl_pancard/xls_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getSubscriptionFees = async (token) => {
    const url =
      common.baseUrl + "user/nsdl_pancard/fetch_activation_commercial";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  nsdlResponse = async (encrypted_data) => {
    const url = common.baseUrl + "user/nsdl_pancard/response";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ encrypted_data }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NsdlPANCardService();
