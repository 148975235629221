import React, { useState, useContext, useRef, useEffect } from "react";
import JsonFormatter from "react-json-formatter";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const PaymentSettings = (props) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  let data = props.data.pg_settings;

  const loadingCtx = useContext(LoadingContext);

  const [pgs, setPgs] = useState(data ? data.pgs : []);
  const [defaultPG, setDefaultPG] = useState(data.default_pg);

  const updateHandler = async () => {
    const jsonData = JSON.stringify({
      default_pg: defaultPG,
      pgs: pgs,
    });

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updatePGSettings(
        token,
        jsonData
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Default Gateway</label>
            <div class="col-sm-9">
              {data.pgs.map((e, index) => {
                return (
                  <div class="custom-control custom-radio">
                    <input
                      class="custom-control-input"
                      type="radio"
                      checked={defaultPG === e.name}
                      id={index}
                      key={index}
                    />
                    <label
                      for="customRadio1"
                      key={index}
                      class="custom-control-label"
                      onClick={() => {
                        setDefaultPG(e.name);
                      }}
                    >
                      {e.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          {data.pgs.map((e, index) => {
            return (
              <div>
                <div class="card bg-light d-flex flex-fill">
                  <div class="card-header text-muted border-bottom-0">
                    <h5>
                      <strong>{e.name}</strong>
                    </h5>
                  </div>
                  <div class="card-body pt-0">
                    {e.params.map((ee, index1) => {
                      return (
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label">
                            {ee.placeholder}
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={ee.value}
                              // placeholder={ee.placeholder}
                              onInput={(x) => {
                                // REPLACE
                                let value = x.target.value;
                                let replace = { ...ee, value };

                                // REPLACE 1
                                let replace1 = { ...e };
                                replace1.params[index1] = replace;

                                const merge = Object.assign(pgs, replace1);

                                //   console.log(JSON.stringify(replace1));

                                setPgs(merge);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">
                        Whitelisted IPs
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ex: 000.000.00.000, 111.111.11.111"
                          defaultValue={e.whitelisted_ips}
                          onInput={(x) => {
                            // REPLACE
                            let whitelisted_ips = x.target.value;
                            whitelisted_ips = whitelisted_ips.split(",");

                            //Find index of specific object using findIndex method.
                            const objIndex = pgs.findIndex(
                              (obj) => obj.name == e.name
                            );

                            //Update object's name property.
                            pgs[objIndex].whitelisted_ips = whitelisted_ips;
                          }}
                        />

                        <small>Use comma (,) to add multiple IPs</small>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Webhook URL</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          defaultValue={e.webhook_url}
                          readOnly
                        />

                        <small>
                          Set this as Webhook URL in your {e.name} account
                        </small>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Status</label>
                      <div class="col-sm-9">
                        <select
                          class="form-control"
                          placeholder="Ex: 000.000.00.000, 111.111.11.111"
                          onChange={(x) => {
                            // REPLACE
                            let status = x.target.value;

                            //Find index of specific object using findIndex method.
                            const objIndex = pgs.findIndex(
                              (obj) => obj.name == e.name
                            );

                            //Update object's name property.
                            pgs[objIndex].status = status;
                          }}
                        >
                          <option value={e.status ? e.status : ""}>
                            {e.status ? e.status : "Select Status"}
                          </option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <p>
            <JsonFormatter json={pgs} tabWith="4" jsonStyle={jsonStyle} />
          </p> */}

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSettings;
