import common from "../../utill/Common";
import axios from "axios";

class CommercialService {
  add = async (token, newData) => {
    const url = common.baseUrl + "user/commercial/add";

    try {
      const response = await axios.post(url, newData, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });

      if (!response.status || !response.data) {
        throw new Error("Request Failed!");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  update = async (token, newData) => {
    const url = common.baseUrl + "user/commercial/update";

    try {
      const response = await axios.post(url, newData, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });

      if (!response.status || !response.data) {
        throw new Error("Request Failed!");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  fetch = async (token, package_id, product_id) => {
    const url = common.baseUrl + "user/commercial/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ package_id, product_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  delete = async (token, id) => {
    const url = common.baseUrl + "user/commercial/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  addPackage = async (token, name, status) => {
    const url = common.baseUrl + "user/commercial_package/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ name, status }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchPackages = async (token) => {
    const url = common.baseUrl + "user/commercial_package/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deletePackage = async (token, id) => {
    const url = common.baseUrl + "user/commercial_package/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  editPackage = async (token, name, status, id) => {
    const url = common.baseUrl + "user/commercial_package/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ name, status, id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CommercialService();
