import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

const KeyDetailsDialog = (props) => {
  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">API Keys</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-striped table-primary">
                <tr>
                  <th>Client ID:</th>
                  <td>{props.clientId}</td>
                </tr>
                <tr>
                  <th>Client Secret:</th>
                  <td>{props.clientSecret}</td>
                </tr>
                <tr>
                  <th>Encryption Key:</th>
                  <td>{props.encryptionKey}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default KeyDetailsDialog;
