import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  ListItemSecondaryAction,
} from "@material-ui/core";

//import Zoom from "react-medium-image-zoom";
//import "react-medium-image-zoom/dist/styles.css";

import JsonFormatter from "react-json-formatter";

const VideoKycTab = (props) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  var myObject;

  if (
    props.userData &&
    props.userData.video_kyc_status &&
    props.userData.video_kyc_json
  ) {
    myObject = JSON.parse(props.userData.video_kyc_json);
  }

  return (
    <React.Fragment>
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.video_kyc_status}
              </ListItemSecondaryAction>
              <ListItemText primary="Video KYC Status" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.video_kyc_token}
              </ListItemSecondaryAction>
              <ListItemText primary="Token" />
            </ListItem>

            {props.userData && props.userData.video_kyc_status && (
              <div>
                {" "}
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.otp}
                  </ListItemSecondaryAction>
                  <ListItemText primary="OTP" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.faceFound}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Face Found" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.videoForensics
                        .staticRisk}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Static Risk" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.videoForensics
                        .prerecordedRisk}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Pre Recorded Risk" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.audioMatch
                        .matchAudioScore}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Audio Match Score" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.videoFaceMatch[0] &&
                      myObject.result.videoVerification.videoFaceMatch[0]
                        .matchStatistics.matchPercentage}{" "}
                    ---{" "}
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.videoFaceMatch[0] &&
                      myObject.result.videoVerification.videoFaceMatch[0]
                        .matchStatistics.coVariance}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Face Match Score (PAN)" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.videoFaceMatch[1] &&
                      myObject.result.videoVerification.videoFaceMatch[1]
                        .matchStatistics.matchPercentage}{" "}
                    ---{" "}
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.matchImageFaceMatch &&
                      myObject.result.videoVerification.matchImageFaceMatch
                        .matchPercentage}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Face Match Score (AADHAAR)" />
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    {props.userData &&
                      myObject &&
                      myObject.result.videoVerification.matchImageFaceMatch &&
                      myObject.result.videoVerification.matchImageFaceMatch
                        .matchPercentage}
                  </ListItemSecondaryAction>
                  <ListItemText primary="Final Match Score" />
                </ListItem>
              </div>
            )}
          </List>

          <Grid container justify="center" spacing={10}>
            <Grid item>
              {props.base64Data[6]["value"] && (
                <video controls width="400">
                  <source type="video/mp4" src={props.base64Data[6]["value"]} />
                </video>
              )}

              {!props.base64Data[6]["value"] && (
                <button
                  onClick={() => {
                    props.getImageHandler(6);
                  }}
                  className="btn btn-primary"
                  disabled={props.isLoading}
                >
                  {props.isLoading && <CircularProgress size={14} />}
                  {!props.isLoading && "Fetch & Play Video"}
                </button>
              )}
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {props.userData && props.userData.video_kyc_json && (
            <JsonFormatter
              json={props.userData.video_kyc_json}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default VideoKycTab;
