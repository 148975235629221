import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/Admin/AuthService";
import RechargeApiService from "../../../../services/Admin/RechargeApiService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const AddRechargeApi = () => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const [status, setStatus] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const urlRef = useRef();
  const userIdRef = useRef();
  const passwordRef = useRef();
  const tokenRef = useRef();

  const addApiHandler = async () => {
    const name = nameRef.current.value;
    const url = urlRef.current.value;
    const userId = userIdRef.current.value;
    const password = passwordRef.current.value;
    const xtoken = tokenRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid API Name", "error");
      return;
    }

    if (!url) {
      Swal.fire("Opps!", "Invalid URL", "error");
      return;
    }

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    if (!xtoken) {
      Swal.fire("Opps!", "Invalid Token", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeApiService.add(
        token,
        name,
        url,
        userId,
        password,
        xtoken,
        status
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Recharge API">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Recharge API</h3>
                    <div class="card-tools">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/admin/recharge_api_list");
                        }}
                      >
                        Recharge API List
                      </button>
                    </div>
                  </div>

                  <div class="row mt-2 mb-2">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              API Name
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="API Name"
                                ref={nameRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">URL</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="URL"
                                ref={urlRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              User ID
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="User ID"
                                ref={userIdRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Password
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Password"
                                ref={passwordRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Token</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Token"
                                ref={tokenRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Status
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <option value="">---Select Status---</option>
                                <option value="Enabled">Enabled</option>
                                <option value="Disabled">Disabled</option>
                              </select>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addApiHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddRechargeApi;
