import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const EmailSettings = (props) => {
  const data = props.data.email_settings;

  const loadingCtx = useContext(LoadingContext);

  const emailRef = useRef();
  const encryptionRef = useRef();
  const smtpHostRef = useRef();
  const smtpPortRef = useRef();
  const smtpUsernameRef = useRef();
  const smtpPasswordRef = useRef();

  const updateHandler = async () => {
    const email = emailRef.current.value;
    const encryption = encryptionRef.current.value;
    const smtpHost = smtpHostRef.current.value;
    const smtpPort = smtpPortRef.current.value;
    const smtpUsername = smtpUsernameRef.current.value;
    const smtpPassword = smtpPasswordRef.current.value;

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateEmailSettings(
        token,
        email,
        encryption,
        smtpHost,
        smtpPort,
        smtpUsername,
        smtpPassword
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const temailRef = useRef();

  const sendTestMail = async () => {
    const email = temailRef.current.value;

    if (!email) {
      Swal.fire("Opps!", "Invalid Email ID", "error");
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.sendTestMail(token, email);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Encryption</label>
            <div class="col-sm-10">
              <select className="form-control" ref={encryptionRef}>
                <option value={data ? data.encryption : ""}>
                  {data ? data.encryption : "Select Encryption"}
                </option>
                <option value="None">None</option>
                <option value="TLS">TLS</option>
                <option value="SSL">SSL</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">From Email</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={emailRef}
                defaultValue={data ? data.email : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">SMTP Host</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={smtpHostRef}
                defaultValue={data ? data.smtp_host : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">SMTP Port</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={smtpPortRef}
                defaultValue={data ? data.smtp_port : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">SMTP Username</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={smtpUsernameRef}
                defaultValue={data ? data.smtp_username : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">SMTP Password</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={smtpPasswordRef}
                defaultValue={data ? data.smtp_password : ""}
              />
            </div>
          </div>

          <div class="text-center pb-3">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row pt-4">
            <div class="input-group input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Email Address"
                ref={temailRef}
              />
              <span class="input-group-append">
                <button
                  type="button"
                  class="btn btn-info btn-flat"
                  onClick={() => {
                    sendTestMail();
                  }}
                >
                  Send Test Mail
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailSettings;
