import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip } from "@material-ui/core";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import ProductService from "../../../services/Admin/ProductService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

const WalletTopupHistory = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [category, setCategory] = useState();
  const [productId, setProductId] = useState();
  const [userId, setUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  useEffect(() => {
    // fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [products, setProducts] = React.useState();

  const fetchProducts = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await ProductService.fetchAll(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WalletService.walletTopupHistory(
      token,
      fromDate,
      toDate,
      status,
      category,
      productId,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div>
          <span>{row.order_id}</span>
          <br />
          <small>{row.created_on}</small>
        </div>
      ),
    },
    {
      name: "Operator ID",
      cell: (row) => (
        <div>
          <span>{row.operator_id}</span>
        </div>
      ),
    },
    {
      name: "TXN Type",
      cell: (row) => (
        <div>
          {(() => {
            if (row.txn_type === "CR") {
              return <span class="badge badge-success">CREDIT</span>;
            } else {
              return <span class="badge badge-danger">DEBIT</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Order Amount",
      cell: (row) => (
        <div>
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={"₹" + row.order_amount.toFixed(2)}
          />
        </div>
      ),
    },
    {
      name: "Credit/Debit",
      cell: (row) => (
        <div>
          {(() => {
            if (row.txn_type === "CR") {
              return (
                <Chip
                  style={{ color: "green", borderColor: "green" }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  label={"+" + row.amount.toFixed(2)}
                />
              );
            } else {
              return (
                <Chip
                  style={{ color: "red", borderColor: "red" }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  label={"-" + row.amount.toFixed(2)}
                />
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Balance",
      cell: (row) => (
        <div>
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={"₹" + row.closing_bal.toFixed(2)}
          />
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <Chip
                  style={{ backgroundColor: "green" }}
                  size="small"
                  color="primary"
                  label="SUCCESS"
                />
              );
            } else if (row.status === "FAILED") {
              return (
                <Chip
                  style={{ backgroundColor: "red" }}
                  size="small"
                  color="primary"
                  label="FAILED"
                />
              );
            } else if (row.status === "PENDING") {
              return (
                <Chip
                  style={{ backgroundColor: "#FFCC00" }}
                  size="small"
                  color="primary"
                  label="PENDING"
                />
              );
            } else if (row.status === "REVERSAL") {
              return <Chip size="small" color="default" label="REVERSAL" />;
            } else if (row.status === "REFUND") {
              return <Chip size="small" color="default" label="REFUND" />;
            }
          })()}

          <br />
          <span
            style={{ textAlign: "center" }}
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            View Details
          </span>
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div>
    //       <IconButton
    //         color="primary"
    //         aria-label="upload picture"
    //         component="span"
    //         onClick={() => {
    //           setSelectedData(row);
    //           setOpen(true);
    //         }}
    //       >
    //         <MenuOpenIcon />
    //       </IconButton>
    //     </div>
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Wallet Topup History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Wallet Topup History</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setCategory(e.target.value);
                                  }}
                                >
                                  <option value="">Select Category</option>
                                  <option value="TXN">TXN</option>
                                  <option value="COMMISSION">COMMISSION</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option value="">
                                    Select Transaction Type
                                  </option>
                                  <option value="62">WALLET DEBIT</option>
                                  <option value="63">WALLET CREDIT</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                class="btn btn-primary mr-4"
                                onClick={() => {
                                  fetchData();
                                }}
                              >
                                <i class="fa fa-search"></i> Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </React.Fragment>
  );
};

export default WalletTopupHistory;
