import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const CommissionSummary = () => {
  const loadingCtx = useContext(LoadingContext);

  const [products, setProducts] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [productId, setProductId] = useState();

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, [toDate, fromDate, productId]);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.commissionSummary(
        token,
        fromDate,
        toDate,
        productId
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;
        setData(data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchProductListHandler();
  }, []);

  const fetchProductListHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.productList(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setProducts(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Commission Summary">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Commission Summary</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                    // alert(e.target.value);
                                  }}
                                >
                                  <option value="">Select Product</option>
                                  {products &&
                                    products.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {data && (
                      <div
                        className="table-responsive"
                        style={{ marginTop: "30px" }}
                      >
                        <table
                          className="table table-dark"
                          style={{ border: "1px solid black" }}
                        >
                          <tbody>
                            <tr>
                              <td>TOTAL COMMISSION</td>
                              <td>:</td>
                              <td>
                                <b>₹{data.my_commission}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default CommissionSummary;
