import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/Admin/AuthService";
import ExpenseService from "../../../services/Admin/ExpenseService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddCategoryDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const nameRef = useRef();

  const addCategoryHandler = async () => {
    const name = nameRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.addExpenseCategory(token, name);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        await props.expenseCategoryList();
        props.handleClose();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Category</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      ref={nameRef}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            <button
              type="button"
              class="btn btn-primary"
              onClick={addCategoryHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddCategoryDialog;
