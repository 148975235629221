import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

import AuthService from "../../../services/User/AuthService";
import DeveloperApiService from "../../../services/User/DeveloperApiService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const IPWhitelist = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const ipRef = useRef();

  const submitHandler = async () => {
    const ip = ipRef.current.value;

    loadingCtx.startLoading();

    const ips = ip.split(",");

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DeveloperApiService.ipWhitelist(token, ips);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  let i = 0;
  let defaultValue = "";
  const data = props.data ? JSON.parse(props.data.whitelisted_ips) : null;
  const ips = data ? data.ips : [];

  ips.forEach((e) => {
    defaultValue += e + ",";

    if (!data.ips.length === i) {
      defaultValue += e + ",";
    } else {
      defaultValue += e;
    }

    i++;
  });

  return (
    <React.Fragment>
      <div className="mt-3 mb-3">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div class="form-group">
              <label for="exampleInputEmail1">IP Address</label>
              <input
                type="text"
                class="form-control"
                ref={ipRef}
                placeholder="Ex: 000.000.00.000, 111.111.11.111"
                defaultValue={ips}
              />
              <p className="mt-1">Use comma (,) to add multiple IPs</p>
            </div>

            <div className="text-center plan-btn mt-2 mb-2">
              <Button
                color="primary"
                onClick={() => {
                  submitHandler();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IPWhitelist;
