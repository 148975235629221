import React from "react";

import Templete from "../Templete/Templete";

import UserDetails from "./UserDetails";
import EditUserForm from "./EditUserForm";
import ResetLoginDetailsForm from "./ResetLoginDetailsForm";
import UpdateBankDetails from "./UpdateBankDetails";

const UserProfile = (props) => {
  return (
    <React.Fragment>
      <Templete t1="Home" t2="User Profile">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#viewProfile"
                          data-toggle="tab"
                        >
                          View Profile
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#editProfile"
                          data-toggle="tab"
                        >
                          Edit Profile
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#updateBankDetails"
                          data-toggle="tab"
                        >
                          Update Bank Details
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#resetLoginDetails"
                          data-toggle="tab"
                        >
                          Reset Login Details
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div class="card-body">
                        <div class="tab-content">
                          <div class="active tab-pane" id="viewProfile">
                            <UserDetails userId={props.match.params.userId} />
                          </div>

                          <div class="tab-pane" id="editProfile">
                            <EditUserForm userId={props.match.params.userId} />
                          </div>

                          <div class="tab-pane" id="updateBankDetails">
                            <UpdateBankDetails
                              userId={props.match.params.userId}
                            />
                          </div>

                          <div class="tab-pane" id="resetLoginDetails">
                            <ResetLoginDetailsForm
                              userId={props.match.params.userId}
                            />
                          </div>

                          <div class="tab-pane" id="joiningVoucher"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UserProfile;
