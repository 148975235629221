import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import DeveloperApiService from "../../../services/User/DeveloperApiService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import OtpDialog from "./OtpDialog";
import Webhooks from "./Webhooks";
import IPWhitelist from "./IPWhitelist";

const DeveloperAPI = () => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [otpToken, setOtpToken] = useState();
  const [errMsg, setErrMsg] = useState();

  const regenerateKeyHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DeveloperApiService.regenerateKey(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const _otpToken = responseData.data.token;

        setOtpToken(_otpToken);
        setErrMsg(message);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DeveloperApiService.getKey(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const _data = responseData.data;

        setData(_data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Developer API">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Developer API</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        <div class="col-md-8">
                          <div class="card-body">
                            <div class="card card-primary">
                              <div class="card-header p-0 border-bottom-0">
                                <ul
                                  class="nav nav-tabs"
                                  id="custom-content-below-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active"
                                      id="custom-content-below-home-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-home"
                                      role="tab"
                                      aria-controls="custom-content-below-home"
                                      aria-selected="true"
                                    >
                                      API Keys
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      id="custom-content-below-profile-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-profile"
                                      role="tab"
                                      aria-controls="custom-content-below-profile"
                                      aria-selected="false"
                                    >
                                      Webhooks
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      id="custom-content-below-ip-tab"
                                      data-toggle="pill"
                                      href="#custom-content-below-ip"
                                      role="tab"
                                      aria-controls="custom-content-below-ip"
                                      aria-selected="false"
                                    >
                                      IP Whitelist
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              class="tab-content pt-1"
                              id="custom-content-below-tabContent"
                            >
                              <div
                                class="tab-pane fade show active"
                                id="custom-content-below-home"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-home-tab"
                              >
                                <div className="mt-3 mb-3">
                                  {data && (
                                    <div className="table-responsive">
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th scope="col">Client ID</th>
                                            <th scope="col">Client Secret</th>
                                            <th scope="col">Encryption Key</th>
                                            <th scope="col">Date Time</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{data.client_id}</td>
                                            <td>**********</td>
                                            <td>{data.encryption_key}</td>
                                            <td>{data.modified_on}</td>
                                            <td>
                                              <Button
                                                size="sm"
                                                type="submit"
                                                onClick={regenerateKeyHandler}
                                              >
                                                Regenerate
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="custom-content-below-profile"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-profile-tab"
                              >
                                <Webhooks data={data} />
                              </div>

                              <div
                                class="tab-pane fade"
                                id="custom-content-below-ip"
                                role="tabpanel"
                                aria-labelledby="custom-content-below-ip-tab"
                              >
                                <IPWhitelist data={data} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <OtpDialog
        open={open}
        handleClose={handleClose}
        errMsg={errMsg}
        otpToken={otpToken}
        fetchData={fetchData}
      />
    </React.Fragment>
  );
};

export default DeveloperAPI;
