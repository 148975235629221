import common from "../../utill/Common";

class LeadManagementService {
  addLeadProduct = async (
    token,
    category_id,
    name,
    title,
    sub_title,
    logo,
    payout_type,
    earn_upto,
    data,
    status,
    default_route,
    route1,
    route2,
    bs_product_id,
    utm_link
  ) => {
    const url = common.baseUrl + "admin/lead_management/add_lead_product";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        category_id,
        name,
        title,
        sub_title,
        logo,
        payout_type,
        earn_upto,
        data,
        status,
        default_route,
        route1,
        route2,
        bs_product_id,
        utm_link,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateLeadProduct = async (
    token,
    id,
    category_id,
    name,
    title,
    sub_title,
    logo,
    payout_type,
    earn_upto,
    data,
    status,
    default_route,
    route1,
    route2,
    bs_product_id,
    utm_link
  ) => {
    const url = common.baseUrl + "admin/lead_management/update_lead_product";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        category_id,
        name,
        title,
        sub_title,
        logo,
        payout_type,
        earn_upto,
        data,
        status,
        default_route,
        route1,
        route2,
        bs_product_id,
        utm_link,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteLeadProduct = async (token, id) => {
    const url = common.baseUrl + "admin/lead_management/delete_lead_product";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchProducts = async (
    token,
    category_id,
    search_text,
    status,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/lead_management/fetch_products";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        category_id,
        search_text,
        status,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchProductById = async (token, id) => {
    const url = common.baseUrl + "admin/lead_management/fetch_product_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchCategories = async (token, id) => {
    const url = common.baseUrl + "admin/lead_management/fetch_categories";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LeadManagementService();
