import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/User/AuthService";
import DeveloperApiService from "../../../services/User/DeveloperApiService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import KeyDetailsDialog from "./KeyDetailsDialog";

const OtpDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const otpRef = useRef();

  const otpVerifyHandler = async (e) => {
    e.preventDefault();

    const otp = otpRef.current.value;

    if (!otp || isNaN(otp) || otp.length < 6) {
      Swal.fire("Opps!", "Invalid OTP", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DeveloperApiService.regenerateKeyOtpVerify(
        token,
        props.otpToken,
        otp
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        await props.fetchData();

        const client_id = responseData.data.client_id;
        const client_secret = responseData.data.client_secret;
        const encryption_key = responseData.data.encryption_key;

        setClientId(client_id);
        setClientSecret(client_secret);
        setEncryptionKey(encryption_key);

        setOpen(true);
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [clientId, setClientId] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [encryptionKey, setEncryptionKey] = useState();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">OTP Verification</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-success" role="alert">
              <b>Success! </b> {props.errMsg}
            </div>

            <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={otpRef}
                class="form-control"
                placeholder="OTP (One Time Password)"
                maxLength={6}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={otpVerifyHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <KeyDetailsDialog
        open={open}
        handleClose={handleClose}
        clientId={clientId}
        clientSecret={clientSecret}
        encryptionKey={encryptionKey}
      />
    </React.Fragment>
  );
};

export default OtpDialog;
