import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

//import Zoom from "react-medium-image-zoom";
//import "react-medium-image-zoom/dist/styles.css";
import Swal from "sweetalert2";

import HttpError from "../../../../utill/HttpError";
import UserService from "../../../../services/Admin/UserService";
import AuthService from "../../../../services/Admin/AuthService";

const PersonalDetailsTab = (props) => {
  const [isLoading, setIsloading] = useState(false);

  const checkSimilarityHandler = async (type) => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const data = await UserService.faceComparison(
        token,
        props.userData ? props.userData.id : "",
        type
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const similarity = data.data.similarity;

        Swal.fire("Success!", similarity + "%", "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  return (
    <React.Fragment>
      <div class="row justify-content-center">
        <div class="col-sm">
          {/* <Zoom> */}
          {props.base64Data[0]["value"] && (
            <img
              alt="profile img"
              src={props.base64Data[0]["value"]}
              width="100"
            />
          )}
          {/* </Zoom> */}
          {!props.base64Data[0]["value"] && (
            <button
              onClick={() => {
                props.getImageHandler(0);
              }}
              className="btn btn-primary"
              disabled={props.isLoading}
            >
              {props.isLoading && <CircularProgress size={14} />}
              {!props.isLoading && "View Profile Image"}
            </button>
          )}
        </div>

        <div class="col-sm">
          <button
            onClick={() => {
              checkSimilarityHandler("selfie_aadhaar");
            }}
            className="btn btn-primary"
            disabled={isLoading}
          >
            Selfie Aadhaar Similarity
          </button>
        </div>

        <div class="col-sm">
          <button
            onClick={() => {
              checkSimilarityHandler("selfie_pan");
            }}
            className="btn btn-primary"
            disabled={isLoading}
          >
            Selfie PAN Similarity
          </button>
        </div>
      </div>

      <div class="row justify-content-center pt-3">
        <div class="col-sm">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.fathers_name}
              </ListItemSecondaryAction>
              <ListItemText primary="Fathers Name" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.mothers_name}
              </ListItemSecondaryAction>
              <ListItemText primary="Mathers Name" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.dob}
              </ListItemSecondaryAction>
              <ListItemText primary="Date of Birth" />
            </ListItem>
            {/* 
          <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.gender}
              </ListItemSecondaryAction>
              <ListItemText primary="Gender" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.mobile}
              </ListItemSecondaryAction>
              <ListItemText primary="Mobile Number" />
            </ListItem>
          </List>
        </div>

        <div class="col-sm">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.physically_handicapped}
              </ListItemSecondaryAction>
              <ListItemText primary="Physically Handicapped" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.marital_status}
              </ListItemSecondaryAction>
              <ListItemText primary="Marital Status" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.category}
              </ListItemSecondaryAction>
              <ListItemText primary="Category" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.qualification}
              </ListItemSecondaryAction>
              <ListItemText primary="Qualification" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.alternative_number}
              </ListItemSecondaryAction>
              <ListItemText primary="Alternative Number" />
            </ListItem>
          </List>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalDetailsTab;
