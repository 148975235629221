import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import LeadManagementService from "../../../services/Admin/LeadManagementService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const LeadProductList = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [categoryId, setCategoryId] = useState();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState();
  const [page, setPage] = useState();
  const [size, setSize] = useState(10);
  const [count, setCount] = useState();

  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    fetchData();
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    LeadManagementService.fetchProducts(
      token,
      categoryId,
      searchText,
      status,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setData(dataResult);
        setCount(responseData.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "Product ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.id}</span>
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      name: "Title",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.title}</span>
          <br />
          <small>{row.sub_title}</small>
        </div>
      ),
    },
    {
      name: "Logo",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            <img src={row.logo} width="50px" />
          </span>
        </div>
      ),
    },
    {
      name: "Earn Upto",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.earn_upto}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "Active") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              window.open("/admin/edit_lead_product/" + row.id, "_blank");
              return true;
            }}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              Swal.fire({
                title: "Are you sure to delete?",
                showCancelButton: true,
                confirmButtonText: `Delete`,
                confirmButtonColor: "red",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteHandler(row.id);
                }
              });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await LeadManagementService.deleteLeadProduct(
        token,
        id
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        fetchData();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [leadCategories, setLeadCategories] = useState([]);

  const fetchCategories = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await LeadManagementService.fetchCategories(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setLeadCategories(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Lead Product List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Lead Product List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <Autocomplete
                                  size={"small"}
                                  options={leadCategories}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      size="sm"
                                      {...params}
                                      label="Select Category"
                                    />
                                  )}
                                  onChange={(e, v) => {
                                    setCategoryId(v ? v.id : null);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">---Select Status---</option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default LeadProductList;
