import common from "../../utill/Common";

class PPIService {
  fetchUsers = async (
    token,
    user_id,
    status,
    min_kyc_status,
    full_kyc_status,
    size,
    page
  ) => {
    const url = common.baseUrl + "admin/ppi/user/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        status,
        min_kyc_status,
        full_kyc_status,
        size,
        page,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  userSummary = async (token) => {
    const url = common.baseUrl + "admin/ppi/user/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchCards = async (
    token,
    user_id,
    status,
    order_status,
    search_text,
    size,
    page
  ) => {
    const url = common.baseUrl + "admin/ppi/card/fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        status,
        order_status,
        search_text,
        size,
        page,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchCardById = async (token, id) => {
    const url = common.baseUrl + "admin/ppi/card/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  topup = async (token, id, amount, reference_id, remark) => {
    const url = common.baseUrl + "admin/ppi/card/topup";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        amount,
        reference_id,
        remark,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PPIService();
