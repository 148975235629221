import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

//import Zoom from "react-medium-image-zoom";
//import "react-medium-image-zoom/dist/styles.css";

import JsonFormatter from "react-json-formatter";

const AadhaarDetailsTab = (props) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <React.Fragment>
      <div class="row justify-content-center">
        <div class="col-sm">
          {/* <Zoom> */}
          {props.base64Data[3]["value"] && (
            <img
              alt="aadhaar frontside img"
              src={props.base64Data[3]["value"]}
              width="100"
            />
          )}
          {/* </Zoom> */}

          {props.base64Data[3]["value"] && (
            <a
              href={props.base64Data[3]["value"]}
              download={props.userData.poa_frontside_img}
            >
              Download
            </a>
          )}

          {!props.base64Data[3]["value"] && (
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                props.getImageHandler(3);
              }}
              disabled={props.isLoading}
            >
              {props.isLoading && <CircularProgress size={14} />}
              {!props.isLoading && "View Aadhaar Front Image"}
            </button>
          )}
        </div>
        <div class="col-sm">
          {/* <Zoom> */}
          {props.base64Data[4]["value"] && (
            <img
              alt="aadhaar backside img"
              src={props.base64Data[4]["value"]}
              width="100"
            />
          )}
          {/* </Zoom> */}

          {props.base64Data[4]["value"] && (
            <a
              href={props.base64Data[4]["value"]}
              download={props.userData.poa_backside_img}
            >
              Download
            </a>
          )}

          {!props.base64Data[4]["value"] && (
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                props.getImageHandler(4);
              }}
              disabled={props.isLoading}
            >
              {props.isLoading && <CircularProgress size={14} />}
              {!props.isLoading && "View Aadhaar Back Image"}
            </button>
          )}
        </div>

        <div class="col-sm">
          {/* <Zoom> */}
          {props.base64Data[5]["value"] && (
            <img
              alt="aadhaar backside img"
              src={props.base64Data[5]["value"]}
              width="100"
            />
          )}
          {/* </Zoom> */}

          {!props.base64Data[5]["value"] && (
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                props.getImageHandler(5);
              }}
              disabled={props.isLoading}
            >
              {props.isLoading && <CircularProgress size={14} />}
              {!props.isLoading && "View Image On Aadhaar"}
            </button>
          )}
        </div>
      </div>
      <div class="row justify-content-center pt-3">
        <div class="col-sm">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.poa_id_number}
              </ListItemSecondaryAction>
              <ListItemText primary="Aadhaar Number" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.encrypted_poa_id_number}
              </ListItemSecondaryAction>
              <ListItemText primary="Encrypted Aadhaar Number" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.aadhaar_linked_with_mobile}
              </ListItemSecondaryAction>
              <ListItemText primary="Is Aadhaar Linked With Mobile" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.poa_verified}
              </ListItemSecondaryAction>
              <ListItemText primary="Is Aadhaar Verified" />
            </ListItem>
          </List>
        </div>
        <div class="col-sm">
          {props.userData && props.userData.eaadhaar && (
            <JsonFormatter
              json={props.userData.eaadhaar}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AadhaarDetailsTab;
