import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const EditProfileForm = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [userId, setUserId] = useState();
  const [data, setData] = useState();

  const [title, setTitle] = useState();
  const [gender, setGender] = useState();
  const [state, setState] = useState();

  const formRef = useRef();
  const panNumberRef = useRef();
  const nameRef = useRef();
  const fathersNameRef = useRef();
  const dobRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const pincodeRef = useRef();
  const cityRef = useRef();
  const districtRef = useRef();
  const addressRef = useRef();

  const updateHandler = async () => {
    const panNumber = panNumberRef.current.value;
    const name = nameRef.current.value;
    const fathersName = fathersNameRef.current.value;
    const dob = dobRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const pincode = pincodeRef.current.value;
    const city = cityRef.current.value;
    const district = districtRef.current.value;
    const address = addressRef.current.value;

    if (!panNumber) {
      Swal.fire("Opps!", "Invalid PAN Number", "error");
      return;
    }
    if (!title) {
      Swal.fire("Opps!", "Invalid Title", "error");
      return;
    }
    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }
    if (!dob) {
      Swal.fire("Opps!", "Invalid Date of Birth", "error");
      return;
    }
    if (!gender) {
      Swal.fire("Opps!", "Invalid Gender", "error");
      return;
    }
    if (!fathersName) {
      Swal.fire("Opps!", "Invalid Fathers Name", "error");
      return;
    }
    if (!mobile || mobile.length < 10 || mobile.length > 10) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }
    if (!email) {
      Swal.fire("Opps!", "Invalid Email ID", "error");
      return;
    }
    if (!pincode) {
      Swal.fire("Opps!", "Invalid PIN Code", "error");
      return;
    }
    if (!state) {
      Swal.fire("Opps!", "Invalid State", "error");
      return;
    }
    if (!city) {
      Swal.fire("Opps!", "Invalid City", "error");
      return;
    }
    if (!district) {
      Swal.fire("Opps!", "Invalid District", "error");
      return;
    }
    if (!address) {
      Swal.fire("Opps!", "Invalid Address", "error");
      return;
    }

    loadingCtx.startLoading();
    try {
      const token = AuthService.getAccessToken();
      const responseData = await ProfileService.update(
        token,
        panNumber,
        title,
        name,
        dob,
        gender,
        fathersName,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address
      );
      const status1 = responseData.status;
      const message = responseData.message;
      if (status1 === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const userId = AuthService.getUserID();
    if (userId) {
      setUserId(userId);
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.fetch(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setTitle(responseData.data.title);
        setState(responseData.data.state);
        setGender(responseData.data.gender);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-12 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">User Type</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      readOnly
                      defaultValue={data.user_type}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">User ID</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      readOnly
                      defaultValue={userId}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">PAN Number</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="PAN Number"
                      maxLength={10}
                      ref={panNumberRef}
                      defaultValue={data.pan_number}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Title</label>
                  <div class="col-sm-10">
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    >
                      <option defaultValue={data.title}>{data.title}</option>
                      <option defaultValue="Mr.">Mr.</option>
                      <option defaultValue="Mrs.">Mrs.</option>
                      <option defaultValue="Ms.">Ms.</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      ref={nameRef}
                      defaultValue={data.name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Date of Birth</label>
                  <div class="col-sm-10">
                    <input
                      type="date"
                      class="form-control"
                      ref={dobRef}
                      defaultValue={data.dob}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Gender</label>
                  <div class="col-sm-10">
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option defaultValue={data.gender}>
                        {data.gender ? data.gender : "---Select Gender---"}
                      </option>
                      <option defaultValue="Male">Male</option>
                      <option defaultValue="Female">Female</option>
                      <option defaultValue="Transgender">Transgender</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Father's Name</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Father's Name"
                      ref={fathersNameRef}
                      defaultValue={data.fathers_name}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Mobile Number</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Mobile Number"
                      maxLength={10}
                      onInput={(e) => {
                        e.target.defaultValue = e.target.defaultValue.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      ref={mobileRef}
                      defaultValue={data.mobile}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Email ID</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="Email ID"
                      ref={emailRef}
                      defaultValue={data.email}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">PIN Code</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="PIN Code"
                      maxLength={6}
                      onInput={(e) => {
                        e.target.defaultValue = e.target.defaultValue.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                      ref={pincodeRef}
                      defaultValue={data.pincode}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">State</label>
                  <div class="col-sm-10">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    >
                      <option defaultValue={data.state}>{data.state}</option>

                      {states &&
                        states.map((e, key) => {
                          return (
                            <option key={key} defaultValue={e.defaultValue}>
                              {e.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">City</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      ref={cityRef}
                      defaultValue={data.city}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">District</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="District"
                      ref={districtRef}
                      defaultValue={data.district}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Address</label>
                  <div class="col-sm-10">
                    <textarea
                      ref={addressRef}
                      className="form-control"
                      placeholder="Address"
                      rows="3"
                      defaultValue={data.address}
                    ></textarea>
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditProfileForm;
