import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  ListItemSecondaryAction,
} from "@material-ui/core";

//import Zoom from "react-medium-image-zoom";
//import "react-medium-image-zoom/dist/styles.css";

import JsonFormatter from "react-json-formatter";

const PanDetailsTab = (props) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <React.Fragment>
      <div class="row justify-content-center">
        {/* <Zoom> */}
        {props.base64Data[2]["value"] && (
          <img alt="pan img" src={props.base64Data[2]["value"]} width="100" />
        )}
        {/* </Zoom> */}

        {props.base64Data[2]["value"] && (
          <a
            href={props.base64Data[2]["value"]}
            download={props.userData.pan_img}
          >
            Download
          </a>
        )}

        {!props.base64Data[2]["value"] && (
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              props.getImageHandler(2);
            }}
            disabled={props.isLoading}
          >
            {props.isLoading && <CircularProgress size={14} />}
            {!props.isLoading && "View PAN Image"}
          </button>
        )}
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-6">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.pan_number}
              </ListItemSecondaryAction>
              <ListItemText primary="PAN Number" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.pan_verified}
              </ListItemSecondaryAction>
              <ListItemText primary="Is PAN Verified ?" />
            </ListItem>
          </List>
        </div>
        {/* <div class="col-sm-6">
          {props.userData && props.userData.pan_json && (
            <JsonFormatter
              json={props.userData && props.userData.pan_json}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default PanDetailsTab;
