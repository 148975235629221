import React, { useState, useEffect } from "react";
import DataTableComponent from "react-data-table-component";

// import IconButton from "@mui/material/IconButton";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import EditIcon from "@mui/icons-material/Edit";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Tree from "react-animated-tree";

// import TreeView from "@mui/lab/TreeView";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import TreeItem from "@mui/lab/TreeItem";

import AuthService from "../../services/User/AuthService";
import DbModuleService from "../../services/User/DbModuleService";

const UserListSso = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorization = params.get("authorization");

    if (!authorization) {
      return;
    }

    const token = authorization;

    setLoading(true);

    DbModuleService.list(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.children;

        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.user_type === "Admin") {
              return (
                <span class="badge badge-pill badge-success">
                  {row.user_type}
                </span>
              );
            } else {
              return (
                <span class="badge badge-pill badge-primary">
                  {row.user_type}
                </span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.user_id}</span>
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      name: "Mobile",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.mobile}</span>
        </div>
      ),
    },
    {
      name: "Invt Bal",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>₹{row.main_wallet}</span>
        </div>
      ),
    },

    {
      name: "Reg Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
  ];

  const renderTree = (nodes) => (
    <Tree
      id={nodes.id}
      content={
        nodes.name +
        " (" +
        nodes.user_id +
        ")" +
        " (Invt Bal: ₹" +
        nodes.main_wallet +
        ")"
      }
    >
      {nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </Tree>
  );

  const ExpandedComponent = ({ data }) => (
    <pre>
      {renderTree(data)}

      {/* <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          height: 110,
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
        }}
      >
        {renderTree(data)}
      </TreeView> */}
    </pre>
  );

  return (
    <React.Fragment>
      <DataTableComponent
        noHeader={true}
        columns={columns}
        data={data}
        progressPending={loading}
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
      />
    </React.Fragment>
  );
};

export default UserListSso;
