import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import AuthService from "../../../services/User/AuthService";
import CommercialService from "../../../services/User/CommercialService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import EditPackageDialog from "./EditPackageDialog";

const PackageList = () => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    CommercialService.fetchPackages(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }
        const dataResult = responseData.data;
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>{row.name}</b>
        </div>
      ),
    },

    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <b>{row.status}</b>
        </div>
      ),
    },
    {
      name: "Created On",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Edit",
      cell: (row) => (
        <h6>
          <span
            class="badge badge-primary"
            onClick={() => {
              setOpen(true);
              setSelectedData(row);
            }}
          >
            Edit
          </span>
        </h6>
      ),
    },

    {
      name: "Delete",
      cell: (row) => (
        <h6>
          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              Swal.fire({
                title: "Are you sure to delete?",
                showCancelButton: true,
                confirmButtonText: `Delete`,
                confirmButtonColor: "red",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteHandler(row.id);
                }
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </h6>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CommercialService.deletePackage(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        getData();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Package List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Package List</h3>
                  </div>

                  <div class="card-body">
                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <EditPackageDialog
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        getData={getData}
        selectedData={selectedData}
      />
    </React.Fragment>
  );
};

export default PackageList;
