import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";

const IncomppleteApplication = () => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const orderIdRef = useRef();

  const submitHandler = async () => {
    const order_id = orderIdRef.current.value;

    if (!order_id) {
      Swal.fire("Opps!", "Invalid Order ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.txnStatus(token, order_id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        var txnStatus = responseData.data.status;
        var authorization = responseData.data.authorization;

        if (txnStatus == "PENDING") {
          const url =
            "https://sso-nsdl-ekyc-app.pages.dev/nsdl_ekyc_redirect" +
            `?authorization=${authorization}&is_incomplete=true`;

          const newWindow = window.open(url, "_blank", "noopener,noreferrer");

          if (newWindow) newWindow.opener = null;
        } else {
          Swal.fire(
            "Opps!",
            "Only pending transactions are allowed. The transaction ID you have entered does not in pending stage.",
            "error"
          );
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <div className="mt-3 mb-3">
      <form ref={formRef}>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control "
                placeholder="Order ID"
                ref={orderIdRef}
              />
            </div>
          </div>
        </div>

        <div class="text-center plan-btn mt-2 mb-2">
          <button type="button" class="btn btn-primary" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default IncomppleteApplication;
