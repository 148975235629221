import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import LeadManagementService from "../../../services/Admin/LeadManagementService";
import PackageService from "../../../services/Admin/PackageService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const EditLeadProduct = (props) => {
  const loadingCtx = useContext(LoadingContext);

  React.useEffect(() => {
    fetchCategories();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState();

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await LeadManagementService.fetchProductById(
        token,
        id
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setStatus(responseData.data.status);
        setDefaultRoute(responseData.data.default_route);
        setCategoryId(responseData.data.category_id);
        setPayoutType(responseData.data.payout_type);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [leadCategories, setLeadCategories] = useState([]);

  const [categoryId, setCategoryId] = useState();
  const [status, setStatus] = useState();
  const [defaultRoute, setDefaultRoute] = useState();
  const [payoutType, setPayoutType] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const titleRef = useRef();
  const subTitleRef = useRef();
  const logoRef = useRef();
  const earnUptoRef = useRef();
  const dataRef = useRef();
  const route1Ref = useRef();
  const route2Ref = useRef();
  const bsProductIdRef = useRef();
  const utmLinkRef = useRef();

  const submitHandler = async () => {
    const name = nameRef.current.value;
    const title = titleRef.current.value;
    const subTitle = subTitleRef.current.value;
    const logo = logoRef.current.value;
    const earnUpto = earnUptoRef.current.value;
    const data = dataRef.current.value;
    const route1 = route1Ref.current.value;
    const route2 = route2Ref.current.value;
    const bsProductId = bsProductIdRef.current.value;
    const utmLink = utmLinkRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!title) {
      Swal.fire("Opps!", "Invalid Title", "error");
      return;
    }

    if (!subTitle) {
      Swal.fire("Opps!", "Invalid Sub Title", "error");
      return;
    }

    if (!logo) {
      Swal.fire("Opps!", "Invalid Logo", "error");
      return;
    }

    if (!earnUpto) {
      Swal.fire("Opps!", "Invalid Earn Upto", "error");
      return;
    }

    if (!data) {
      Swal.fire("Opps!", "Invalid Data", "error");
      return;
    }

    if (IsJsonString(data) == false) {
      Swal.fire("Opps!", "Invalid JSON data", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const id = props.id ? props.id : props.match.params.id;

      const token = AuthService.getAccessToken();

      const responseData = await LeadManagementService.updateLeadProduct(
        token,
        id,
        categoryId,
        name,
        title,
        subTitle,
        logo,
        payoutType,
        earnUpto,
        data,
        status,
        defaultRoute,
        route1,
        route2,
        bsProductId,
        utmLink
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        fetchData();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchCategories = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await LeadManagementService.fetchCategories(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setLeadCategories(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Lead Product">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add Lead Product</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body mt-2 mb-2">
                          {leadCategories && data && (
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Select Category
                              </label>
                              <div class="col-sm-10">
                                <Autocomplete
                                  options={leadCategories}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Category"
                                    />
                                  )}
                                  onChange={(e, v) => {
                                    setCategoryId(v ? v.id : null);
                                  }}
                                  defaultValue={leadCategories.find(
                                    (v) => v.id.toString() === data.category_id
                                  )}
                                />
                              </div>
                            </div>
                          )}

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                ref={nameRef}
                                defaultValue={data ? data.name : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Title</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                ref={titleRef}
                                defaultValue={data ? data.title : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Sub Title
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                ref={subTitleRef}
                                defaultValue={data ? data.sub_title : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Logo</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Logo"
                                ref={logoRef}
                                defaultValue={data ? data.logo : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Payout Type
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setPayoutType(e.target.value);
                                }}
                              >
                                <option value={payoutType}>{payoutType}</option>
                                <option value="FLAT">FLAT</option>
                                <option value="PERCENTAGE">PERCENTAGE</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Earn Upto
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Earn Upto"
                                ref={earnUptoRef}
                                defaultValue={data ? data.earn_upto : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Data</label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={dataRef}
                                cols="50"
                                rows="30"
                                defaultValue={data ? data.data : ""}
                              ></textarea>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Status
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <option value={status}>{status}</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="Paused">Paused</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Default Route
                            </label>
                            <div class="col-sm-10">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setDefaultRoute(e.target.value);
                                }}
                              >
                                <option value={defaultRoute}>
                                  {defaultRoute}
                                </option>
                                <option value="ROUTE_1">ROUTE_1</option>
                                <option value="ROUTE_2">ROUTE_2</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Route 1
                            </label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={route1Ref}
                                cols="4"
                                rows="4"
                                defaultValue={data ? data.route1 : ""}
                              ></textarea>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Route 2
                            </label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={route2Ref}
                                cols="4"
                                rows="4"
                                defaultValue={data ? data.route2 : ""}
                              ></textarea>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              BS Product ID
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="BS Product ID"
                                ref={bsProductIdRef}
                                defaultValue={data ? data.bs_product_id : ""}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              UTM Link
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder=" UTM Link"
                                ref={utmLinkRef}
                                defaultValue={data ? data.utm_link : ""}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditLeadProduct;
