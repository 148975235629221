import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import UserService from "../../../services/Admin/UserService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const UserDetails = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();
  const [parentData, setParentData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data.result);
        setParentData(responseData.data.parent_result);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      {/* <Templete t1="Home" t2="View Agent">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">View Agent</h3>
                  </div> */}

      {/* <div class="row">
        <div class="col-md-6 mx-auto"> */}
      {data && (
        <table cellpadding="0" cellspacing="0" border="0" width="100%">
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="0"
                width="100%"
                align="center"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        cellpadding="3"
                        cellspacing="0"
                        border="0"
                        width="100%"
                        align="center"
                      >
                        <tr>
                          <td width="10%" align="center" valign="middle">
                            <br />
                            <br />
                            <table
                              cellpadding="4"
                              cellspacing="0"
                              border="1"
                              width="70%"
                              align="center"
                            >
                              <tr>
                                <td colspan="2">
                                  <table border="0">
                                    <tr>
                                      <td width="208">
                                        <img
                                          src="/assets/img/no-image-icon.jpg"
                                          width="80"
                                          border="1"
                                          style={{
                                            border: "2px solid #333333",
                                          }}
                                          alt="profile img"
                                        />
                                      </td>
                                      <td width="610" align="center">
                                        <font color="#000000" size="+1">
                                          <b>
                                            {data.name} ({data.user_id})
                                          </b>
                                        </font>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4">
                                  <font size="+1">
                                    <b style={{ color: "red" }}>
                                      Personal Details:
                                    </b>
                                  </font>
                                </td>
                              </tr>
                              <tr>
                                <td width="44%">
                                  <b>ID</b>
                                </td>
                                <td width="56%">{data.id}</td>
                              </tr>
                              <tr>
                                <td width="44%">
                                  <b>User Type</b>
                                </td>
                                <td width="56%">{data.user_type}</td>
                              </tr>
                              <tr>
                                <td width="44%">
                                  <b>User ID</b>
                                </td>
                                <td width="56%">{data.user_id}</td>
                              </tr>
                              <tr>
                                <td width="44%">
                                  <b>PAN Number</b>
                                </td>
                                <td width="56%">{data.pan_number}</td>
                              </tr>
                              {parentData && data && (
                                <tr>
                                  <td>
                                    <b>Introducer Name</b>
                                  </td>
                                  <td>
                                    {parentData.parent_name} {"("}
                                    {parentData.parent_user_id}
                                    {")"}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>{data.name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Gender</b>
                                </td>
                                <td>{data.gender}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Date Of Birth</b>
                                </td>
                                <td>{data.dob}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Mobile</b>
                                </td>
                                <td>{data.mobile}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email ID</b>
                                </td>
                                <td>{data.email}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>PIN Code</b>
                                </td>
                                <td>{data.pincode}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>State</b>
                                </td>
                                <td>{data.state}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>City</b>
                                </td>
                                <td>{data.city}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>District</b>
                                </td>
                                <td>{data.district}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Address</b>
                                </td>
                                <td>{data.address}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Status</b>
                                </td>
                                <td>{data.status}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Registration Date</b>
                                </td>
                                <td>{data.created_on}</td>
                              </tr>

                              {data.shop_name && (
                                <React.Fragment>
                                  <tr>
                                    <td colspan="4">
                                      <font size="+1">
                                        <b style={{ color: "red" }}>
                                          Business/Shop Details:
                                        </b>
                                      </font>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop Name</b>
                                    </td>
                                    <td>{data.shop_name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop Type</b>
                                    </td>
                                    <td>{data.shop_type}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop PIN Code</b>
                                    </td>
                                    <td>{data.shop_pincode}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop State</b>
                                    </td>
                                    <td>{data.shop_state}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop City</b>
                                    </td>
                                    <td>{data.shop_city}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop District</b>
                                    </td>
                                    <td>{data.shop_district}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop Address</b>
                                    </td>
                                    <td>{data.shop_type}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop Latitude</b>
                                    </td>
                                    <td>{data.shop_latitude}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Shop Longitude</b>
                                    </td>
                                    <td>{data.shop_longitude}</td>
                                  </tr>
                                </React.Fragment>
                              )}

                              {data.account_number && (
                                <React.Fragment>
                                  <tr>
                                    <td colspan="4">
                                      <font size="+1">
                                        <b style={{ color: "red" }}>
                                          Bank A/C Details:
                                        </b>
                                      </font>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Beneficiary Name</b>
                                    </td>
                                    <td>{data.name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Bank Name</b>
                                    </td>
                                    <td>{data.bank_name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Account Number</b>
                                    </td>
                                    <td>{data.account_number}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>IFSC Code</b>
                                    </td>
                                    <td>{data.ifsc_code}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Account Type</b>
                                    </td>
                                    <td>{data.account_type}</td>
                                  </tr>
                                </React.Fragment>
                              )}

                              {data.pan_number && (
                                <React.Fragment>
                                  <tr>
                                    <td colspan="4">
                                      <font size="+1">
                                        <b style={{ color: "red" }}>
                                          KYC Details:
                                        </b>
                                      </font>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>PAN Number</b>
                                    </td>
                                    <td>{data.pan_number}</td>
                                  </tr>

                                  {data.poa_id_number && (
                                    <tr>
                                      <td>
                                        <b>Aadhaar Number</b>
                                      </td>
                                      <td>
                                        XXXX-XXXX-
                                        {data.poa_id_number.substring(
                                          data.poa_id_number.length - 4
                                        )}
                                      </td>
                                    </tr>
                                  )}

                                  <tr>
                                    <td>
                                      <b>KYC Status</b>
                                    </td>
                                    <td>{data.kyc_status}</td>
                                  </tr>
                                </React.Fragment>
                              )}
                            </table>

                            <div class="text-center pt-4">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() => {
                                  window.print();
                                }}
                              >
                                Print
                              </button>
                              <button
                                type="button"
                                class="btn btn-default ml-5"
                                onClick={() => {
                                  // history.push("/agent_list");
                                  window.close();
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      )}
      {/* </div>
      </div> */}
      {/* </div>
              </div>
            </div>
          </div>
        </section>
      </Templete> */}
    </React.Fragment>
  );
};

export default UserDetails;
