import React from "react";
import { Grid } from "@material-ui/core";

import JsonFormatter from "react-json-formatter";

const LogsTab = (props) => {
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <React.Fragment>
      <Grid container justify="center" spacing={10}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {props.fData && props.fData.log1 && (
            <JsonFormatter
              json={props.fData.log1}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {props.fData && props.fData.log2 && (
            <JsonFormatter
              json={props.fData.log2}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {props.fData && props.fData.log3 && (
            <JsonFormatter
              json={props.fData.log3}
              tabWith="4"
              jsonStyle={jsonStyle}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogsTab;
