import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const StorageSettings = (props) => {
  let data = props.data.storage_settings;

  const loadingCtx = useContext(LoadingContext);

  const [defaultStorage, setDefaultStorage] = useState(data.default_storage);

  const accessKeyIdRef = useRef();
  const secretAccessKeyRef = useRef();
  const regionRef = useRef();
  const bucketRef = useRef();

  const updateHandler = async () => {
    const accessKeyId = accessKeyIdRef.current.value;
    const secretAccessKey = secretAccessKeyRef.current.value;
    const region = regionRef.current.value;
    const bucket = bucketRef.current.value;

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateStorageSettings(
        token,
        defaultStorage,
        accessKeyId,
        secretAccessKey,
        region,
        bucket
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          {/* <div class="form-group row">
            <label class="col-sm-2 col-form-label">Encryption</label>
            <div class="col-sm-10">
              <select className="form-control" ref={encryptionRef}>
                <option value={data ? data.encryption : ""}>
                  {data ? data.encryption : "Select Encryption"}
                </option>
                <option value="None">None</option>
                <option value="TLS">TLS</option>
                <option value="SSL">SSL</option>
              </select>
            </div>
          </div> */}

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Default Storage</label>
            <div class="col-sm-9">
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  checked={defaultStorage === "LOCAL" ? true : false}
                  id="customRadio1"
                  onClick={() => {
                    setDefaultStorage("LOCAL");
                  }}
                />
                <label for="customRadio1" class="custom-control-label">
                  LOCAL
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  checked={defaultStorage === "AWS_S3" ? true : false}
                  id="customRadio2"
                  onClick={() => {
                    setDefaultStorage("AWS_S3");
                  }}
                />
                <label for="customRadio2" class="custom-control-label">
                  AWS_S3
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">AWS Access Key ID</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={accessKeyIdRef}
                defaultValue={data ? data.aws_s3.access_key_id : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">AWS Secret Access Key</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={secretAccessKeyRef}
                defaultValue={data ? data.aws_s3.secret_access_key : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">AWS Region</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={regionRef}
                defaultValue={data ? data.aws_s3.region : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">AWS Bucket</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={bucketRef}
                defaultValue={data ? data.aws_s3.bucket : ""}
              />
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StorageSettings;
