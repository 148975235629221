import common from "../../utill/Common";

class DbModuleService {
  add = async (
    token,
    package_id,
    user_type,
    domain_name,
    pan_number,
    name,
    shop_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address
  ) => {
    const url = common.baseUrl + "user/db_module/add_user";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        package_id,
        user_type,
        domain_name,
        pan_number,
        name,
        shop_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  list = async (token, search_text, page, size) => {
    const url = common.baseUrl + "user/db_module/fetch_users";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updatePackage = async (token, user_id, package_id) => {
    const url = common.baseUrl + "user/db_module/update_package";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        package_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "user/db_module/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  balanceTransfer = async (token, user_id, amount) => {
    const url = common.baseUrl + "user/db_module/balance_transfer";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getNameFromUserId = async (token, user_id) => {
    const url = common.baseUrl + "user/db_module/get_name_from_user_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  adminLogin = async (token) => {
    const url = common.baseUrl + "user/db_module/admin_login";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DbModuleService();
