import React, { useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";
import Templete from "../Templete/Templete";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

import AuthService from "../../../services/User/AuthService";
import CommercialService from "../../../services/User/CommercialService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const SetCommercial = () => {
  const [loading, setLoading] = useState(false);

  const [packages, setPackages] = useState();
  const [packageId, setPackageId] = useState();

  const [products, setProducts] = useState();
  const [productId, setProductId] = useState();

  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    fetchPackages();
    fetchProducts();
  }, []);

  const fetchPackages = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await CommercialService.fetchPackages(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setPackages(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchProducts = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await WalletService.productList(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const columns = [
    {
      title: "Retailer Commission",
      field: "rt_amount",
      type: "numeric",
      initialEditValue: "0",
    },
    {
      title: "Distributor Commission",
      field: "dt_amount",
      type: "numeric",
      initialEditValue: "0",
    },
    {
      title: "Master Commission",
      field: "md_amount",
      type: "numeric",
      initialEditValue: "0",
    },
  ];

  const [data, setData] = useState();

  // const getData = () => {
  const getData = React.useCallback(async () => {
    const token = AuthService.getAccessToken();

    loadingCtx.startLoading();

    CommercialService.fetch(
      token,
      packageId ? packageId : "",
      productId ? productId : ""
    )
      .then((data) => {
        if (data.status === "SUCCESS") {
          const dataResult = data.data;
          setData(dataResult);
        } else {
          throw new Error(data.message);
        }

        loadingCtx.stopLoading();
      })
      .catch((err) => {
        loadingCtx.stopLoading();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId, productId]);
  // };

  useEffect(() => {
    getData();
  }, [getData]);

  const defaultMaterialTheme = createTheme();

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Set Commercial">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Set Commercial</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <select
                                  class="form-control form-control-border"
                                  onChange={(e) => {
                                    setPackageId(e.target.value);
                                  }}
                                >
                                  <option>Select Package</option>
                                  {packages &&
                                    packages.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-6">
                              <div class="form-group">
                                <select
                                  class="form-control form-control-border"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option>Select Product</option>
                                  {products &&
                                    products.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <MaterialTable
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                        }}
                        columns={columns}
                        data={data}
                        options={{
                          search: false,
                          paging: false,
                          showTitle: false,

                          headerStyle: {
                            backgroundColor: "#DEF3FA",
                            color: "Black",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          },
                          cellStyle: {
                            textAlign: "center",
                          },
                        }}
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise(async (resolve, reject) => {
                              // setData([...data, newData]);
                              const token = await AuthService.getAccessToken();
                              newData["package_id"] = packageId
                                ? packageId
                                : "";
                              newData["product_id"] = productId
                                ? productId
                                : "";
                              CommercialService.add(token, newData)
                                .then((data) => {
                                  getData();
                                })
                                .catch((error) => {
                                  getData();
                                });
                              resolve();
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise(async (resolve, reject) => {
                              const dataUpdate = [...data];
                              const index = oldData.tableData.id;
                              dataUpdate[index] = newData;
                              setData([...dataUpdate]);
                              const token = await AuthService.getAccessToken();
                              CommercialService.update(token, newData)
                                .then((data) => {
                                  getData();
                                })
                                .catch((error) => {
                                  getData();
                                });
                              resolve();
                            }),
                          onRowDelete: (oldData) =>
                            new Promise(async (resolve, reject) => {
                              const dataDelete = [...data];
                              const index = oldData.tableData.id;
                              dataDelete.splice(index, 1);
                              setData([...dataDelete]);
                              const token = await AuthService.getAccessToken();
                              CommercialService.delete(token, oldData.id)
                                .then((data) => {
                                  getData();
                                })
                                .catch((error) => {
                                  getData();
                                });
                              resolve();
                              // STOP LOADING
                              loadingCtx.stopLoading();
                            }),
                        }}
                      />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default SetCommercial;
