import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import InsuranceService from "../../../../services/User/InsuranceService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const InsuranceDekho = () => {
  const loadingCtx = useContext(LoadingContext);

  const [isOutlet, setIsOutlet] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const isOutlet = localStorage.getItem("is_outlet");

    //alert(isOutlet);

    if (isOutlet == true || isOutlet == "true") {
      setIsOutlet(true);
    }
  };

  const generateLoginUrl = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await InsuranceService.generateLoginUrl(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const url = data.data.url;
        window.open(url, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Insurance Dekho">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Insurance Dekho</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          <div class="card-body">
                            <div className="text-center plan-btn mt-2 mb-2">
                              {isOutlet && (
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={generateLoginUrl}
                                  >
                                    Login to Insurance Dekho Portal
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default InsuranceDekho;
