import React from "react";

const ThankYou = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        style={{
          //  display: "block",
          marginLeft: "auto",
          marginrRight: "auto",
          width: "50%",
        }}
        src="/images/thank-you-icon.png"
      />
      <h5 style={{ color: "green", fontWeight: "bold" }}>
        Thank you for choosing us. Your money will be credited to your wallet
        within a few minutes if the payment is successful.
      </h5>
    </div>
  );
};

export default ThankYou;
