import React, { useEffect, useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import UserService from "../../../services/Admin/UserService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const WalletTopup = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [txnType, setTxnType] = useState();
  const [walletType, setWalletType] = useState();
  const [data, setData] = useState();

  const [userId, setUserId] = useState();
  const [id, setId] = useState();

  const formRef = useRef();
  const userIdRef = useRef();
  const amountRef = useRef();
  const ReferenceIdRef = useRef();
  const remarkRef = useRef();

  const submitHandler = async () => {
    const amount = amountRef.current.value;
    const referenceId = ReferenceIdRef.current.value;
    const remark = remarkRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!txnType) {
      Swal.fire("Opps!", "Invalid TXN Type", "error");
      return;
    }

    if (!walletType) {
      Swal.fire("Opps!", "Invalid Wallet Type", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!referenceId) {
      Swal.fire("Opps!", "Invalid Reference ID", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.addTransaction(
        token,
        id,
        txnType,
        walletType,
        amount,
        referenceId,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        await fetchHandler(userId);
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchHandler = async (val) => {
    let userId;

    if (val) {
      userId = val;
    } else if (props.match.params.id) {
      userId = props.match.params.id;

      if (userId == "null") {
        userId = userIdRef.current.value;
      }
    } else {
      userId = userIdRef.current.value;
    }

    if (!userId) {
      return;
    }

    if (userId == "null") {
      return;
    }

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data);
        setUserId(userId);
        setId(responseData.data.result.id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchHandler(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [amountInWord, setAmountInWord] = useState();

  const getWord = () => {
    const amount = amountRef.current.value;
    if (amount) {
      let words = toWords.convert(amount);
      setAmountInWord(words);
    } else {
      setAmountInWord();
    }
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Wallet Topup">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Wallet Topup</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body mt-2">
                          {!open && (
                            <div class="form-group row">
                              <div class="input-group input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter User ID/Mobile Number"
                                  ref={userIdRef}
                                />
                                <span class="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-info btn-flat"
                                    onClick={() => {
                                      fetchHandler(false);
                                    }}
                                  >
                                    Go
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}

                          {open && data && (
                            <div>
                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      data.result.name +
                                      " (" +
                                      data.result.user_id +
                                      ")"
                                    }
                                    readOnly
                                  />

                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      // color: "green",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    Main Wallet:
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      ₹{data.wallet_result.main_wallet}
                                    </span>
                                  </p>

                                  <p
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      // color: "green",
                                    }}
                                  >
                                    Settlement Wallet:
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      ₹{data.wallet_result.settlement_wallet}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  TXN Type
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setTxnType(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select TXN Type---
                                    </option>
                                    <option value="CR">CREDIT</option>
                                    <option value="DR">DEBIT</option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Wallet Type
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setWalletType(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select Wallet Type---
                                    </option>
                                    <option value="MAIN_WALLET">
                                      MAIN_WALLET
                                    </option>
                                    <option value="SETTLEMENT_WALLET">
                                      SETTLEMENT_WALLET
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Amount
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Amount"
                                    maxLength={10}
                                    ref={amountRef}
                                    style={{ fontWeight: "bold" }}
                                    onChange={getWord}
                                  />
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "red",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {amountInWord}
                                  </p>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Reference ID
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Reference ID"
                                    ref={ReferenceIdRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Remark
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={remarkRef}
                                    className="form-control"
                                    placeholder="Remark"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default WalletTopup;
