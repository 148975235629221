import React from "react";

import Templete from "../Templete/Templete";
import EditUserForm from "./EditUserForm";

const EditUser = (props) => {
  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit User">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Edit User</h3>
                  </div>

                  <EditUserForm
                    userId={
                      props.userId ? props.userId : props.match.params.userId
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditUser;
