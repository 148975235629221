import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

import ShowImgDialog from "./ShowImgDialog";

const KycDocumentsTab = (props) => {
  const [openImgDialog, setOpenImgDialog] = useState(false);
  const [imgSrc, SetImgSrc] = useState();

  const ImgDialogHandler = (value) => {
    SetImgSrc("https://formax-users-kyc.s3.us-east-2.amazonaws.com/" + value);
    setOpenImgDialog(true);
  };

  const downloadVideoHandler = (value) => {
    if (!value) {
      return;
    }
    SetImgSrc("https://formax-users-kyc.s3.us-east-2.amazonaws.com/" + value);

    window.open(imgSrc, "_blank");
  };

  return (
    <div>
      <Grid container justify="center" spacing={10}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.pan_number}
              </ListItemSecondaryAction>
              <ListItemText primary="PAN Number" />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ImgDialogHandler(props.userData && props.userData.pan_img);
                  }}
                >
                  Show Image
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="PAN Image" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.poa_id_number}
              </ListItemSecondaryAction>
              <ListItemText primary="Aadhaar Number" />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ImgDialogHandler(
                      props.userData && props.userData.poa_frontside_img
                    );
                  }}
                >
                  Show Image
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Aadhaar Image" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} sm={3} md={6} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.pob_type}
              </ListItemSecondaryAction>
              <ListItemText primary="Proof of Bank Type" />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ImgDialogHandler(props.userData && props.userData.pob_img);
                  }}
                >
                  Show Image
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Proof Of Bank Image" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ImgDialogHandler(
                      props.userData && props.userData.selfie_with_shop_img
                    );
                  }}
                >
                  Show Image
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Selfie With Shop Image" />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ImgDialogHandler(
                      props.userData && props.userData.profile_img
                    );
                  }}
                >
                  Show Image
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Profile Image" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={6}>
          <List>
            <Divider />

            <ListItem>
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    downloadVideoHandler(
                      props.userData && props.userData.video_declaration
                    );
                  }}
                >
                  Play Video
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Video Declaration" />
            </ListItem>
            <Divider />
          </List>
        </Grid>
      </Grid>
      <ShowImgDialog
        openImgDialog={openImgDialog}
        setOpenImgDialog={setOpenImgDialog}
        imgSrc={imgSrc}
      />
    </div>
  );
};

export default KycDocumentsTab;
