import React, { useState, useEffect } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/MenuOpen";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import InvestmentService from "../../../services/User/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import ActionDialog from "./ActionDialog";

const NewWithdrawalRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    InvestmentService.activeInvestments(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data;
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-primary">₹{row.amount}</span>{" "}
        </div>
      ),
    },
    {
      name: "Withdrawn Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-danger">
            ₹{row.amount_withdrawn}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Lien Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-warning">
            ₹{row.lien_amount ? row.lien_amount : "0"}
          </span>{" "}
        </div>
      ),
    },
    {
      name: "Available Balance",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-success">
            ₹{row.amount - row.amount_withdrawn}
          </span>{" "}
        </div>
      ),
    },

    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.is_closed !== "YES") {
              return <span>Active</span>;
            } else if (row.status === "REJECTED") {
              return <span>Closed</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_date}</span>
        </div>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <SettingsIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="New Withdrawal Request">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">New Withdrawal Request</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <DataTableComponent
                          noHeader={true}
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          highlightOnHover
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ActionDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          selectedData={selectedData}
          fetch={fetch}
        />
      </Templete>
    </React.Fragment>
  );
};

export default NewWithdrawalRequest;
