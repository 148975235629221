import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import DbModuleService from "../../../services/User/DbModuleService";
import CommercialService from "../../../services/User/CommercialService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";
import NsdlPANCardService from "../../../services/User/NsdlPANCardService";

const AddUser = () => {
  const loadingCtx = useContext(LoadingContext);

  const [userType, setUserType] = useState();
  const [packages, setPackages] = useState();
  const [fees, setFees] = useState();

  const [selectedUserType, setSelectedUserType] = useState();

  const formRef = useRef();
  const packageIdRef = useRef();
  const userTypeRef = useRef();
  const domainNameRef = useRef();
  const panNumberRef = useRef();
  const nameRef = useRef();
  const shopNameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const pincodeRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const districtRef = useRef();
  const addressRef = useRef();

  const addUserHandler = async () => {
    let packageId;
    let domainName;

    const userType1 = selectedUserType;

    const panNumber = panNumberRef.current.value;
    const name = nameRef.current.value;
    const shopName = shopNameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;
    const pincode = pincodeRef.current.value;
    const state = pincodeRef.current.value;
    const city = cityRef.current.value;
    const district = districtRef.current.value;
    const address = addressRef.current.value;

    if (userType === "Reseller") {
      packageId = packageIdRef.current.value;

      if (!packageId) {
        Swal.fire("Opps!", "Invalid Commercial Package", "error");
        return;
      }
    }

    if (!userType1) {
      Swal.fire("Opps!", "Invalid User Type", "error");
      return;
    }

    if (userType1 == "Reseller") {
      domainName = domainNameRef.current.value;

      if (!domainName) {
        Swal.fire("Opps!", "Invalid User Type", "error");
        return;
      }
    }

    if (!panNumber) {
      Swal.fire("Opps!", "Invalid PAN Number", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!shopName) {
      Swal.fire("Opps!", "Invalid Shop Name", "error");
      return;
    }

    if (!mobile) {
      Swal.fire("Opps!", "Invalid Mobile Number", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Invalid Email Address", "error");
      return;
    }

    if (!pincode) {
      Swal.fire("Opps!", "Invalid PIN Code", "error");
      return;
    }

    if (!state) {
      Swal.fire("Opps!", "Invalid State", "error");
      return;
    }

    if (!city) {
      Swal.fire("Opps!", "Invalid City", "error");
      return;
    }

    if (!district) {
      Swal.fire("Opps!", "Invalid City", "error");
      return;
    }

    if (!address) {
      Swal.fire("Opps!", "Invalid City", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DbModuleService.add(
        token,
        packageId,
        userType1,
        domainName,
        panNumber,
        name,
        shopName,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  useEffect(() => {
    getData();
    fetchCommercial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const userType = AuthService.getUserType();
    setUserType(userType);

    if (userType !== "Reseller") {
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = await AuthService.getAccessToken();

      const data = await CommercialService.fetchPackages(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setPackages(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchCommercial = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await NsdlPANCardService.getSubscriptionFees(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        if (data.data) {
          setFees(data.data.fees);
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const mrsUserTypeList = [
    "Reseller",
    "Master Distributor",
    "Distributor",
    "Retailer",
  ];
  const rsUserTypeList = ["Master Distributor", "Distributor", "Retailer"];
  const mdUserTypeList = ["Distributor", "Retailer"];
  const dtUserTypeList = ["Retailer"];

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add User">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add User</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          {userType === "Reseller" && (
                            <div class="form-group row mt-3">
                              <label className="col-sm-3 col-form-label">
                                Commercial Package
                              </label>
                              <div class="col-sm-9">
                                <select class="form-control" ref={packageIdRef}>
                                  <option value="">
                                    Select Commercial Package
                                  </option>
                                  {packages &&
                                    packages.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          )}

                          <div class="form-group row">
                            <label className="col-sm-3 col-form-label">
                              User Type
                            </label>
                            <div class="col-sm-9">
                              <select
                                class="form-control"
                                onChange={(e) => {
                                  setSelectedUserType(e.target.value);
                                }}
                              >
                                <option value="">Select User Type</option>

                                {userType === "Master Reseller" &&
                                  mrsUserTypeList.map((e, key) => {
                                    return (
                                      <option key={key} value={e}>
                                        {e}
                                      </option>
                                    );
                                  })}

                                {userType === "Reseller" &&
                                  rsUserTypeList.map((e, key) => {
                                    return (
                                      <option key={key} value={e}>
                                        {e}
                                      </option>
                                    );
                                  })}

                                {userType === "Master Distributor" &&
                                  mdUserTypeList.map((e, key) => {
                                    return (
                                      <option key={key} value={e}>
                                        {e}
                                      </option>
                                    );
                                  })}

                                {userType === "Distributor" &&
                                  dtUserTypeList.map((e, key) => {
                                    return (
                                      <option key={key} value={e}>
                                        {e}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>

                          {selectedUserType == "Reseller" && (
                            <div class="form-group row">
                              <label className="col-sm-3 col-form-label">
                                Domain Name
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class="form-control"
                                  ref={domainNameRef}
                                  placeholder="Domain Name"
                                />
                              </div>
                            </div>
                          )}

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              PAN Number
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="PAN Number"
                                maxLength={10}
                                ref={panNumberRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="Name"
                                ref={nameRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Shop Name
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                ref={shopNameRef}
                                placeholder="Shop Name"
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Mobile Number
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="Mobile Number"
                                maxLength={10}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                ref={mobileRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Email ID
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="Email ID"
                                ref={emailRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              PIN Code
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="PIN Code"
                                maxLength={6}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                ref={pincodeRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <select class="form-control" ref={stateRef}>
                                <option>Select State</option>
                                {states &&
                                  states.map((e, key) => {
                                    return (
                                      <option key={key} value={e.value}>
                                        {e.label}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="City"
                                ref={cityRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              District
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                name="last_name"
                                class="form-control"
                                placeholder="District"
                                ref={districtRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Address
                            </label>
                            <div class="col-sm-9">
                              <textarea
                                ref={addressRef}
                                className="form-control"
                                placeholder="Address"
                                rows="3"
                              ></textarea>
                            </div>

                            <p style={{ color: "red" }} className="mt-3 mb-3">
                              *Important Note: Please note that Rs. {fees} will
                              be deducted from your Wallet for adding new user.
                            </p>
                          </div>

                          <div class="card-footer text-center mb-3">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addUserHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddUser;
