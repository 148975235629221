import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";

// import TreeView from "@mui/lab/TreeView";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import TreeItem from "@mui/lab/TreeItem";

import Tree from "react-animated-tree";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const UserListTreeView = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.userListTreeView(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  // const renderTree = (nodes) => (
  //   <TreeItem nodeId={nodes.id} label={nodes.name + " (" + nodes.user_id + ")"}>
  //     {Array.isArray(nodes.children)
  //       ? nodes.children.map((node) => renderTree(node))
  //       : null}
  //   </TreeItem>
  // );

  const renderTree = (nodes) => (
    <Tree
      id={nodes.id}
      content={
        nodes.name +
        " (" +
        nodes.user_id +
        " [" +
        nodes.package_name +
        "-" +
        nodes.amount +
        "%])"
      }
    >
      {nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </Tree>
  );

  return (
    <React.Fragment>
      <Templete t1="Home" t2="User List (Tree View)">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User List (Tree View)</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        {data && (
                          // <TreeView
                          //   aria-label="rich object"
                          //   defaultCollapseIcon={<ExpandMoreIcon />}
                          //   defaultExpanded={["root"]}
                          //   defaultExpandIcon={<ChevronRightIcon />}
                          //   sx={{
                          //     height: 110,
                          //     flexGrow: 1,
                          //     maxWidth: 400,
                          //     overflowY: "auto",
                          //   }}
                          // >
                          //   {renderTree(data)}
                          // </TreeView>

                          <div>{renderTree(data)} </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UserListTreeView;
