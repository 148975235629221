import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/Edit";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import ExpenseService from "../../../services/Admin/ExpenseService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ExpenseHistory = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [searchText, setSearchText] = useState("");

  const [categoryId, setCategoryId] = useState();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [userType, setUserType] = useState();

  const [totalAmount, setTotalAmount] = useState();

  const size = 10;

  useEffect(() => {
    fetchData();
    getUserType();
    expenseCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate, categoryId]);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    setUserType(_userType);
  };

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    ExpenseService.history(
      token,
      fromDate,
      toDate,
      searchText,
      categoryId,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.data.result;
        const totalAmount = responseData.data.total_amount;
        setTotalAmount(totalAmount);
        setCount(responseData.data.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      name: "Note",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.note}</span>
        </div>
      ),
    },
    {
      name: "Category",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_expense_category.name}</span>
        </div>
      ),
    },
    {
      name: "Expense Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.expense_date}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.amount}</span>
        </div>
      ),
    },
    {
      name: "Timestamp",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/edit_expense/" + row.id);
            }}
          >
            <AccountCircleIcon />
          </IconButton>

          {userType === "Admin" && (
            <IconButton
              color="error"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure to delete?",
                  showCancelButton: true,
                  confirmButtonText: `Delete`,
                  confirmButtonColor: "red",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteHandler(row.id);
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const deleteHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.delete(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [categoryList, setCategoryList] = React.useState();

  const expenseCategoryList = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.expenseCategoryList(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setCategoryList(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Expense History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Expense History</h3>

                    <div class="card-tools">
                      <a
                        href="/admin/add_expense"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Add Expense
                      </a>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="From Date"
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                    defaultValue={fromDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="To Date"
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                    defaultValue={toDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <Autocomplete
                                    options={categoryList}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Category"
                                      />
                                    )}
                                    onChange={(e, v) => {
                                      setCategoryId(v ? v.id : null);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />

                    <br />
                    {data && (
                      <div>
                        <p>
                          Total Amount:{" "}
                          <b style={{ color: "green" }}>₹{totalAmount}</b>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ExpenseHistory;
