import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ViewDetailsDialog = (props) => {
  const data = props.selectedData;

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && data && (
              <ul class="list-group list-group-unbordered mb-3">
                <div>
                  <li class="list-group-item">
                    <b>Withdrawal Amount</b>{" "}
                    <span
                      class="float-right"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      ₹{data ? data.amount : ""}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Investment Order ID</b>{" "}
                    <span class="float-right">
                      {data ? data.investment_id : ""}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Status</b>{" "}
                    <span class="float-right">{data ? data.status : ""}</span>
                  </li>

                  <li class="list-group-item">
                    <b>Order ID</b>{" "}
                    <span class="float-right">{data ? data.order_id : ""}</span>
                  </li>

                  {data && data.status === "APPROVED" && (
                    <div>
                      <li class="list-group-item">
                        <b>Payment Ref ID</b>{" "}
                        <span class="float-right">
                          {data ? data.payment_ref_id : ""}
                        </span>
                      </li>

                      <li class="list-group-item">
                        <b>Payment Date</b>{" "}
                        <span class="float-right">
                          {data ? data.payment_date : ""}
                        </span>
                      </li>
                    </div>
                  )}

                  <li class="list-group-item">
                    <b>Remark</b>{" "}
                    <span class="float-right">
                      {data ? data.remark : "NULL"}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Requested Date</b>{" "}
                    <span class="float-right">
                      {data ? data.created_on : ""}
                    </span>
                  </li>
                </div>
              </ul>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ViewDetailsDialog;
