import common from "../../utill/Common";

class WithdrawalService {
  newWithdrawalRequest = async (token, order_id, amount) => {
    const url = common.baseUrl + "user/withdrawal/new_withdrawal_request";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id, amount }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  withdrawalHistory = async (
    token,
    from_date,
    to_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/withdrawal/withdrawal_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new WithdrawalService();
