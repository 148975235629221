import common from "../../utill/Common";

class IncomeReportService {
  incomeSummary = async (token, year, month) => {
    const url = common.baseUrl + "user/income_report/income_summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        year,
        month,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  commissionHistory = async (
    token,
    from_date,
    to_date,
    txn_date,
    txn_type,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/income_report/commission_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        txn_date,
        from_date,
        to_date,
        txn_type,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new IncomeReportService();
