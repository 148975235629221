import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";

//import Zoom from "react-medium-image-zoom";
//import "react-medium-image-zoom/dist/styles.css";

const ShopDetailsTab = (props) => {
  return (
    <React.Fragment>
      <div class="row justify-content-center">
        {props.base64Data[1]["value"] && (
          <img
            alt="selfie img"
            src={props.base64Data[1]["value"]}
            width="100"
          />
        )}

        {!props.base64Data[1]["value"] && (
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              props.getImageHandler(1);
            }}
            disabled={props.isLoading}
          >
            {props.isLoading && <CircularProgress size={14} />}
            {!props.isLoading && "View Selfie With Shop Image"}
          </button>
        )}
      </div>

      <div class="row justify-content-center">
        <div class="col-sm-6">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_name}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop Name" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_pincode}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop PIN Code" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_state}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop State" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_city}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop City" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_district}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop District" />
            </ListItem>

            {/* <Divider /> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_address}
              </ListItemSecondaryAction>
              <ListItemText primary="Shop Address" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_latitude}
              </ListItemSecondaryAction>
              <ListItemText primary="Latitude" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.shop_longitude}
              </ListItemSecondaryAction>
              <ListItemText primary="Longitude" />
            </ListItem>
          </List>
        </div>
      </div>

      <div class="d-flex justify-content-center pt-3">
        <div></div>
      </div>
    </React.Fragment>
  );
};

export default ShopDetailsTab;
