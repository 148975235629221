import common from "../../utill/Common";

class InvestmentService {
  withdrawalHistory = async (
    token,
    from_date,
    to_date,
    user_id,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/withdrawal/withdrawal_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        from_date,
        to_date,
        user_id,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  pendingWithdrawalRequest = async (
    token,
    from_date,
    to_date,
    user_id,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/withdrawal/pending_withdrawal_request";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        from_date,
        to_date,
        user_id,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateWithdrawalRequest = async (
    token,
    id,
    status,
    file,
    payment_date,
    payment_ref_id,
    remark
  ) => {
    const url = common.baseUrl + "admin/withdrawal/update_withdrawal_request";

    const dataArray = new FormData();
    dataArray.append("id", id);
    dataArray.append("status", status);
    dataArray.append("payment_receipt", file);
    dataArray.append("payment_date", payment_date);
    dataArray.append("payment_ref_id", payment_ref_id);
    dataArray.append("remark", remark);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  downloadReceipt = async (token, order_id) => {
    const url = common.baseUrl + "admin/withdrawal/download_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  viewPaymentReceipt = async (token, order_id) => {
    const url = common.baseUrl + "admin/withdrawal/view_payment_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvestmentService();
