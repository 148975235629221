import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Button,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Autocomplete from "@mui/material/Autocomplete";

import Swal from "sweetalert2";

import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import UserService from "../../../../services/Admin/UserService";
import AuthService from "../../../../services/Admin/AuthService";

const BasicDetailsTab = (props) => {
  const state = [
    "Andaman And Nicobar",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra And Nagar Haveli",
    "Daman And Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu And Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const formRef = React.useRef();
  const nameRef = React.useRef();
  const panNumberRef = React.useRef();
  const pincodeRef = React.useRef();
  const stateRef = React.useRef();
  const cityRef = React.useRef();
  const districtRef = React.useRef();
  const addressRef = React.useRef();

  const loadingCtx = React.useContext(LoadingContext);

  const updateUserHandler = async (e) => {
    e.preventDefault();

    const name = nameRef.current.value;
    const panNumber = panNumberRef.current.value;
    const pincode = pincodeRef.current.value;
    const state = stateRef.current.value;
    const district = districtRef.current.value;
    const city = cityRef.current.value;
    const address = addressRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Please Enter Name", "error");
      return;
    }

    if (!panNumber) {
      Swal.fire("Opps!", "Please Enter PAN Number", "error");
      return;
    }

    if (!pincode) {
      Swal.fire("Opps!", "Please Enter PIN Code", "error");
      return;
    }

    if (!state) {
      Swal.fire("Opps!", "Please Select State", "error");
      return;
    }

    if (!district) {
      Swal.fire("Opps!", "Please Enter District", "error");
      return;
    }

    if (!city) {
      Swal.fire("Opps!", "Please Enter City", "error");
      return;
    }

    if (!address) {
      Swal.fire("Opps!", "Please Enter Address", "error");
      return;
    }

    // START LOADING
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await UserService.updateUser(
        token,
        props.userData ? props.userData.id : "",
        name,
        panNumber,
        "true",
        pincode,
        state,
        district,
        city,
        address
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    // STOP LOADING
    loadingCtx.stopLoading();
  };

  const fetchPincodeHandler = async (e) => {
    e.preventDefault();

    const pincode = pincodeRef.current.value;

    if (!pincode || pincode.length < 6 || pincode.length > 6) {
      //  swal("Opps!", "Please Enter PIN Code", "error");
      return;
    }

    // START LOADING
    loadingCtx.startLoading();

    try {
      const data = await UserService.fetchPincode(pincode);

      const status = data[0]["Status"];

      const postOffice = data[0]["PostOffice"];

      if (status == "Error" || postOffice == null) {
        Swal.fire("Opps!", status, "error");
        return;
      }

      const district = postOffice[0]["District"];
      const state = postOffice[0]["State"];
      const city = postOffice[0]["Region"];

      cityRef.current.value = city;
      districtRef.current.value = district;
      stateRef.current.value = state;
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    // STOP LOADING
    loadingCtx.stopLoading();
  };
  return (
    <Box>
      <form onSubmit={updateUserHandler} inputRef={formRef}>
        <div class="row justify-content-center">
          <div class="col-sm">
            <List>
              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.user_type}
                </ListItemSecondaryAction>
                <ListItemText primary="User Type" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.user_id}
                </ListItemSecondaryAction>
                <ListItemText primary="User ID" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.name}
                </ListItemSecondaryAction>
                <ListItemText primary="Name" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.email}
                </ListItemSecondaryAction>
                <ListItemText primary="Email Address" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.mobile}
                </ListItemSecondaryAction>
                <ListItemText primary="Mobile Number" />
              </ListItem>
            </List>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <Grid
                xs={12}
                justify="center"
                container
                direction={"column"}
                spacing={3}
              >
                <Grid item>
                  <TextField
                    fullWidth
                    label="Name"
                    inputRef={nameRef}
                    defaultValue={props.userData && props.userData.name}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    fullWidth
                    label="PAN Number"
                    inputRef={panNumberRef}
                    defaultValue={props.userData && props.userData.pan_number}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <List>
              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.pincode}
                </ListItemSecondaryAction>
                <ListItemText primary="PIN Code" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.state}
                </ListItemSecondaryAction>
                <ListItemText primary="State" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.city}
                </ListItemSecondaryAction>
                <ListItemText primary="City" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.district}
                </ListItemSecondaryAction>
                <ListItemText primary="District" />
              </ListItem>

              {/* <Divider /> */}

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.address}
                </ListItemSecondaryAction>
                <ListItemText primary="Address" />
              </ListItem>
            </List>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <Grid
                xs={12}
                justify="center"
                container
                direction={"column"}
                spacing={3}
              >
                <Grid item>
                  <TextField
                    fullWidth
                    label="PIN Code"
                    inputRef={pincodeRef}
                    onChange={fetchPincodeHandler}
                    defaultValue={props.userData && props.userData.pincode}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="State"
                    inputRef={stateRef}
                    defaultValue={props.userData && props.userData.state}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="City"
                    inputRef={cityRef}
                    defaultValue={props.userData && props.userData.city}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="District"
                    inputRef={districtRef}
                    defaultValue={props.userData && props.userData.district}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="Address"
                    multiline
                    rows={4}
                    inputRef={addressRef}
                    defaultValue={props.userData && props.userData.address}
                  />
                </Grid>
                <Grid item justify="center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </div>
      </form>
    </Box>
  );
};

export default BasicDetailsTab;
