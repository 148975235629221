import common from "../../utill/Common";

class ExpenseService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/expense/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (token, name, note, category_id, expense_date, amount) => {
    const url = common.baseUrl + "admin/expense/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        name,
        note,
        category_id,
        expense_date,
        amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (token, id, name, note, category_id, expense_date, amount) => {
    const url = common.baseUrl + "admin/expense/edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        name,
        note,
        category_id,
        expense_date,
        amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/expense/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  addExpenseCategory = async (token, name) => {
    const url = common.baseUrl + "admin/expense/add_expense_category";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        name,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  expenseCategoryList = async (token) => {
    const url = common.baseUrl + "admin/expense/expense_category_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  history = async (
    token,
    from_date,
    to_date,
    search_text,
    category_id,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/expense/history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        search_text,
        category_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ExpenseService();
