import React from "react";

import Templete from "../Templete/Templete";
import ResetLoginDetailsForm from "./ResetLoginDetailsForm";

const ResetLoginDetails = (props) => {
  return (
    <React.Fragment>
      <Templete t1="Home" t2="Reset Login Details">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Reset Login Details</h3>
                  </div>

                  <ResetLoginDetailsForm
                    userId={
                      props.userId ? props.userId : props.match.params.userId
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ResetLoginDetails;
