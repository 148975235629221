import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import EmployeeService from "../../../services/Admin/EmployeeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { useHistory } from "react-router-dom";

const AddEmployee = () => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);

  const [employeeType, setEmployeeType] = useState();
  const [status, setStatus] = useState();

  const formRef = useRef();
  const empIdRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();

  const addEmployeeHandler = async () => {
    const empId = empIdRef.current.value;
    const password = passwordRef.current.value;
    const name = nameRef.current.value;
    const mobile = mobileRef.current.value;
    const email = emailRef.current.value;

    if (!empId) {
      Swal.fire("Opps!", "Invalid EMP ID", "error");
      return;
    }

    if (!password) {
      Swal.fire("Opps!", "Invalid Password", "error");
      return;
    }

    if (!employeeType) {
      Swal.fire("Opps!", "Invalid Employee Type", "error");
      return;
    }

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!mobile) {
      Swal.fire("Opps!", "Invalid Mobile", "error");
      return;
    }

    if (!email) {
      Swal.fire("Opps!", "Invalid Email", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await EmployeeService.add(
        token,
        empId,
        password,
        employeeType,
        name,
        mobile,
        email,
        status
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Employee">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Employee</h3>

                    <div class="card-tools">
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/admin/employee_list");
                        }}
                      >
                        Employee List
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              EMP ID
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Employee ID"
                                ref={empIdRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Password
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Password"
                                ref={passwordRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Employee Type
                            </label>
                            <div class="col-sm-9">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setEmployeeType(e.target.value);
                                }}
                              >
                                <option value="">---Select Type---</option>
                                <option value="Admin">Admin</option>
                                <option value="Staff">Staff</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                ref={nameRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Email ID
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Email"
                                ref={emailRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Mobile Number
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Mobile"
                                ref={mobileRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Status
                            </label>
                            <div class="col-sm-9">
                              <select
                                name="title"
                                class="form-control"
                                onChange={(e) => {
                                  setStatus(e.target.value);
                                }}
                              >
                                <option value="">---Select Status---</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addEmployeeHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddEmployee;
