import common from "../../utill/Common";

class InvestmentService {
  newInvestmentRequest = async (
    token,
    id,
    amount,
    from_bank,
    to_bank,
    payment_mode,
    payment_date,
    payment_ref_id,
    file,
    remark
  ) => {
    const url = common.baseUrl + "user/investment/new_investment_request";

    const dataArray = new FormData();
    dataArray.append("id", id);
    dataArray.append("amount", amount);
    dataArray.append("from_bank", from_bank);
    dataArray.append("to_bank", to_bank);
    dataArray.append("payment_mode", payment_mode);
    dataArray.append("payment_date", payment_date);
    dataArray.append("payment_ref_id", payment_ref_id);
    dataArray.append("payment_receipt", file);
    dataArray.append("remark", remark);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  invtRequestHistory = async (token) => {
    const url = common.baseUrl + "user/investment/invt_request_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  invtHistory = async (
    token,
    from_date,
    to_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/investment/investment_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  teamInvestmentHistory = async (token, user_id, from_date, to_date) => {
    const url = common.baseUrl + "user/investment/team_investment_history";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        user_id,
        from_date,
        to_date,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  viewPaymentReceipt = async (token, order_id) => {
    const url = common.baseUrl + "user/investment/view_payment_receipt";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateInvestmentRequest = async (
    token,
    order_id,
    amount,
    fromBank,
    toBank,
    payment_mode,
    payment_date,
    payment_ref_id,
    file,
    remark
  ) => {
    const url = common.baseUrl + "user/investment/update_investment_request";

    const dataArray = new FormData();
    dataArray.append("order_id", order_id);
    dataArray.append("amount", amount);
    dataArray.append("from_bank", fromBank);
    dataArray.append("to_bank", toBank);
    dataArray.append("payment_mode", payment_mode);
    dataArray.append("payment_date", payment_date);
    dataArray.append("payment_ref_id", payment_ref_id);
    dataArray.append("payment_receipt", file);
    dataArray.append("remark", remark);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  activeInvestments = async (token) => {
    const url = common.baseUrl + "user/investment/active_investments";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InvestmentService();
