import React, { useState, useContext, useRef, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const BrandingSettings = (props) => {
  const data = props.data;

  const loadingCtx = useContext(LoadingContext);

  const uploadLogoHandler = async (type) => {
    let value;

    switch (type) {
      case "logo":
        value = logo;
        break;

      case "white_logo":
        value = whiteLogo;
        break;

      case "favicon":
        value = favicon;
        break;

      default:
        break;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.uploadLogo(token, type, value);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  useEffect(() => {
    //getLogo;
    fetchLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [logoBase64, setLogoBase64] = useState();
  const [whiteLogoBase64, setWhiteLogoBase64] = useState();
  const [faviconBase64, setFaviconBase64] = useState();

  const fetchLogos = async () => {
    const logoBase64 = await getLogo("logo");
    setLogoBase64(logoBase64);

    const whiteLogoBase64 = await getLogo("white_logo");
    setWhiteLogoBase64(whiteLogoBase64);

    const faviconBase64 = await getLogo("favicon");
    setFaviconBase64(faviconBase64);
  };

  const getLogo = async (type) => {
    // loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.getLogo(token, type);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        return responseData.data.base64_image;
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
  };

  const fileTypes = ["JPEG", "PNG", "JPG"];

  const [logo, setLogo] = useState(null);
  const [whiteLogo, setWhiteLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);

  const handleChangeLogo = (file) => {
    setLogo(file);
  };

  const handleChangeWhiteLogo = (file) => {
    setWhiteLogo(file);
  };

  const handleChangeWhiteFavicon = (file) => {
    setFavicon(file);
  };

  const titleTextRef = useRef();
  const footerTextRef = useRef();

  const updateHandler = async () => {
    const titleText = titleTextRef.current.value;
    const footerText = footerTextRef.current.value;

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateBrandingSettings(
        token,
        titleText,
        footerText
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row justify-content-center pb-3">
        <div class="col-md-4 mx-auto">
          <div class="form-group row">
            <div class="col-sm-10">
              <img src={logoBase64} height={50} />
              <h6 className="pt-3">Logo</h6>
              <FileUploader
                //  label="Logo"
                multiple={false}
                handleChange={handleChangeLogo}
                name="file"
                types={fileTypes}
                maxSize="2"
              />

              <p>
                {logo ? `File name: ${logo.name}` : "no files uploaded yet"}
              </p>
            </div>
          </div>

          <div class="">
            <button
              type="button"
              class="btn btn-default"
              onClick={() => {
                uploadLogoHandler("logo");
              }}
            >
              Upload Logo
            </button>
          </div>
        </div>
        <div class="col-md-4 mx-auto">
          <div class="form-group row">
            <div class="col-sm-10">
              <img src={whiteLogoBase64} height={50} />
              <h6 className="pt-3">White Logo</h6>
              <FileUploader
                //  label="Logo"
                multiple={false}
                handleChange={handleChangeWhiteLogo}
                name="file"
                types={fileTypes}
                maxSize="2"
              />

              <p>
                {whiteLogo
                  ? `File name: ${whiteLogo.name}`
                  : "no files uploaded yet"}
              </p>
            </div>
          </div>

          <div class="">
            <button
              type="button"
              class="btn btn-default"
              onClick={() => {
                uploadLogoHandler("white_logo");
              }}
            >
              Upload White Logo
            </button>
          </div>
        </div>
        <div class="col-md-4 mx-auto">
          <div class="form-group row">
            <div class="col-sm-10">
              <img src={faviconBase64} height={50} />
              <h6 className="pt-3">Favicon</h6>

              <FileUploader
                //  label="Logo"
                multiple={false}
                handleChange={handleChangeWhiteFavicon}
                name="file"
                types={fileTypes}
                maxSize="2"
              />

              <p>
                {favicon
                  ? `File name: ${favicon.name}`
                  : "no files uploaded yet"}
              </p>
            </div>
          </div>

          <div class="">
            <button
              type="button"
              class="btn btn-default"
              onClick={() => {
                uploadLogoHandler("favicon");
              }}
            >
              Upload Favicon
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="row pt-3">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Title Text</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={titleTextRef}
                defaultValue={data ? data.title_text : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Footer Text</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                ref={footerTextRef}
                defaultValue={data ? data.footer_text : ""}
              />
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BrandingSettings;
