import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const FlashMessage = (props) => {
  let data = props.data;

  const loadingCtx = useContext(LoadingContext);

  const flashMessageRef = useRef();

  const updateHandler = async () => {
    const flashMessage = flashMessageRef.current.value;

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateFlashMessage(
        token,
        flashMessage
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Flash Message</label>
            <div class="col-sm-9">
              <textarea
                type="text"
                class="form-control"
                ref={flashMessageRef}
                defaultValue={data ? data.flash_message : ""}
              />
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FlashMessage;
