import React, { useState, useContext, useEffect } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const TotalWalletBalance = () => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.getTotalBalance(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Total Wallet Balance">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Total Wallet Balance</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-3">
                      <div class="col-md-4 col-sm-4 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-primary">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">Main Wallet</span>
                            <span class="info-box-number">
                              ₹{data ? data.main_wallet : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-warning">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">Main Wallet Lien</span>
                            <span class="info-box-number">
                              ₹{data ? data.main_wallet_hold : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-success">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">Net Main Wallet</span>
                            <span class="info-box-number">
                              ₹{data ? data.main_wallet_without_hold : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4 col-sm-4 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-primary">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">Settlement Wallet</span>
                            <span class="info-box-number">
                              ₹{data ? data.settlement_wallet : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-warning">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">
                              Settlement Wallet Lien
                            </span>
                            <span class="info-box-number">
                              ₹{data ? data.settlement_wallet_hold : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-6 col-12">
                        <div class="info-box">
                          <span class="info-box-icon bg-success">
                            <i class="fa fa-wallet"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">
                              Net Settlement Wallet
                            </span>
                            <span class="info-box-number">
                              ₹{data ? data.settlement_wallet_without_hold : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default TotalWalletBalance;
