import React from "react";

import Templete from "../Templete/Templete";
import EditInvestmentForm from "./EditInvestmentForm";

const EditInvestment = (props) => {
  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Investment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Edit Investment</h3>
                  </div>

                  <EditInvestmentForm
                    id={props.id ? props.id : props.match.params.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditInvestment;
