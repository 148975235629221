import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const NsdlPANCardReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  //   const [open, setOpen] = useState(false);
  //   const [selectedData, setSelectedData] = useState();

  const size = 10;

  useEffect(() => {
    fetchData();
    getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    NsdlPANCardService.report(token, fromDate, toDate, searchText, page, size)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "App/PAN Type",
      cell: (row) => (
        <div>
          {(() => {
            if (row.optional8 === "Y") {
              return <span>{row.optional1} - Physical PAN</span>;
            } else {
              return <span>{row.optional1} - E-PAN</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Applicant Details",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Name: {row.optional3}</span>
          <br />
          <span>Mobile: {row.optional6}</span>
          <br />
        </div>
      ),
    },

    {
      name: "ACK No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <span>
                  {row.secret_optional2 ? row.secret_optional2 : "NULL"}
                </span>
              );
            } else {
              return <span>N/A</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">REFUND</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Error Msg",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "REFUND") {
              return (
                <span>
                  {row.secret_optional1 ? row.secret_optional1 : "NULL"}
                </span>
              );
            } else {
              return <span>N/A</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "###",
      cell: (row) => (
        <div>
          {(() => {
            if (
              row.status === "PENDING" &&
              row.product.sub_master_product.name === "eKYC NSDL"
            ) {
              return (
                <h6>
                  <button
                    class="badge badge-primary"
                    onClick={() => {
                      handleIncomplete(row.secret_optional5);
                    }}
                  >
                    Complete PAN Application
                  </button>
                </h6>
              );
            }
          })()}
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const [summaryData, setSummaryData] = useState();

  const getSummary = async () => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.summary(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.xlsReport(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleIncomplete = (authorization) => {
    const url = `https://sso-nsdl-ekyc-app.pages.dev/sso_nsdl_ekyc_redirect?is_incomplete=true&authorization=${authorization}`;

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");

    if (newWindow) newWindow.opener = null;
  };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="NSDL PAN Card Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">NSDL PAN Card Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.success_count
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL PENDING</span>
                            <span class="info-box-number">
                              {" "}
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.pending_count
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL FAILED</span>
                            <span class="info-box-number">
                              {" "}
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.failed_count
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-icon waves-effect waves-light"
                            onClick={xlsReport}
                          >
                            <i class="fa fa-download"></i> EXPORT
                          </button>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default NsdlPANCardReport;
