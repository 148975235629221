import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const CompanyDetails = (props) => {
  const data = props.data;

  const loadingCtx = useContext(LoadingContext);

  const companyNameRef = useRef();
  const legalNameRef = useRef();
  const primaryDomainRef = useRef();
  const androidAppRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const addressRef = useRef();
  const panNumberRef = useRef();
  const gstinRef = useRef();

  const updateHandler = async () => {
    const companyName = companyNameRef.current.value;
    const legalName = legalNameRef.current.value;
    const primaryDomain = primaryDomainRef.current.value;
    const androidApp = androidAppRef.current.value;
    const phone = phoneRef.current.value;
    const email = emailRef.current.value;
    const address = addressRef.current.value;
    const panNumber = panNumberRef.current.value;
    const gstin = gstinRef.current.value;

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateCompanyDetails(
        token,
        companyName,
        legalName,
        primaryDomain,
        androidApp,
        phone,
        email,
        address,
        panNumber,
        gstin
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Company Name</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={companyNameRef}
                defaultValue={data ? data.company_name : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Legal Name</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={legalNameRef}
                defaultValue={data ? data.legal_name : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Primary Domain</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={primaryDomainRef}
                defaultValue={data ? data.primary_domain : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Android App</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={androidAppRef}
                defaultValue={data ? data.android_app : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Phone</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={phoneRef}
                defaultValue={data ? data.phone : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={emailRef}
                defaultValue={data ? data.email : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Address</label>
            <div class="col-sm-10">
              <textarea
                className="form-control"
                ref={addressRef}
                defaultValue={data ? data.address : ""}
              ></textarea>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">PAN Number</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={panNumberRef}
                defaultValue={data ? data.pan_number : ""}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">GSTIN</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                ref={gstinRef}
                defaultValue={data ? data.gstin : ""}
              />
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetails;
