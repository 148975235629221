import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const WhatsappSettings = (props) => {
  let data = props.data.whatsapp_settings;

  const loadingCtx = useContext(LoadingContext);

  const [whatsappSettings, setWhatsappSettings] = useState(data);
  const [headers, setHeaders] = useState(data.headers);
  const [contentType, setContentType] = useState(data.content_type);
  const [body, setBody] = useState(data.body ? data.body : []);
  const [jsonBody, setJsonBody] = useState(JSON.stringify(data.json_body));

  const urlRef = useRef();
  const methodRef = useRef();

  const updateHandler = async () => {
    const url = urlRef.current.value;
    const method = methodRef.current.value;

    let _jsonBody;

    if (contentType == "JSON" && jsonBody) {
      _jsonBody = JSON.parse(jsonBody);
    } else {
      _jsonBody = {};
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateWhatsappSettings(
        token,
        url,
        method,
        headers,
        contentType,
        body,
        jsonBody
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-8 mx-auto">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">URL</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                defaultValue={data.url}
                ref={urlRef}
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Method</label>
            <div class="col-sm-9">
              <select className="form-control" ref={methodRef}>
                <option value={data ? data.method : ""}>
                  {data ? data.method : "Select Method"}
                </option>
                <option value="GET">GET</option>
                <option value="POST">POST</option>
              </select>
            </div>
          </div>

          <div class="card bg-light d-flex flex-fill">
            <div class="card-header text-muted border-bottom-0">
              <h3 class="card-title">Headers</h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  onClick={() => {
                    const _headers = [...headers, { key: "", value: "" }];

                    setHeaders(_headers);
                  }}
                >
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </div>

            <div class="card-body pt-0 mb-2 ">
              {headers.map((e, index) => {
                return (
                  <div class="row pt-2" key={index}>
                    <div class="col-5">
                      <label>Key</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.key}
                        onInput={(x) => {
                          let key = x.target.value;

                          headers[index].key = key;
                        }}
                      />
                    </div>
                    <div class="col-5">
                      <label>Value</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.value}
                        onInput={(x) => {
                          let value = x.target.value;

                          headers[index].value = value;
                        }}
                      />
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style={{ marginTop: "34px" }}
                        onClick={() => {
                          let _headers = headers.filter(
                            (item, index2) => index2 !== index
                          );

                          setHeaders(_headers);
                        }}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div class="card bg-light d-flex flex-fill">
            <div class="card-header text-muted border-bottom-0">
              <h3 class="card-title">Body</h3>
              <div class="card-tools">
                {contentType === "Form Data" && (
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    onClick={() => {
                      const _body = [...body, { key: "", value: "" }];

                      setBody(_body);
                    }}
                  >
                    <i className="fas fa-plus"></i> Add
                  </button>
                )}
              </div>
            </div>

            <div class="card-body pt-0 mb-2 ">
              <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item">
                  <a
                    className={`nav-link ${
                      contentType === "Form Data" ? "active" : ""
                    }`}
                    id="custom-tabs-four-home-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="custom-tabs-four-home"
                    aria-selected="true"
                    onClick={() => {
                      setContentType("Form Data");
                    }}
                  >
                    Form Data
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    className={`nav-link ${
                      contentType === "JSON" ? "active" : ""
                    }`}
                    id="custom-tabs-four-profile-tab"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="custom-tabs-four-profile"
                    aria-selected="false"
                    onClick={() => {
                      setContentType("JSON");
                    }}
                  >
                    JSON
                  </a>
                </li>
              </ul>

              {contentType === "Form Data" && (
                <div>
                  {body.map((e, index) => {
                    return (
                      <div class="row pt-2">
                        <div class="col-5">
                          <label>Key</label>
                          <input
                            type="text"
                            class="form-control"
                            defaultValue={e.key}
                            onInput={(x) => {
                              let key = x.target.value;

                              body[index].key = key;
                            }}
                          />
                        </div>
                        <div class="col-5">
                          <label>Value</label>
                          <input
                            type="text"
                            class="form-control"
                            defaultValue={e.value}
                            onInput={(x) => {
                              let value = x.target.value;

                              body[index].value = value;
                            }}
                          />
                        </div>
                        <div class="col-2">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            style={{ marginTop: "34px" }}
                            onClick={() => {
                              let _body = body.filter(
                                (item, index2) => index2 !== index
                              );

                              setBody(_body);
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {contentType === "JSON" && (
                <div class="row pt-2">
                  <div class="col-12">
                    <textarea
                      type="text"
                      class="form-control mt-2"
                      rows="6"
                      defaultValue={JSON.stringify(data.json_body)}
                      onChange={(x) => {
                        setJsonBody(x.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <p>{"{mobile_number}"}</p>

          <p>{"{mobile_number_with_country_code}"}</p>

          <p>{"{message}"}</p>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhatsappSettings;
