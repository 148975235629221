import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";

const TxnStatus = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const formRef = useRef();
  const orderIdRef = useRef();

  const submitHandler = async () => {
    const order_id = orderIdRef.current.value;

    if (!order_id) {
      Swal.fire("Opps!", "Invalid Order ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.txnStatus(token, order_id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <div className="mt-3 mb-3">
      {data && (
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Status</th>
                <th scope="col">Acknowledgement Number</th>
                {data.error_message && <th scope="col">Error Message</th>}
                <th scope="col">Date Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.order_id}</td>
                <td>{data.status}</td>
                <td>{data.ack_no}</td>
                {data.error_message && <td>{data.error_message}</td>}
                <td>{data.date}</td>
              </tr>
            </tbody>
          </Table>

          <div className="text-center plan-btn mt-2 mb-2">
            <Button
              color="primary"
              onClick={() => {
                setData();
              }}
            >
              Back
            </Button>
          </div>
        </div>
      )}

      {!data && (
        <form ref={formRef}>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control "
                  placeholder="Order ID"
                  ref={orderIdRef}
                />
              </div>
            </div>
          </div>

          <div class="text-center plan-btn mt-2 mb-2">
            <button
              type="button"
              class="btn btn-primary"
              onClick={submitHandler}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default TxnStatus;
