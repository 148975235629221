import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";

import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import UserService from "../../../../services/Admin/UserService";
import AuthService from "../../../../services/Admin/AuthService";

const ActionTab = (props) => {
  const formRef = React.useRef();
  const statusRef = React.useRef();
  const remarkRef = React.useRef();

  const loadingCtx = React.useContext(LoadingContext);

  const kycStatusUpdateHandler = async () => {
    const kycStatus = statusRef.current.value;
    const remark = remarkRef.current.value;

    if (!kycStatus) {
      Swal.fire("Opps!", "Please Select Status", "error");
      return;
    }

    if (kycStatus === "Rejected" && !remark) {
      Swal.fire("Opps!", "Please Enter Remark", "error");
      return;
    }

    // START LOADING
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await UserService.updateKycStatus(
        token,
        props.userData ? props.userData.id : "",
        kycStatus,
        remark
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        //  formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    // STOP LOADING
    loadingCtx.stopLoading();
  };

  const addPSAHandler = async () => {
    // START LOADING
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await UserService.addPSA(
        token,
        props.userData ? props.userData.id : ""
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    // STOP LOADING
    loadingCtx.stopLoading();
  };

  return (
    <div>
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.created_on}
              </ListItemSecondaryAction>
              <ListItemText primary="Created On" />
            </ListItem>

            {/* <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.last_password_changed_on}
                </ListItemSecondaryAction>
                <ListItemText primary="Last Password Changed On" />
              </ListItem>

              <ListItem>
                <ListItemSecondaryAction>
                  {props.userData && props.userData.last_pin_changed_on}
                </ListItemSecondaryAction>
                <ListItemText primary="Last PIN Changed On" />
              </ListItem> */}

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.status}
              </ListItemSecondaryAction>
              <ListItemText primary="Status" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.kyc_status}
              </ListItemSecondaryAction>
              <ListItemText primary="KYC Status" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.userData && props.userData.kyc_remark}
              </ListItemSecondaryAction>
              <ListItemText primary="KYC Remark" />
            </ListItem>
          </List>

          <Grid item className="pt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select label="Status" inputRef={statusRef}>
                <MenuItem value="Verified">VERIFIED</MenuItem>
                <MenuItem value="Rejected">REJECTED</MenuItem>
                <MenuItem value="Suspended">SUSPENDED</MenuItem>
                <MenuItem value="Pending">PENDING</MenuItem>
                <MenuItem value="Not Applied">NOT APPLIED</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="pt-4">
            <TextField
              inputRef={remarkRef}
              fullWidth
              placeholder="Remark"
              multiline
              rows={4}
              rowsMax={4}
            />
          </Grid>

          <div class="text-center pt-4">
            <button
              type="button"
              class="btn btn-primary"
              onClick={kycStatusUpdateHandler}
              disabled={loadingCtx.isLoading}
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-default ml-5"
              disabled={loadingCtx.isLoading}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionTab;
