import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
//import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
// import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import TreeItem from "@mui/lab/TreeItem";

import Tree from "react-animated-tree";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import DbModuleService from "../../../services/User/DbModuleService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const folder = {
  name: "",
  children: [
    {
      name: "Fruits",
      children: [
        { name: "Avocados" },
        { name: "Bananas" },
        { name: "Berries" },
        { name: "Oranges" },
        { name: "Pears" },
      ],
    },
    {
      name: "Drinks",
      children: [
        { name: "Apple Juice" },
        { name: "Chocolate" },
        { name: "Coffee" },
        {
          name: "Tea",
          children: [
            { name: "Black Tea" },
            { name: "Green Tea" },
            { name: "Red Tea" },
            { name: "Matcha" },
          ],
        },
      ],
    },
    {
      name: "Vegetables",
      children: [
        { name: "Beets" },
        { name: "Carrots" },
        { name: "Celery" },
        { name: "Lettuce" },
        { name: "Onions" },
      ],
    },
  ],
};

const data1 = flattenTree(folder);

const UserListTreeViewSso = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorization = params.get("authorization");

    if (!authorization) {
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = authorization;

      const responseData = await DbModuleService.list(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        console.log(responseData.data);
        setData(flattenTree(responseData.data));
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  // const renderTree = (nodes) => (
  //   <TreeItem nodeId={nodes.id} label={nodes.name + " (" + nodes.user_id + ")"}>
  //     {Array.isArray(nodes.children)
  //       ? nodes.children.map((node) => renderTree(node))
  //       : null}
  //   </TreeItem>
  // );

  const renderTree = (nodes) => (
    <Tree
      id={nodes.id}
      content={
        nodes.name +
        " (" +
        nodes.user_id +
        " [" +
        nodes.package_name +
        "-" +
        nodes.amount +
        "%])" +
        " (Invt Bal: ₹" +
        nodes.main_wallet +
        ")"
      }
    >
      {nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </Tree>
  );

  const [expandedIds, setExpandedIds] = useState();

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      getAndSetIds();
    }
  };

  const getAndSetIds = () => {
    setExpandedIds(
      document
        .querySelector("#txtIdsToExpand")
        .value.split(",")
        .filter((val) => !!val.trim())
        .map((x) => {
          if (isNaN(parseInt(x.trim()))) {
            return x;
          }
          return parseInt(x.trim());
        })
    );
  };

  return (
    <React.Fragment>
      {/* <Templete t1="Home" t2="User List (Tree View)">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User List (Tree View)</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        {data && (
                          // <TreeView
                          //   aria-label="rich object"
                          //   defaultCollapseIcon={<ExpandMoreIcon />}
                          //   defaultExpanded={["root"]}
                          //   defaultExpandIcon={<ChevronRightIcon />}
                          //   sx={{
                          //     height: 110,
                          //     flexGrow: 1,
                          //     maxWidth: 400,
                          //     overflowY: "auto",
                          //   }}
                          // >
                          //   {renderTree(data)}
                          // </TreeView>

                          <div>{renderTree(data)} </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete> */}

      {data && (
        <TreeView
          data={data}
          aria-label="Controlled expanded node tree"
          expandedIds={expandedIds}
          defaultExpandedIds={[1]}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isDisabled,
            getNodeProps,
            level,
            handleExpand,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{
                  marginLeft: 40 * (level - 1),
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                <span className="name">
                  {element.name}-{element.id}
                </span>
              </div>
            );
          }}
        />
      )}
    </React.Fragment>
  );
};

const ArrowIcon = ({ isOpen, className }) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
  return <ExpandMoreIcon className={classes} />;
};

export default UserListTreeViewSso;
