import React, { useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import WalletIcon from "@mui/icons-material/Wallet";
import { Chip } from "@material-ui/core";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/Admin/AuthService";
import PPIService from "../../../../services/Admin/PPIService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
// import ViewLogDialog from "./ViewLogDialog";

const PrepaidCardList = () => {
  const loadingCtx = useContext(LoadingContext);

  const history = useHistory();

  const [userId, setUserId] = useState();
  const [status, setStatus] = useState();
  const [orderStatus, setOrderStatus] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    PPIService.fetchCards(
      token,
      userId,
      status,
      orderStatus,
      searchText,
      size,
      page
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Reference Number",
      cell: (row) => (
        <div>
          <span>{row.reference_number}</span>
        </div>
      ),
    },
    {
      name: "Account Number",
      cell: (row) => (
        <div>
          <span>{row.account_number}</span>
        </div>
      ),
    },
    {
      name: "Order Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.order_status === "ISSUED") {
              return (
                <Chip
                  style={{ color: "green", borderColor: "green" }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  label={row.order_status}
                />
              );
            } else if (row.order_status === "PENDING") {
              return (
                <Chip
                  style={{ color: "yellow", borderColor: "yellow" }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  label={row.order_status}
                />
              );
            } else {
              return (
                <Chip
                  style={{ color: "red", borderColor: "red" }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  label={row.order_status}
                />
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "Active") {
              return (
                <h6>
                  <span class="badge badge-success">Active</span>
                </h6>
              );
            } else {
              return (
                <h6>
                  <span class="badge badge-danger">Inactive</span>
                </h6>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/ppi_topup/" + row.id);
            }}
          >
            <WalletIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Prepaid Card List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Prepaid Card List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">---Select Status---</option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setOrderStatus(e.target.value);
                                  }}
                                >
                                  <option value="">
                                    ---Select Order Status---
                                  </option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="ISSUED">ISSUED</option>
                                  <option value="FAILED">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      {/* <ViewLogDialog setOpen={setOpen} open={open} fData={selectedData} /> */}
    </React.Fragment>
  );
};

export default PrepaidCardList;
