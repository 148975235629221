import React, { useContext, useEffect } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Loading from "./pages/Loading/Loading";
import LoadingContext from "./store/loading-context";

import Routes from "./routes";

const App = () => {
  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorization = params.get("authorization");

    if (!authorization) {
      return;
    }
  };

  return (
    <React.Fragment>
      {loadingCtx.isLoading && <Loading />}

      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
