import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ViewProfile = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.fetch(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        if (message === "Unauthorized Access") {
          window.location.href = "/user/logout";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      {data && (
        <table cellpadding="0" cellspacing="0" border="0" width="100%">
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="0"
                width="100%"
                align="center"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        cellpadding="3"
                        cellspacing="0"
                        border="0"
                        width="100%"
                        align="center"
                      >
                        <tr>
                          <td width="10%" align="center" valign="middle">
                            <br />

                            <table
                              cellpadding="4"
                              cellspacing="0"
                              border="1"
                              width="70%"
                              align="center"
                            >
                              <tr>
                                <td colspan="4">
                                  <font size="+1">
                                    <b>Personal Details:</b>
                                  </font>
                                </td>
                              </tr>
                              <tr>
                                <td width="44%">
                                  <b>User ID</b>
                                </td>
                                <td width="56%">{data.user_id}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>{data.name}</td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <b>Father/Husband's Name</b>
                                </td>
                                <td>{data.fathers_name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Gender</b>
                                </td>
                                <td>{data.gender}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Date Of Birth</b>
                                </td>
                                <td>{data.dob}</td>
                              </tr> */}
                              <tr>
                                <td>
                                  <b>Mobile</b>
                                </td>
                                <td>{data.mobile}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email ID</b>
                                </td>
                                <td>{data.email}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>PIN Code</b>
                                </td>
                                <td>{data.pincode}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>State</b>
                                </td>
                                <td>{data.state}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>City</b>
                                </td>
                                <td>{data.city}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>District</b>
                                </td>
                                <td>{data.district}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Address</b>
                                </td>
                                <td>{data.address}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Status</b>
                                </td>
                                <td>{data.status}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Registration Date</b>
                                </td>
                                <td>{data.created_on}</td>
                              </tr>
                              {/* 
                              {data.nominee_name && (
                                <React.Fragment>
                                  <tr>
                                    <td colspan="4">
                                      <font size="+1">
                                        <b>Nominee Details:</b>
                                      </font>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Nominee Name</b>
                                    </td>
                                    <td>{data.nominee_name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Nominee Relation</b>
                                    </td>
                                    <td>{data.nominee_relation}</td>
                                  </tr>
                                </React.Fragment>
                              )} */}

                              {/* {data.beneficiary_name && (
                                <React.Fragment>
                                  <tr>
                                    <td colspan="4">
                                      <font size="+1">
                                        <b>Bank A/C Details:</b>
                                      </font>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Beneficiary Name</b>
                                    </td>
                                    <td>{data.beneficiary_name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Bank Name</b>
                                    </td>
                                    <td>{data.bank_name}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Account Number</b>
                                    </td>
                                    <td>{data.account_number}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>IFSC Code</b>
                                    </td>
                                    <td>{data.ifsc_code}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Account Type</b>
                                    </td>
                                    <td>{data.account_type}</td>
                                  </tr>
                                </React.Fragment>
                              )} */}
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <br />
        </table>
      )}
    </React.Fragment>
  );
};

export default ViewProfile;
