import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";

const PANStatus = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const formRef = useRef();
  const ackNoRef = useRef();

  const submitHandler = async () => {
    const ack_no = ackNoRef.current.value;

    if (!ack_no) {
      Swal.ack_no("Opps!", "Invalid Acknowledgement Number", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.panStatus(token, ack_no);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <div className="mt-3 mb-3">
      {data && (
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th scope="col">Acknowledgement Number</th>
                <th scope="col">PAN Status</th>
                <th scope="col">PAN</th>
                {data.error_message && <th scope="col">Error Message</th>}
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.ack_no}</td>
                <td>{data.pan_status}</td>
                <td>{data.pan}</td>
                {data.error_message && <td>{data.error_message}</td>}
                <td>
                  {" "}
                  <Button
                    size="sm"
                    color="success"
                    type="submit"
                    onClick={() =>
                      window.open(
                        "https://www.onlineservices.nsdl.com/paam/requestAndDownloadEPAN.html",
                        "_blank"
                      )
                    }
                  >
                    e-PAN download
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="text-center plan-btn mt-2 mb-2">
            <Button
              color="primary"
              onClick={() => {
                setData();
              }}
            >
              Back
            </Button>
          </div>
        </div>
      )}

      {!data && (
        <form ref={formRef}>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control "
                  placeholder="Acknowledgement Number"
                  ref={ackNoRef}
                />
              </div>
            </div>
          </div>

          <div class="text-center plan-btn mt-2 mb-2">
            <button
              type="button"
              class="btn btn-primary"
              onClick={submitHandler}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PANStatus;
