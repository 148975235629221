import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import RechargeService from "../../../services/Admin/RechargeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

const RechargeReport = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const id = props.id ? props.id : props.match.params.id;

  useEffect(() => {
    updateUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserId = () => {
    if (id) {
      setUserId(id);
      fetchData(null, id);
      getSummary(id);
    }
  };

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [productId, setProductId] = useState();
  const [userId, setUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _status = params.get("status");

    if (_status) {
      setStatus(_status);
      await fetchData(_status);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async (_status, id) => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    RechargeService.report(
      token,
      fromDate,
      toDate,
      _status ? _status : status,
      productId,
      id ? id : userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },

    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "API Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.recharge_api ? row.recharge_api.name : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Operator",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Operator: {row.product.name}</span>
          <br />
          <span>Number: {row.optional1}</span>
          <br />
          <span>Amount: ₹{row.order_amount}</span>
        </div>
      ),
    },
    // {
    //   name: "Number",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span>{row.optional1}</span>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Amount",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span>₹{row.order_amount}</span>
    //     </div>
    //   ),
    // },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Commission",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>User: ₹{row.commercial_amount}</span> <br />
          <span>Admin: ₹{row.admin_c_amount}</span>
        </div>
      ),
    },
    {
      name: "Response",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <span>
                  {row.secret_optional2 ? row.secret_optional2 : "NULL"}
                </span>
              );
            } else {
              return <span>N/A</span>;
            }
          })()}
        </div>
      ),
    },
    {
      name: "Operator Ref",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.operator_id ? row.operator_id : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => {
              handleClick(event);
              setSelectedData(row);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                apiDisputeRequestHandler(selectedData.id);
              }}
            >
              API Dispute Request
            </MenuItem>
            <MenuItem
              onClick={() => {
                apiStatusCheckHandler(selectedData.id);
              }}
            >
              API Status Check
            </MenuItem>
          </Menu>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to SUCCESS?",
                html: `
                  <input id="swal-input1" placeholder="Operator ID" value=${row.operator_id} class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Success`,
                confirmButtonColor: "green",
                preConfirm: () => {
                  return [document.getElementById("swal-input1").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "SUCCESS", formValues[0], null);
              }
            }}
          >
            <DoneOutlineIcon />
          </IconButton>

          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to FAILED?",
                html: `
                <input id="swal-input2" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Failure`,
                confirmButtonColor: "red",
                preConfirm: () => {
                  return [document.getElementById("swal-input2").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "FAILED", null, formValues[0]);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const apiDisputeRequestHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.apiDisputeRequest(token, id);

      const status1 = responseData.status;
      const message = responseData.message;
      const log = responseData.data ? responseData.data.log : null;

      //  alert(message);
      Swal.fire({
        title: message,
        text: log ? JSON.stringify(log) : "null",
      });
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const apiStatusCheckHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.apiStatusCheck(token, id);

      const status1 = responseData.status;
      const message = responseData.message;
      const log = responseData.data ? responseData.data.log : null;

      Swal.fire({
        title: message,
        text: log ? JSON.stringify(log) : "null",
      });
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [isLoading, setIsloading] = useState(false);

  const [summaryData, setSummaryData] = useState();

  const getSummary = async (id) => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.summary(
        token,
        fromDate,
        toDate,
        productId,
        id ? id : userId
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const xlsReport = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.xlsReport(
        token,
        fromDate,
        toDate
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const updateTxnStatusHandler = async (id, status, operator_id, remark) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.updateTxnStatus(
        token,
        id,
        status,
        operator_id,
        remark
      );

      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Recharge Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Recharge Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={status ? status : ""}>
                                    {status ? status : "Select Status"}{" "}
                                  </option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setProductId(e.target.value);
                                  }}
                                >
                                  <option value="">Select Operator</option>
                                  <option value="1">Airtel</option>
                                  <option value="2">BSNL Topup</option>
                                  <option value="3">BSNL Special</option>
                                  <option value="4">JIO</option>
                                  <option value="5">Vi</option>
                                  <option value="6">Airtel Digital TV</option>
                                  <option value="7">Dish TV</option>
                                  <option value="8">Sun Direct</option>
                                  <option value="9">Tata Sky</option>
                                  <option value="10">Videocon D2H</option>
                                  <option value="47">Google Play</option>
                                  <option value="15">Electricity</option>
                                  <option value="16">Fastag</option>
                                  <option value="27">Piped Gas</option>
                                  <option value="28">Mobile Postpaid</option>
                                  <option value="29">Landline Postpaid</option>
                                  <option value="30">Water</option>
                                  <option value="31">DTH</option>
                                  <option value="32">Broadband Postpaid</option>
                                  <option value="33">
                                    Clubs and Associations
                                  </option>
                                  <option value="34">
                                    Hospital and Pathology
                                  </option>
                                  <option value="35">Credit Card</option>
                                  <option value="36">LPG Gas</option>
                                  <option value="37">Loan Repayment</option>
                                  <option value="38">Life Insurance</option>
                                  <option value="39">Health Insurance</option>
                                  <option value="40">Cable TV</option>
                                  <option value="41">Education Fees</option>
                                  <option value="42">Housing Society</option>
                                  <option value="43">Municipal Taxes</option>
                                  <option value="44">Municipal Services</option>
                                  <option value="45">Subscription</option>
                                  <option value="46">Insurance</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  defaultValue={userId}
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-secondary mr-4"
                                  onClick={() => {
                                    getSummary();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Fetch Summary
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-outline-primary"
                                  onClick={xlsReport}
                                >
                                  <i class="fa fa-download"></i> EXPORT
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.success.rows.length > 0
                                  ? "₹" +
                                    summaryData.success.rows[0].total_amount +
                                    " [" +
                                    summaryData.success.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    summaryData.success.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL PENDING</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.pending.rows.length > 0
                                  ? "₹" +
                                    summaryData.pending.rows[0].total_amount +
                                    " [" +
                                    summaryData.pending.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    summaryData.pending.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL FAILED</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.failed.rows.length > 0
                                  ? "₹" +
                                    summaryData.failed.rows[0].total_amount +
                                    " [" +
                                    summaryData.failed.count +
                                    "]"
                                  : "₹0" + " [" + summaryData.failed.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </React.Fragment>
  );
};

export default RechargeReport;
