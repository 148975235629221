import React from "react";

const WebhookApiTab = (props) => {
  const rechargeApi = props.webhookApi;
  const setRechargeApi = props.setWebhookApi;

  return (
    <React.Fragment>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Method</label>
        <div class="col-sm-9">
          <select
            className="form-control"
            onChange={(x) => {
              let value = x.target.value;

              rechargeApi.method = value;
            }}
          >
            <option value={rechargeApi ? rechargeApi.method : ""}>
              {rechargeApi ? rechargeApi.method : "Select Method"}
            </option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
          </select>
        </div>
      </div>

      {/* {JSON.stringify(rechargeApi)} */}

      <div class="card bg-light d-flex flex-fill">
        <div class="card-header text-muted border-bottom-0">
          <h3 class="card-title">Response</h3>
        </div>

        <div class="card-body pt-0 mb-2 ">
          <div class="row pt-2">
            <div class="col-4">
              <label>Status Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_key = value;
                }}
              />
            </div>
            <div class="col-4">
              <label>Message Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.message_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.message_key = value;
                }}
              />
            </div>
            <div class="col-4">
              <label>Order ID Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.order_id_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.order_id_key = value;
                }}
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-4">
              <label>Operator Ref ID Key</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.operator_ref_id_key}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.operator_ref_id_key = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Status Success Value</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_success_value}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_success_value = value;
                }}
              />
            </div>

            <div class="col-4">
              <label>Status Failed Value</label>
              <input
                type="text"
                class="form-control"
                defaultValue={rechargeApi.response_keys.status_failed_value}
                onInput={(x) => {
                  let value = x.target.value;

                  rechargeApi.response_keys.status_failed_value = value;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebhookApiTab;
