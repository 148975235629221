import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import WithdrawalService from "../../../services/Admin/WithdrawalService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import ActionDialog from "./ActionDialog";

const PendingWithdrawalRequest = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const size = 10;

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate, status, userId]);

  const fetch = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WithdrawalService.pendingWithdrawalRequest(
      token,
      fromDate,
      toDate,
      userId,
      status,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            {row.tbl_user.user_id} ({row.tbl_user.name})
          </span>
        </div>
      ),
    },
    {
      name: "Withdrawal Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>₹{row.amount}</span>
        </div>
      ),
    },
    {
      name: "Investment Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.investment_id}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "APPROVED") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else if (row.status === "PENDING") {
              return (
                <span class="badge badge-pill badge-warning">{row.status}</span>
              );
            } else if (row.status === "REJECTED") {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Requested Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const deleteInvEntryHandler = async (id, orderId) => {
    // loadingCtx.startLoading();
    // try {
    //   const token = AuthService.getAccessToken();
    //   const responseData = await InvestmentService.deleteEntry(
    //     token,
    //     id,
    //     orderId
    //   );
    //   const status = responseData.status;
    //   const message = responseData.message;
    //   if (status === "SUCCESS") {
    //     Swal.fire("Success!", message, "success");
    //   } else {
    //     Swal.fire("Opps!", message, "error");
    //   }
    // } catch (error) {
    //   const errMsg = HttpError.getError(error);
    //   Swal.fire("Opps!", errMsg, "error");
    // }
    // loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Pending Withdrawal Request">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Pending Withdrawal Request</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="From Date"
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                    defaultValue={fromDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="To Date"
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                    defaultValue={toDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="User ID"
                                    onChange={(e) => {
                                      setUserId(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <ActionDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selectedData={selectedData}
        fetch={fetch}
      />
    </React.Fragment>
  );
};

export default PendingWithdrawalRequest;
