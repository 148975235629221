import common from "../../utill/Common";

class PackageService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/package/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (token, name, status) => {
    const url = common.baseUrl + "admin/package/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        name,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (token, id, name, status) => {
    const url = common.baseUrl + "admin/package/edit";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        id,
        name,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/package/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAll = async (token) => {
    const url = common.baseUrl + "admin/package/fetch_all";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PackageService();
