import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const TxnDetailsTab = (props) => {
  return (
    <Box>
      <Grid container justify="center" spacing={10}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.tbl_user.user_id}
              </ListItemSecondaryAction>
              <ListItemText primary="User ID" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.tbl_user.name}
              </ListItemSecondaryAction>
              <ListItemText primary="Name" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.product
                  ? props.fData.product.name
                  : ""}
              </ListItemSecondaryAction>
              <ListItemText primary="Product" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.product
                  ? props.fData.product.sub_master_product.name
                  : ""}
              </ListItemSecondaryAction>
              <ListItemText primary="Sub Product" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.order_id}
              </ListItemSecondaryAction>
              <ListItemText primary="Order ID" />
            </ListItem>

            {props.fData && props.fData.partner_order_id && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.partner_order_id}
                </ListItemSecondaryAction>
                <ListItemText primary="Partner Order ID" />
              </ListItem>
            )}

            {props.fData && props.fData.refund_order_id && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.refund_order_id}
                </ListItemSecondaryAction>
                <ListItemText primary="Refund Order ID" />
              </ListItem>
            )}

            {props.fData && props.fData.operator_id && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.operator_id}
                </ListItemSecondaryAction>
                <ListItemText primary="Operator ID" />
              </ListItem>
            )}

            {props.fData && props.fData.account && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.account}
                </ListItemSecondaryAction>
                <ListItemText primary="Account" />
              </ListItem>
            )}

            {props.fData && props.fData.mode && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.mode}
                </ListItemSecondaryAction>
                <ListItemText primary="Mode" />
              </ListItem>
            )}

            {props.fData && props.fData.optional1 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional1}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 1" />
              </ListItem>
            )}

            {props.fData && props.fData.optional2 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional2}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 2" />
              </ListItem>
            )}

            {props.fData && props.fData.optional3 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional3}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 3" />
              </ListItem>
            )}

            {props.fData && props.fData.optional4 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional4}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 4" />
              </ListItem>
            )}

            {props.fData && props.fData.optional5 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional5}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 5" />
              </ListItem>
            )}

            {props.fData && props.fData.optional6 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional6}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 6" />
              </ListItem>
            )}

            {props.fData && props.fData.optional7 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional7}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 7" />
              </ListItem>
            )}

            {props.fData && props.fData.optional8 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional8}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 8" />
              </ListItem>
            )}

            {props.fData && props.fData.optional9 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional9}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 9" />
              </ListItem>
            )}

            {props.fData && props.fData.optional10 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional10}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 10" />
              </ListItem>
            )}

            {props.fData && props.fData.optional11 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional11}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 11" />
              </ListItem>
            )}

            {props.fData && props.fData.optional12 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional12}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 12" />
              </ListItem>
            )}

            {props.fData && props.fData.optional13 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional13}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 13" />
              </ListItem>
            )}

            {props.fData && props.fData.optional14 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional14}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 14" />
              </ListItem>
            )}

            {props.fData && props.fData.optional15 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.optional15}
                </ListItemSecondaryAction>
                <ListItemText primary="Optional 15" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional1 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional1}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 1" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional2 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional2}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 2" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional3 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional3}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 3" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional4 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional4}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 4" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional5 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional5}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 5" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional6 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional6}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 6" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional7 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional7}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 7" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional8 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional8}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 8" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional9 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional9}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 9" />
              </ListItem>
            )}

            {props.fData && props.fData.secret_optional10 && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.secret_optional10}
                </ListItemSecondaryAction>
                <ListItemText primary="Secret Optional 10" />
              </ListItem>
            )}

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.created_on}
              </ListItemSecondaryAction>
              <ListItemText primary="TXN Date Time" />
            </ListItem>

            {props.fData && props.fData.modified_on && (
              <ListItem>
                <ListItemSecondaryAction>
                  {props.fData && props.fData.modified_on}
                </ListItemSecondaryAction>
                <ListItemText primary="TXN Updated On" />
              </ListItem>
            )}
          </List>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <List>
            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.description}
              </ListItemSecondaryAction>
              <ListItemText primary="Description" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                {props.fData && props.fData.status}
              </ListItemSecondaryAction>
              <ListItemText primary="Status" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                ₹ <b>{props.fData && props.fData.order_amount} </b>
              </ListItemSecondaryAction>
              <ListItemText primary="Order Amount" />
            </ListItem>

            {props.fData && (
              <ListItem>
                <ListItemSecondaryAction>
                  ₹ {props.fData && props.fData.commercial_amount}
                </ListItemSecondaryAction>
                <ListItemText primary="Commercial Amount" />
              </ListItem>
            )}

            {props.fData && (
              <ListItem>
                <ListItemSecondaryAction>
                  ₹ {props.fData && props.fData.tds_amount}
                </ListItemSecondaryAction>
                <ListItemText primary="TDS Amount" />
              </ListItem>
            )}

            {props.fData && (
              <ListItem>
                <ListItemSecondaryAction>
                  ₹ {props.fData && props.fData.gst_amount}
                </ListItemSecondaryAction>
                <ListItemText primary="GST Amount" />
              </ListItem>
            )}

            <ListItem>
              <ListItemSecondaryAction>
                ₹ {props.fData && props.fData.txn_type}
              </ListItemSecondaryAction>
              <ListItemText primary="TXN Type" />
            </ListItem>

            <ListItem>
              <ListItemSecondaryAction>
                ₹ {props.fData && props.fData.amount}
              </ListItemSecondaryAction>
              <ListItemText primary="Total TXN Amount" />
            </ListItem>

            <table id="example1" class="table table-bordered">
              <tbody>
                <tr
                  style={{ textAlign: "center", border: "2px solid #cedeed" }}
                >
                  <td>
                    OPENING BAL
                    <br />
                    <strong>₹ {props.fData && props.fData.opening_bal}</strong>
                  </td>
                  <td>
                    TXN AMOUNT
                    <br />
                    <strong>
                      ₹ {props.fData && props.fData.amount} (
                      {props.fData && props.fData.txn_type})
                    </strong>
                  </td>
                  <td>
                    CLOSING BAL
                    <br />
                    <strong>₹ {props.fData && props.fData.closing_bal}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TxnDetailsTab;
