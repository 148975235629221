import React, { useRef, useEffect, useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import AuthService from "../../../services/User/AuthService";
import DbModuleService from "../../../services/User/DbModuleService";
import HttpError from "../../../utill/HttpError";
import CommercialService from "../../../services/User/CommercialService";
import LoadingContext from "../../../store/loading-context";

const ViewProfileDialog = (props) => {
  const packageIdRef = useRef();

  const loadingCtx = useContext(LoadingContext);

  const [userType, setUserType] = useState();
  const [packages, setPackages] = useState();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const userType = AuthService.getUserType();
    setUserType(userType);

    if (userType !== "Reseller") {
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await CommercialService.fetchPackages(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setPackages(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const updatePackageHandler = async () => {
    const packageId = packageIdRef.current.value;

    if (!packageId) {
      Swal.fire("Opps!", "Invalid Package", "error");
      return;
    }

    console.log(props.fData);

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await DbModuleService.updatePackage(
        token,
        props.fData ? props.fData.user_id : "",
        packageId
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        props.fetchUsers();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const getInfo = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await DbModuleService.fetchById(token, id);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const data1 = data.data;
        alert(data1.name + " [" + data1.name + "] (" + data1.user_id + ")");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <>
      <Modal fullscreen={true} show={props.open} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>View Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userType === "Reseller" && props.fData && props.fData.dt_id && (
            <Button
              variant="secondary"
              onClick={() => {
                getInfo(props.fData.dt_id);
              }}
            >
              Get Distributor Info
            </Button>
          )}
          <br />
          <br />
          {userType === "Reseller" && props.fData && props.fData.md_id && (
            <Button
              variant="secondary"
              onClick={() => {
                getInfo(props.fData.md_id);
              }}
            >
              Get Master Distributor Info
            </Button>
          )}

          <br />
          <br />

          {userType === "Reseller" && (
            <div class="form-group">
              <label>Commercial Package</label>
              <select
                class="form-control form-control-border"
                ref={packageIdRef}
              >
                <option value="">Select Commercial Package</option>

                {props.fData &&
                  packages &&
                  packages.map((e, key) => {
                    return (
                      <option
                        key={key}
                        value={e.id}
                        selected={e.id == props.fData.package_id ? true : false}
                      >
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}

          {/* {userType === "Reseller" && props.fData && props.fData.md_id && (
            <ListItem>
              <ListItemSecondaryAction>
                {props.fData.md_id}
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    getInfo(props.fData.md_id);
                  }}
                >
                  Get Info
                </Button>
              </ListItemSecondaryAction>
              <ListItemText primary="Master Distributor Info" />
            </ListItem>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updatePackageHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewProfileDialog;
