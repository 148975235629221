import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import AgentsService from "../../../services/Admin/AgentsService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const PendingActionDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.selectedData;

  const [isLoading, setIsLoading] = useState(false);

  const viewPaymentReceiptHandler = async (orderId) => {
    // loadingCtx.startLoading();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.viewPaymentReceipt(
        token,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.setImage(responseData.data.image);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    setIsLoading(false);
  };

  const [status, setStatus] = useState();

  const remarkRef = useRef();

  const updateHandler = async (orderId) => {
    props.handleClose();
    loadingCtx.startLoading();
    // setIsLoading(true);

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    const remark = remarkRef.current.value;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.updateInvestmentRequest(
        token,
        orderId,
        status,
        remark
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  const getName = async (id) => {
    loadingCtx.startLoading();
    // setIsLoading(true);

    if (!id) {
      Swal.fire("Opps!", "Invalid ID", "error");
      return;
    }

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const userId = responseData.data.result.user_id;
        const name = responseData.data.result.name;

        Swal.fire("Success!", name + " [" + userId + "]", "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && (
              <ul class="list-group list-group-unbordered mb-3">
                <li class="list-group-item">
                  <b>User ID</b>{" "}
                  <span class="float-right">
                    {data
                      ? data.tbl_user.user_id + " (" + data.tbl_user.name + ")"
                      : ""}
                  </span>
                </li>
                <li class="list-group-item">
                  <b>Amount</b>{" "}
                  <span
                    class="float-right"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    ₹{data ? data.amount : ""}
                  </span>
                </li>
                {data && data.status === "APPROVED" && (
                  <li class="list-group-item">
                    <b>Amount Withdrawn</b>{" "}
                    <span
                      class="float-right"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      ₹{data ? data.amount_withdrawn : ""}
                    </span>
                  </li>
                )}

                <li class="list-group-item">
                  <b>From the Bank</b>{" "}
                  <span class="float-right">{data ? data.from_bank : ""}</span>
                </li>

                <li class="list-group-item">
                  <b>To the Bank</b>{" "}
                  <span class="float-right">{data ? data.to_bank : ""}</span>
                </li>

                <li class="list-group-item">
                  <b>Payment Mode/Date</b>{" "}
                  <span class="float-right">
                    {data
                      ? data.payment_mode + " (" + data.payment_date + ")"
                      : ""}
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Payment Ref ID</b>{" "}
                  <span class="float-right">
                    {data ? data.payment_ref_id : ""}
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Payment Receipt</b>{" "}
                  {isLoading && (
                    <span class="float-right badge badge-pill badge-danger">
                      Fetching...
                    </span>
                  )}
                  {!isLoading && (
                    <span class="float-right">
                      {props.image && (
                        <span>
                          <span
                            class="badge badge-pill badge-primary"
                            onClick={() => {
                              const newTab = window.open(props.image);

                              newTab?.document.write(
                                `<!DOCTYPE html><head><title>Image</title></head><body><img src="${props.image}"  ></body></html>`
                              );

                              newTab?.document.close();
                            }}
                          >
                            View
                          </span>

                          <a
                            href={props.image}
                            download={data.payment_receipt}
                            class="badge badge-pill badge-primary ml-2"
                          >
                            Download
                          </a>
                        </span>
                      )}

                      {!props.image && (
                        <span
                          class="badge badge-pill badge-primary ml-2"
                          onClick={() => {
                            viewPaymentReceiptHandler(
                              data ? data.order_id : ""
                            );
                          }}
                        >
                          Fetch
                        </span>
                      )}
                    </span>
                  )}
                </li>

                <li class="list-group-item">
                  <b>Remark</b>{" "}
                  <span class="float-right">{data ? data.remark : ""}</span>
                </li>

                {data && data.status === "APPROVED" && (
                  <li class="list-group-item">
                    <b>Admin Remark</b>{" "}
                    <span class="float-right">
                      {data ? data.admin_remark : ""}
                    </span>
                  </li>
                )}

                <li class="list-group-item">
                  <b>Order ID</b>{" "}
                  <span class="float-right">{data ? data.order_id : ""}</span>
                </li>

                <li class="list-group-item">
                  <b>Cheque Status</b>{" "}
                  <span class="float-right">
                    {data ? data.cheque_status : ""}
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Cheque Details</b>{" "}
                  <span class="float-right">
                    {data ? data.cheque_details : ""}
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Cheque Return Date</b>{" "}
                  <span class="float-right">
                    {data ? data.cheque_return_date : ""}
                  </span>
                </li>

                <li class="list-group-item">
                  <b>Created On</b>{" "}
                  <span class="float-right">{data ? data.created_on : ""}</span>
                </li>

                <li class="list-group-item">
                  <b>Initiated By</b>{" "}
                  <span class="float-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        getName(data ? data.initiated_by : "");
                      }}
                    >
                      Get Name
                    </button>
                  </span>
                </li>

                {data && data.status === "APPROVED" && (
                  <li class="list-group-item">
                    <b>IS Closed</b>{" "}
                    <span class="float-right">
                      {data ? data.is_closed : ""}
                    </span>
                  </li>
                )}
              </ul>
            )}

            {data &&
              data.status === "PENDING" &&
              props.userType === "Admin" && (
                <div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Status</label>
                    <div class="col-sm-9">
                      <select
                        name="title"
                        class="form-control"
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="">---Select Status---</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Remark</label>
                    <div class="col-sm-9">
                      <textarea
                        ref={remarkRef}
                        className="form-control"
                        placeholder="Remark"
                        rows="3"
                        defaultValue={data ? data.admin_remark : ""}
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            {data &&
              data.status === "PENDING" &&
              props.userType === "Admin" && (
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => {
                    updateHandler(data ? data.order_id : "");
                  }}
                >
                  Submit
                </button>
              )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PendingActionDialog;
