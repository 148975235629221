import React, { useState, useContext, useRef, useEffect } from "react";

import Swal from "sweetalert2";

import AuthService from "../../../../services/Admin/AuthService";
import CompanyService from "../../../../services/Admin/CompanyService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const SliderImages = (props) => {
  let data = props.data.slider_images;

  const loadingCtx = useContext(LoadingContext);

  const [sliderImages, setSliderImages] = useState(data ? data : []);

  const updateHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.updateSliderImages(
        token,
        sliderImages
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="row">
        <div class="col-md-10 mx-auto">
          <div class="card bg-light d-flex flex-fill">
            <div class="card-header text-muted border-bottom-0">
              <h3 class="card-title">Slider Images</h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-info btn-sm"
                  onClick={() => {
                    const _sliderImages = [
                      ...sliderImages,
                      { image_link: "", target_link: "" },
                    ];

                    setSliderImages(_sliderImages);
                  }}
                >
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </div>

            <div class="card-body pt-0 mb-2 ">
              {sliderImages.map((e, index) => {
                return (
                  <div class="row pt-2" key={index}>
                    <div class="col-5">
                      <label>Image (Link)</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.image_link}
                        onInput={(x) => {
                          let image_link = x.target.value;

                          sliderImages[index].image_link = image_link;
                        }}
                      />
                    </div>
                    <div class="col-5">
                      <label>Target (Link)</label>
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={e.target_link}
                        onInput={(x) => {
                          let target_link = x.target.value;

                          sliderImages[index].target_link = target_link;
                        }}
                      />
                    </div>
                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style={{ marginTop: "34px" }}
                        onClick={() => {
                          let _sliderImages = sliderImages.filter(
                            (item, index2) => index2 !== index
                          );

                          setSliderImages(_sliderImages);
                        }}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SliderImages;
