import common from "../../utill/Common";

class UtiitslPANCardService {
  generateLoginUrl = async (token) => {
    const url = common.baseUrl + "user/utiitsl_pancard/generate_login_url";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UtiitslPANCardService();
