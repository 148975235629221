import React, { useRef, useEffect, useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import AuthService from "../../../services/User/AuthService";
import DbModuleService from "../../../services/User/DbModuleService";
import HttpError from "../../../utill/HttpError";
import CommercialService from "../../../services/User/CommercialService";
import LoadingContext from "../../../store/loading-context";

const FundTransferDialog = (props) => {
  const amountRef = useRef();

  const loadingCtx = useContext(LoadingContext);

  const [userType, setUserType] = useState();
  const [packages, setPackages] = useState();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const userType = AuthService.getUserType();
    setUserType(userType);

    if (userType !== "Reseller") {
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = await AuthService.getAccessToken();

      const data = await CommercialService.fetchPackages(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setPackages(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const submitHandler = async () => {
    const amount = amountRef.current.value;

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    console.log(props.fData);

    loadingCtx.startLoading();

    try {
      const token = await AuthService.getAccessToken();

      const data = await DbModuleService.balanceTransfer(
        token,
        props.fData ? props.fData.user_id : "",
        amount
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <>
      <Modal
        fullscreen={true}
        show={props.openFtDialog}
        onHide={props.handleCloseFtDialog}
      >
        <Modal.Header>
          <Modal.Title>Fund Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label>Amount</label>
            <input
              type="text"
              class="form-control form-control-border"
              placeholder="Amount"
              ref={amountRef}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleCloseFtDialog}>
            Close
          </Button>
          <Button variant="primary" onClick={submitHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FundTransferDialog;
