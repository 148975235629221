import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import ExpenseService from "../../../services/Admin/ExpenseService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import AddCategoryDialog from "./AddCategoryDialog";

const AddExpense = () => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const nameRef = useRef();
  const noteRef = useRef();
  const expenseDateRef = useRef();
  const amountRef = useRef();

  const [categoryId, setCategoryId] = useState();

  const addExpenseHandler = async () => {
    const name = nameRef.current.value;
    const note = noteRef.current.value;
    const expenseDate = expenseDateRef.current.value;
    const amount = amountRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!note) {
      Swal.fire("Opps!", "Invalid Note", "error");
      return;
    }

    if (!categoryId) {
      Swal.fire("Opps!", "Invalid Category", "error");
      return;
    }

    if (!expenseDate) {
      Swal.fire("Opps!", "Invalid Expense Date", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.add(
        token,
        name,
        note,
        categoryId,
        expenseDate,
        amount
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [categoryList, setCategoryList] = React.useState();

  React.useEffect(() => {
    expenseCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expenseCategoryList = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.expenseCategoryList(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setCategoryList(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Expense">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Add Expense</h3>
                    <div class="card-tools">
                      <a
                        href="/admin/expense_history"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Expense History
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                ref={nameRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Note</label>
                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                ref={noteRef}
                              ></textarea>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Select Category
                            </label>
                            <div class="col-sm-10">
                              <Autocomplete
                                options={categoryList}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Category"
                                  />
                                )}
                                onChange={(e, v) => {
                                  setCategoryId(v ? v.id : null);
                                }}
                              />
                              <p
                                style={{
                                  textAlign: "right",
                                  paddingTop: "5px",
                                  fontWeight: "bold",
                                  color: "blue",
                                }}
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                Add New Category
                              </p>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Expense Date
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="date"
                                class="form-control"
                                ref={expenseDateRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Amount
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Amount"
                                ref={amountRef}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={addExpenseHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <AddCategoryDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        expenseCategoryList={expenseCategoryList}
      />
    </React.Fragment>
  );
};

export default AddExpense;
