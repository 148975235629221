import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/User/AuthService";
import CommercialService from "../../../services/User/CommercialService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const EditPackageDialog = (props) => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const nameRef = useRef();
  const statusRef = useRef();

  const submitHandler = async () => {
    const name = nameRef.current.value;
    const status = statusRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Package Name", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CommercialService.editPackage(
        token,
        name,
        status,
        props.selectedData ? props.selectedData.id : ""
      );

      const message = responseData.message;

      if (responseData.status === "SUCCESS") {
        props.handleClose();
        Swal.fire("Success!", message, "success");
        props.getData();
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">2 Step Verification</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Package Name</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Package Name"
                  ref={nameRef}
                  defaultValue={props.selectedData && props.selectedData.name}
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Status</label>
              <div class="col-sm-9">
                <select class="form-control" ref={statusRef}>
                  <option
                    value={props.selectedData && props.selectedData.status}
                  >
                    {props.selectedData && props.selectedData.status}
                  </option>

                  <option value="Enabled">Enabled</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditPackageDialog;
