import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import TabPanel from "./TabPanel";

const UserDetailsDialog = (props) => {
  const handleClose = () => {
    props.setOpenUserDetailsDialog(false);
  };

  return (
    <Dialog
      fullScreen
      fullWidth={true}
      maxWidth="lg"
      open={props.openUserDetailsDialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <AppBar sx={{ position: "relative" }} color="primary">
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            User Details
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers>
        <TabPanel userData={props.userData} />
      </DialogContent>
    </Dialog>
  );
};

export default UserDetailsDialog;
