import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import ExpenseService from "../../../services/Admin/ExpenseService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const EditExpense = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  const formRef = useRef();
  const nameRef = useRef();
  const noteRef = useRef();
  const expenseDateRef = useRef();
  const amountRef = useRef();

  const [categoryId, setCategoryId] = useState();

  const editExpenseHandler = async () => {
    const name = nameRef.current.value;
    const note = noteRef.current.value;
    const expenseDate = expenseDateRef.current.value;
    const amount = amountRef.current.value;

    if (!name) {
      Swal.fire("Opps!", "Invalid Name", "error");
      return;
    }

    if (!note) {
      Swal.fire("Opps!", "Invalid Note", "error");
      return;
    }

    if (!categoryId) {
      Swal.fire("Opps!", "Invalid Category", "error");
      return;
    }

    if (!expenseDate) {
      Swal.fire("Opps!", "Invalid Expense Date", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await ExpenseService.update(
        token,
        id,
        name,
        note,
        categoryId,
        expenseDate,
        amount
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  useEffect(() => {
    fetchData();
    expenseCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.fetchById(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setCategoryId(responseData.data.category_id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [categoryList, setCategoryList] = React.useState();

  const expenseCategoryList = async () => {
    loadingCtx.startLoading();

    // const userId = props.userId ? props.userId : props.match.params.userId;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ExpenseService.expenseCategoryList(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setCategoryList(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Edit Expense">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Edit Expense</h3>

                    <div class="card-tools">
                      <a
                        href="/admin/expense_history"
                        class="btn btn-block btn-outline-primary btn-sm"
                      >
                        Expense History
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      {data && (
                        <form ref={formRef} autoComplete="off">
                          <div class="card-body">
                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Name
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Name"
                                  ref={nameRef}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Note
                              </label>
                              <div class="col-sm-10">
                                <textarea
                                  id="compose-textarea"
                                  class="form-control"
                                  ref={noteRef}
                                >
                                  {data.note}
                                </textarea>
                              </div>
                            </div>

                            {categoryList && (
                              <div class="form-group row">
                                <label class="col-sm-2 col-form-label">
                                  Select Category
                                </label>
                                <div class="col-sm-10">
                                  <Autocomplete
                                    options={categoryList}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Category"
                                      />
                                    )}
                                    onChange={(e, v) => {
                                      setCategoryId(v ? v.id : null);
                                    }}
                                    defaultValue={categoryList.find(
                                      (v) =>
                                        v.id.toString() === data.category_id
                                    )}
                                  />
                                </div>
                              </div>
                            )}

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Expense Date
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="date"
                                  class="form-control"
                                  ref={expenseDateRef}
                                  defaultValue={data.expense_date}
                                />
                              </div>
                            </div>

                            <div class="form-group row">
                              <label class="col-sm-2 col-form-label">
                                Amount
                              </label>
                              <div class="col-sm-10">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Amount"
                                  ref={amountRef}
                                  defaultValue={data.amount}
                                />
                              </div>
                            </div>

                            <div class="card-footer text-center">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={editExpenseHandler}
                              >
                                Submit
                              </button>
                              <button type="reset" class="btn btn-default ml-5">
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default EditExpense;
