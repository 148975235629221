import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import JsonFormatter from "react-json-formatter";

const ViewLogDialog = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <Dialog
      fullScreen
      fullWidth={true}
      maxWidth="lg"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="paper"
    >
      <AppBar sx={{ position: "relative" }} color="primary">
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            TXN Details
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers>
        {props.fData && props.fData.secret_log && (
          <JsonFormatter
            json={props.fData.secret_log}
            tabWith="4"
            jsonStyle={jsonStyle}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewLogDialog;
