import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../services/Admin/AuthService";
import LoadingContext from "../../../store/loading-context";
import HttpError from "../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const LoginOtpDialog = (props) => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const otpRef = useRef();

  const otpVerifyHandler = async (e) => {
    e.preventDefault();

    const otp = otpRef.current.value;

    if (!otp || isNaN(otp)) {
      Swal.fire("Opps!", "Invalid OTP", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const data = await AuthService.verifyOtp(props.token, otp);

      if (!data) {
        props.handleOpen();
        throw new Error("Request Failed!");
      }

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        const accessToken = data.data.access_token;
        const name = data.data.name;
        const email = data.data.email;
        const mobile = data.data.mobile;
        const emp_id = data.data.emp_id;
        const employee_type = data.data.employee_type;

        localStorage.setItem("admin_timestamp", new Date().getTime());
        localStorage.setItem("admin_access_token", accessToken);
        localStorage.setItem(
          "admin",
          JSON.stringify({
            name: name,
            email: email,
            mobile: mobile,
            emp_id: emp_id,
            employee_type: employee_type,
          })
        );

        props.handleClose();

        // history.push("/dashboard");
        window.location.href = "admin/dashboard";
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">2 Step Verification</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-success" role="alert">
              <b>Success! </b> {props.errMsg}
            </div>

            <div class="input-group mb-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={otpRef}
                class="form-control"
                placeholder="OTP (One Time Password)"
                maxLength={6}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={otpVerifyHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LoginOtpDialog;
