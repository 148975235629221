import common from "../../utill/Common";
import axios from "axios";

class RechargeApiService {
  delete = async (token, id) => {
    const url = common.baseUrl + "admin/recharge_api/delete";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (token, name, xurl, user_id, password, xtoken, status) => {
    const url = common.baseUrl + "admin/recharge_api/add";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        name,
        url: xurl,
        user_id,
        password,
        token: xtoken,
        status,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (
    token,
    id,
    name,
    whitelisted_ips,
    status,
    transaction_api,
    status_check_api,
    dispute_api,
    balance_check_api,
    webhook_api
  ) => {
    const url = common.baseUrl + "admin/recharge_api/update";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        name,
        whitelisted_ips,
        status,
        transaction_api,
        status_check_api,
        dispute_api,
        balance_check_api,
        webhook_api,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchById = async (token, id) => {
    const url = common.baseUrl + "admin/recharge_api/fetch_by_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchAll = async (token) => {
    const url = common.baseUrl + "admin/recharge_api/fetch_all";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchApiCode = async (token, product_id) => {
    const url = common.baseUrl + "admin/recharge_api/fetch_api_code";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ product_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateApiCode = async (token, product_id, newData) => {
    const url = common.baseUrl + "admin/recharge_api/update_api_code";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ product_id, newData }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteApiCode = async (token, product_id, newData) => {
    const url = common.baseUrl + "admin/recharge_api/delete_api_code";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ product_id, newData }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RechargeApiService();
