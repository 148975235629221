import React, { useState, useEffect } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/MenuOpen";
import { useHistory } from "react-router-dom";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WithdrawalService from "../../../services/User/WithdrawalService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import ViewDetailsDialog from "./ViewDetailsDialog";

const WithdrawalHistory = () => {
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const size = 10;

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchText, fromDate, toDate, status]);

  const fetchUsers = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    WithdrawalService.withdrawalHistory(
      token,
      fromDate,
      toDate,
      status,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const columns = [
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span class="badge badge-pill badge-primary">₹{row.amount}</span>
        </div>
      ),
    },
    {
      name: "Investment Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.investment_id}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            if (row.status === "APPROVED") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else if (row.status === "PENDING") {
              return (
                <span class="badge badge-pill badge-warning">{row.status}</span>
              );
            } else if (row.status === "REJECTED") {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Requested Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <SettingsIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Withdrawal History">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Withdrawal History</h3>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="From Date"
                                    onChange={(e) => {
                                      setFromDate(e.target.value);
                                    }}
                                    defaultValue={fromDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="To Date"
                                    onChange={(e) => {
                                      setToDate(e.target.value);
                                    }}
                                    defaultValue={toDate}
                                  />
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    placeholder="User ID"
                                    onChange={(e) => {
                                      setStatus(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Status</option>
                                    <option value="APPROVED">APPROVED</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="REJECTED">REJECTED</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-sm-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Search Text"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ViewDetailsDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          selectedData={selectedData}
        />
      </Templete>
    </React.Fragment>
  );
};

export default WithdrawalHistory;
