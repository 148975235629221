import React, { useState } from "react";
import { Link } from "react-router-dom";

import Templete from "../Templete/Templete";

import ViewProfile from "./ViewProfile";
import EditProfileForm from "./EditProfileForm";
import UpdateBankDetailsForm from "./UpdateBankDetailsForm";
import UpdateNomineeDetailsForm from "./UpdateNomineeDetailsForm";

const MyProfile = () => {
  const [data, setData] = useState();

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    setData(user);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="My Profile">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <div class="card card-primary card-outline">
                  <div class="card-body box-profile">
                    <div class="text-center">
                      <img
                        class="profile-user-img img-fluid img-circle"
                        src="/assets/img/user-icon.png"
                        alt="User profile"
                      />
                    </div>
                    <h3 class="profile-username text-center">
                      {data ? data.name : ""}
                    </h3>
                    <p class="text-muted text-center">
                      {data ? data.user_type : ""}
                    </p>
                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item">
                        <b>User ID</b>{" "}
                        <span class="float-right">
                          {data ? data.user_id : ""}
                        </span>
                      </li>
                      <li class="list-group-item">
                        <b>Mobile Number</b>{" "}
                        <span class="float-right">
                          {data ? data.mobile : ""}
                        </span>
                      </li>
                      <li class="list-group-item">
                        <b>Email ID</b>{" "}
                        <span class="float-right">
                          {data ? data.email : ""}
                        </span>
                      </li>
                    </ul>
                    <Link
                      to="/user/change_password"
                      class="btn btn-primary btn-block"
                    >
                      <b>Change Password</b>
                    </Link>
                    <Link
                      to="/user/reset_pin"
                      class="btn btn-primary btn-block"
                    >
                      <b>Reset PIN</b>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-md-9">
                <div class="card">
                  <div class="card-header p-2">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#viewProfile"
                          data-toggle="tab"
                        >
                          View Profile
                        </a>
                      </li>
                      {/* <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#editProfile"
                          data-toggle="tab"
                        >
                          Edit Profile
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#updateBank"
                          data-toggle="tab"
                        >
                          Update Bank Details
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#updateNominee"
                          data-toggle="tab"
                        >
                          Update Nominee Details
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content">
                      <div class="active tab-pane" id="viewProfile">
                        <ViewProfile />
                      </div>

                      <div class="tab-pane" id="editProfile">
                        <EditProfileForm />
                      </div>

                      <div class="tab-pane" id="updateBank">
                        <UpdateBankDetailsForm />
                      </div>

                      <div class="tab-pane" id="updateNominee">
                        <UpdateNomineeDetailsForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default MyProfile;
