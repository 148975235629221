import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <strong>
            Copyright &copy; 2023 <a href="#">JAMC PAY</a>
          </strong>
        </footer>
      </div>
    );
  }
}
