import React from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";

import HttpError from "../../../utill/HttpError";
import WalletService from "../../../services/Admin/WalletService";
import AuthService from "../../../services/Admin/AuthService";

const ActionsTab = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const successTxnHandler = async (id) => {
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const data = await WalletService.doSuccess(token, id);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsLoading(false);
  };

  const reverseTxnHandler = async (id) => {
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const data = await WalletService.doReverse(token, id);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div class="d-flex justify-content-center pt-3">
        <div style={{ marginRight: "50px" }}>
          {(() => {
            if (props.fData.status === "PENDING") {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // successTxnHandler(props.fData.id);

                    Swal.fire({
                      title: "Are you sure to update the status to SUCCESS?",
                      showCancelButton: true,
                      confirmButtonText: "Do Success",
                      confirmButtonColor: "green",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        successTxnHandler(props.fData.id);
                      } else if (result.isDenied) {
                        // Swal.fire("Changes are not saved", "", "info");
                      }
                    });
                  }}
                >
                  {isLoading && <CircularProgress size={18} />}
                  {!isLoading && "Success Transaction"}
                </Button>
              );
            }
          })()}
        </div>

        <div style={{ marginLeft: "50px" }}>
          {(() => {
            if (
              props.fData.status === "SUCCESS" ||
              props.fData.status === "PENDING"
            ) {
              return (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    //  reverseTxnHandler(props.fData.id);

                    Swal.fire({
                      title: "Are you sure to update the status to FAILED?",
                      showCancelButton: true,
                      confirmButtonText: "Do Reverse",
                      confirmButtonColor: "red",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        reverseTxnHandler(props.fData.id);
                      } else if (result.isDenied) {
                        // Swal.fire("Changes are not saved", "", "info");
                      }
                    });
                  }}
                >
                  {isLoading && <CircularProgress size={18} />}
                  {!isLoading && "Reverse Transaction"}
                </Button>
              );
            }
          })()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActionsTab;
