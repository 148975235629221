import React from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";

import TxnDetailsTab from "./TxnDetailsTab";
import LogsTab from "./LogsTab";
import SecretLogsTab from "./SecretLogsTab";
import ActionsTab from "./ActionsTab";

function MyTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

MyTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const [value, seValue] = React.useState(0);

  const handleChange = (e, v) => {
    seValue(v);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container justify="center">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="TXN Details" {...a11yProps(0)} />

            <Tab label="Logs" {...a11yProps(1)} />
            <Tab label="Secret Logs" {...a11yProps(2)} />
            <Tab label="Actions" {...a11yProps(3)} />
          </Tabs>
        </Grid>
      </Box>
      <MyTabPanel value={value} index={0}>
        <TxnDetailsTab fData={props.fData} />
      </MyTabPanel>

      <MyTabPanel value={value} index={1}>
        <LogsTab fData={props.fData} />
      </MyTabPanel>

      <MyTabPanel value={value} index={2}>
        <SecretLogsTab fData={props.fData} />
      </MyTabPanel>

      <MyTabPanel value={value} index={3}>
        <ActionsTab fData={props.fData} />
      </MyTabPanel>
    </Box>
  );
};

export default TabPanel;
