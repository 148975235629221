import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ChangePassword = () => {
  const loadingCtx = useContext(LoadingContext);
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const formRef = useRef();
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmNewPasswordRef = useRef();

  const changePasswordHandler = async () => {
    const currentPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmNewPassword = confirmNewPasswordRef.current.value;

    if (!currentPassword) {
      Swal.fire("Opps!", "Invalid Current Password", "error");
      return;
    }

    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
    );

    if (!regex.test(newPassword)) {
      Swal.fire(
        "Opps!",
        "New password must contain at least one uppercase character, one lowercase character, one number and one special character",
        "error"
      );
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Swal.fire(
        "Opps!",
        "New password & confirm new password does not match",
        "error"
      );
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.changePassword(
        token,
        currentPassword,
        newPassword
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        localStorage.clear();
        history.push("/login");
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Change Password">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Change Password</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Current Password
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Current Password"
                                ref={currentPasswordRef}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              New Password
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="New Password"
                                ref={newPasswordRef}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Confirm New Password
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="New Password"
                                ref={confirmNewPasswordRef}
                                onChange={(e) =>
                                  setPasswordAgain(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"></label>
                            <div class="col-sm-9">
                              <PasswordChecklist
                                rules={[
                                  "minLength",
                                  "specialChar",
                                  "number",
                                  "capital",
                                  "match",
                                ]}
                                minLength={6}
                                value={password}
                                valueAgain={passwordAgain}
                                onChange={(isValid) => {}}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={changePasswordHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default ChangePassword;
