import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AuthService from "../../../services/Admin/AuthService";
import WithdrawalService from "../../../services/Admin/WithdrawalService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ActionDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const data = props.selectedData;
  const [status, setStatus] = useState();

  const [file, setFile] = useState();
  const paymentDateRef = useRef();
  const paymentRefIdRef = useRef();
  const remarkRef = useRef();

  const updateHandler = async (id) => {
    // setIsLoading(true);

    const paymentDate = paymentDateRef.current.value;
    const paymentRefId = paymentRefIdRef.current.value;
    const remark = remarkRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid ID", "error");
      return;
    }

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    if (status === "APPROVED") {
      if (!file) {
        Swal.fire("Opps!", "Please Upload Payment Receipt", "error");
        return;
      }

      if (!paymentDate) {
        Swal.fire("Opps!", "Invalid Payment Date", "error");
        return;
      }

      if (!paymentRefId) {
        Swal.fire("Opps!", "Invalid Payment Ref ID", "error");
        return;
      }
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    props.handleClose();
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WithdrawalService.updateWithdrawalRequest(
        token,
        id,
        status,
        file,
        paymentDate,
        paymentRefId,
        remark
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  function handlePaymentReceipt(event) {
    let _file = event.target.files[0];

    const fileSizeKiloBytes = _file.size / 1024;

    if (fileSizeKiloBytes > 2048) {
      Swal.fire("Opps!", "The file size should be less than 2 MB", "error");
      return;
    }

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    if (!allowedExtensions.exec(_file.name)) {
      Swal.fire(
        "Opps!",
        "Invalid File Type (JPG, JPEG, PNG and PDF are allowed)",
        "error"
      );
      return;
    }

    setFile(_file);
  }

  const [isLoading, setIsLoading] = useState(false);

  const viewPaymentReceiptHandler = async (orderId) => {
    // loadingCtx.startLoading();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WithdrawalService.viewPaymentReceipt(
        token,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.setImage(responseData.data.image);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    // loadingCtx.stopLoading();
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && data && (
              <ul class="list-group list-group-unbordered mb-3">
                <div>
                  <li class="list-group-item">
                    <b>Withdrawal Amount</b>{" "}
                    <span
                      class="float-right"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      ₹{data ? data.amount : ""}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Investment Order ID</b>{" "}
                    <span class="float-right">
                      {data ? data.investment_id : ""}
                    </span>
                  </li>

                  <li class="list-group-item">
                    <b>Status</b>{" "}
                    <span class="float-right">{data ? data.status : ""}</span>
                  </li>

                  <li class="list-group-item">
                    <b>Order ID</b>{" "}
                    <span class="float-right">{data ? data.order_id : ""}</span>
                  </li>

                  {data && data.status === "APPROVED" && (
                    <div>
                      <li class="list-group-item">
                        <b>Payment Receipt</b>{" "}
                        {isLoading && (
                          <span class="float-right badge badge-pill badge-danger">
                            Fetching...
                          </span>
                        )}
                        {!isLoading && (
                          <span class="float-right">
                            {props.image && (
                              <span>
                                <span
                                  class="badge badge-pill badge-primary"
                                  onClick={() => {
                                    const newTab = window.open(props.image);

                                    newTab?.document.write(
                                      `<!DOCTYPE html><head><title>Image</title></head><body><img src="${props.image}"  ></body></html>`
                                    );

                                    newTab?.document.close();
                                  }}
                                >
                                  View
                                </span>

                                <a
                                  href={props.image}
                                  download={data.payment_receipt}
                                  class="badge badge-pill badge-primary ml-2"
                                >
                                  Download
                                </a>
                              </span>
                            )}

                            {!props.image && (
                              <span
                                class="badge badge-pill badge-primary ml-2"
                                onClick={() => {
                                  viewPaymentReceiptHandler(
                                    data ? data.order_id : ""
                                  );
                                }}
                              >
                                Fetch
                              </span>
                            )}
                          </span>
                        )}
                      </li>

                      <li class="list-group-item">
                        <b>Payment Ref ID</b>{" "}
                        <span class="float-right">
                          {data ? data.payment_ref_id : ""}
                        </span>
                      </li>

                      <li class="list-group-item">
                        <b>Payment Date</b>{" "}
                        <span class="float-right">
                          {data ? data.payment_date : ""}
                        </span>
                      </li>
                    </div>
                  )}

                  {data && data.status !== "PENDING" && (
                    <div>
                      <li class="list-group-item">
                        <b>Remark</b>{" "}
                        <span class="float-right">
                          {data ? data.remark : "NULL"}
                        </span>
                      </li>
                    </div>
                  )}

                  <li class="list-group-item">
                    <b>Requested Date</b>{" "}
                    <span class="float-right">
                      {data ? data.created_on : ""}
                    </span>
                  </li>

                  {data && data.status === "APPROVED" && (
                    <div>
                      <li class="list-group-item">
                        <b>Updated Date</b>{" "}
                        <span class="float-right">
                          {data ? data.modified_on : ""}
                        </span>
                      </li>
                    </div>
                  )}
                </div>
              </ul>
            )}

            {data && data.status === "PENDING" && (
              <div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select
                      name="title"
                      class="form-control"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value="">---Select Status---</option>
                      <option value="APPROVED">Approved</option>
                      <option value="REJECTED">Rejected</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Receipt</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          //  ref={paymentReceiptRef}
                          onChange={handlePaymentReceipt}
                          accept=".jpg,.jpeg,.png,application/pdf"
                        />
                        <label class="custom-file-label" for="exampleInputFile">
                          {file ? file.name : "Choose file"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Ref ID</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      ref={paymentRefIdRef}
                      className="form-control"
                      placeholder="Payment Ref ID"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Date</label>
                  <div class="col-sm-9">
                    <input
                      type="date"
                      ref={paymentDateRef}
                      className="form-control"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Remark</label>
                  <div class="col-sm-9">
                    <textarea
                      ref={remarkRef}
                      className="form-control"
                      placeholder="Remark"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            {data && data.status === "PENDING" && (
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  updateHandler(data ? data.id : "");
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ActionDialog;
