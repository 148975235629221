import React, { useState, useContext, useEffect } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import UtiPanCardService from "../../../services/Admin/UtiPanCardService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

const UtiPanCardReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();

  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _status = params.get("status");

    if (_status) {
      setStatus(_status);
      await fetchData(_status);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async (_status) => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    UtiPanCardService.report(
      token,
      fromDate,
      toDate,
      _status ? _status : status,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Product",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>Revamp Token</span>
        </div>
      ),
    },
    // {
    //   name: "Amount",
    //   cell: (row) => (
    //     <div style={{ marginTop: "10px", marginBottom: "10px" }}>
    //       <span>
    //         Order Amount:
    //         <span style={{ fontWeight: "bold" }}> ₹{row.order_amount}</span>
    //       </span>
    //       <br />
    //       <span>
    //         Debit Amount:
    //         <span style={{ color: "red", fontWeight: "bold" }}>
    //           {" "}
    //           -₹{row.amount}
    //         </span>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "PSA ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.optional1}
          </span>
        </div>
      ),
    },
    {
      name: "Quantity",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.optional2}
          </span>
        </div>
      ),
    },
    {
      name: "Order Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {row.order_amount}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to SUCCESS?",
                html: `
                  <input id="swal-input1" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Success`,
                confirmButtonColor: "green",
                preConfirm: () => {
                  return [document.getElementById("swal-input1").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "SUCCESS", formValues[0]);
              }
            }}
          >
            <DoneOutlineIcon />
          </IconButton>

          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to FAILED?",
                html: `
                <input id="swal-input2" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Failure`,
                confirmButtonColor: "red",
                preConfirm: () => {
                  return [document.getElementById("swal-input2").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "FAILED", formValues[0]);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const [summaryData, setSummaryData] = useState();

  const getSummary = async () => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiPanCardService.summary(
        token,
        fromDate,
        toDate,
        userId
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const updateTxnStatusHandler = async (id, status, remark) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiPanCardService.updateTxnStatus(
        token,
        id,
        status,
        remark
      );

      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="UTI PAN Card Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">UTI PAN Card Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value={status ? status : ""}>
                                    {status ? status : "Select Status"}{" "}
                                  </option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-secondary mr-4"
                                  onClick={() => {
                                    getSummary();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Fetch Summary
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.success.rows.length > 0
                                  ? summaryData.success.rows[0].total_amount +
                                    " [" +
                                    summaryData.success.count +
                                    "]"
                                  : "0" + " [" + summaryData.success.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL PENDING</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.pending.rows.length > 0
                                  ? summaryData.pending.rows[0].total_amount +
                                    " [" +
                                    summaryData.pending.count +
                                    "]"
                                  : "0" + " [" + summaryData.pending.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL FAILED</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.failed.rows.length > 0
                                  ? summaryData.failed.rows[0].total_amount +
                                    " [" +
                                    summaryData.failed.count +
                                    "]"
                                  : "0" + " [" + summaryData.failed.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </React.Fragment>
  );
};

export default UtiPanCardReport;
