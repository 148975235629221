import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";

const BankDetailsTab = (props) => {
  return (
    <div class="row justify-content-center">
      <div class="col-sm-6">
        <List>
          <ListItem>
            <ListItemSecondaryAction>
              {props.userData && props.userData.bank_name}
            </ListItemSecondaryAction>
            <ListItemText primary="Bank Name" />
          </ListItem>

          {/* <Divider /> */}

          <ListItem>
            <ListItemSecondaryAction>
              {props.userData && props.userData.account_number}
            </ListItemSecondaryAction>
            <ListItemText primary="Account Number" />
          </ListItem>

          {/* <Divider /> */}

          <ListItem>
            <ListItemSecondaryAction>
              {props.userData && props.userData.ifsc_code}
            </ListItemSecondaryAction>
            <ListItemText primary="IFSC Code" />
          </ListItem>

          {/* <Divider /> */}

          <ListItem>
            <ListItemSecondaryAction>
              {props.userData && props.userData.account_type}
            </ListItemSecondaryAction>
            <ListItemText primary="Account Type" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default BankDetailsTab;
